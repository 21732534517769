<template>
  <custom-auto-complete
    :items="list"
    item-title="username"
    item-value="id"
    :label="$t('users')"
    icon="account"
    col="3"
  />
</template>
<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.$axios("users/userdata/user-data/").then((response) => {
      this.list = response.data;
    });
  },
};
</script>
