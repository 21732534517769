<template>
  <custom-auto-complete
    :items="items"
    item-title="full_name_ar"
    item-value="id"
    :label="$t('student')"
    icon="account"
    col="3"
  />
</template>
<script>
export default {
  data() {
    return {
      items: [],
    };
  },
  async created() {
   await this.$axios
        .get(`student-affairs/students/`)
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data.results;
          }
        })
        .catch(() => {

          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
  },
};
</script>
