<template>
  <nav-drawer
    v-show="drawer"
    :title="update_id ? 'update' : 'create'"
    v-model="drawer"
    temporary
  >
    <template v-slot:body>
      <v-form ref="form">
        <drop-list
          name="group_for_semester"
          v-model="dropList.fk_grouping_id"
          :extraData="groups"
          @update:modelValue="
            dropList.capacity = groups?.find(
              (e) => e.id == dropList?.fk_grouping_id
            )?.capacity
          "
          :rules="[$required]"
        />
        <drop-list
          name="period"
          v-model="dropList.period_id"
          :rules="[$required, $max_value(65535)]"
        />
        <custom-text-field
          v-model="dropList.capacity"
          icon="stack-overflow"
          :rules="[$required, $max_value(65535)]"
          :label="$t('capacity')"
          type="number"
        />
        <v-col class="mb-2">
          <v-textarea
            v-model="dropList.description"
            :label="$t('description')"
            prepend-inner-icon="mdi-pencil"
            :rules="[$max_value(250)]"
          />
        </v-col>
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn
        :type="update_id ? 'update' : 'save'"
        :click="update_id ? updateData : saveData"
      />
      <custom-btn @click.stop="resetForm(), (drawer = !drawer)" />
    </template>
  </nav-drawer>
  <custom-data-table-2
    :headers="headers"
    :items="items"
    :pagination="pagination"
    :method="getData"
    :delItem="delData"
    :editItem="editData"
    :perm="{}"
    :actionList="actionList"
    exports
    :create="() => openDialog()"
  />
</template>
<script>
export default {
  props: {
    fk_semester: Number,
  },
  created(){
  },
  data() {
    return {
      items: [],
      pagination: undefined,
      dropList: {},
      updated_id: undefined,
      drawer: null,
      groups: [],     
    }
  },
  methods: {
    actionList(data){
      return [  
          [
          'schedules',
          () =>
            this.$router.push({
              name: 'SchedulesForGroups',
              params: { fk_semester_group_4b: data.id,fk_semester:this.fk_semester},
            }),
        ],
          [
          'subjectes',
          () =>
            this.$router.push({
              name: 'subject-for-groups',
              params: { id: data.fk_semester_4b_id, fk:data.id },
            }),
        ]
        ]
    },
    async openDialog(isEdit = false) {
      this.groups = undefined
      await this.$axios('academic-affairs/semester-group4B/data-for-save/', {
        params: {
          fk_semester_4b: this.fk_semester,
        },
      })
        .then((response) => {
          if (!isEdit) {
            this.resetForm()
          }
          this.groups = response.data
          this.drawer = true
        })
        .catch(() => {
          this.$emit('alert', 'errorData')
        })
    },

    resetForm() {
      this.dropList = {}
      this.update_id = undefined
      this.$refs.form.reset()
    },
    async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
      await this.$axios('academic-affairs/semester-group4B/filter-paginate/', {
        params: {
          field: 'fk_semester_4b',
          value: this.fk_semester,
          search: txt_search,
          page: page,
          page_size: perPage,
          sort_by: order_data,
        },
      })
        .then((response) => {
          this.items = response.data.results
          this.pagination = response.data.pagination
        })
        .catch(() => {})
    },
    async editData(data) {
      this.resetForm()
      this.update_id = data.id
      this.dropList = { ...data }
      
      await this.openDialog(true)
      const list = [...this.groups]
      
      list.push({
        id: this.dropList.fk_grouping_id,
        name_ar: this.dropList.fk_grouping,
        name_en: null,
        capacity: this.dropList.capacity,
      })    
      this.groups = []  
      this.groups = list.slice().sort((a,b)=>a.id - b.id);
    },

    async delData(delete_id) {
      if (delete_id != undefined) {
        var status
        await this.$axios
          .delete('academic-affairs/semester-group4B/' + delete_id + '/')
          .then(() => {
            status = true
          })
          .catch((error) => {
            status = error
          })
        return status
      }
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        await this.$axios
          .post('academic-affairs/semester-group4B/', {
            ...this.dropList,
            fk_semester_4b: this.fk_semester,
            fk_grouping: this.dropList.fk_grouping_id,
          })
          .then(() => {
            this.$emit('alert', 'add')
            this.drawer = false
            this.getData()
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })
      }
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        await this.$axios
          .put(`academic-affairs/semester-group4B/${this.dropList.id}/`, {
            ...this.dropList,
            fk_grouping: this.dropList.fk_grouping_id,
            fk_semester_4b: this.fk_semester,
            period: this.dropList.period_id,
          })
          .then(() => {
            this.$emit('alert', 'update')
            this.resetForm()
            this.drawer = false
            this.getData()
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })
      }
    },
  },
  computed:{
     headers(){ 
      return [
        { title: this.$t('group'), key: 'fk_grouping' },
        { title: this.$t('clss_name'), key: 'fk_semester_4b' },
        { title: this.$t('period'), key: 'period' },
        {
          title: this.$t('capacity'),
          key: 'capacity',
        },
        {
          title: this.$t('description'),
          key: 'description',
        },

        { title: this.$t('actions'), key: 'actions', sortable: false },
      ]}
  }
}
</script>
