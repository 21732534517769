<template>
  <div>
    <nav-drawer
      v-show="drawer"
      :title="update_id ? 'update' : 'create'"
      v-model="drawer"
      temporary
    >
      <template v-slot:body>
        <v-form ref="form">
          <custom-text-field
            v-model="dropList.topic_ar"
            icon="subtitles-outline"
            :rules="[$required, $ar_letters_only, $max_length(100)]"
            :label="$t('lecture_topic_ar')"
          />
          <custom-text-field
            v-model="dropList.topic_en"
            icon="alpha-e"
            :label="$t('lecture_topic_en')"
            :rules="[$en_letters_only, $max_length(100)]"
          />
        </v-form>
      </template>
      <template v-slot:action>
        <custom-btn
          :type="update_id ? 'update' : 'save'"
          :click="update_id ? updateData : saveData"
        />
        <custom-btn @click.stop="resetForm(), (drawer = false)" />
      </template>
    </nav-drawer>
    <custom-data-table-2
      :headers="headers"
      :items="items"
      :pagination="pagination"
      :method="getData"
      :delItem="delData"
      :editItem="editData"
      :perm="{}"
      exports
      :create="() => (drawer = true)"
    />
  </div>
</template>
<script>
export default {
  props: {
    fk_lecture_name_grouping: Number,
  },
  data() {
    return {
      items: [],
      pagination: undefined,
      dropList: {},
      updated_id: undefined,
      drawer: false,
    }
  },
  methods: {
    resetForm() {
      this.$refs.form.reset()
      this.dropList = {}
      this.update_id = undefined
    },
    async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
      await this.$axios(
        'academic-affairs/lecture-topic-4-grouping/filter-paginate/',
        {
          params: {
            field: 'fk_lecture_name_grouping',
            value: this.fk_lecture_name_grouping,
            search: txt_search,
            page: page,
            page_size: perPage,
            sort_by: order_data,
          },
        }
      )
        .then((response) => {
          this.items = response.data.results
          this.pagination = response.data.pagination
        })
        .catch(() => {
          this.$emit('alert', 'errorData')
        })
    },
    async editData(data) {
      this.resetForm()
      this.update_id = data.id
      this.dropList = { ...data }
      this.drawer = true
    },
    async delData(delete_id) {
      if (delete_id != undefined) {
        var status
        await this.$axios
          .delete(
            'academic-affairs/lecture-topic-4-grouping/' + delete_id + '/'
          )
          .then(() => {
            status = true
          })
          .catch((error) => {
            status = error
          })
        return status
      }
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate()
      if (valid)
        await this.$axios
          .post('academic-affairs/lecture-topic-4-grouping/', {
            ...this.dropList,
            fk_lecture_name_grouping: this.fk_lecture_name_grouping,
          })
          .then(() => {
            this.$emit('alert', 'add')
            this.resetForm()
            this.getData()
            this.drawer = false
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })
    },

    async updateData() {
      const { valid } = await this.$refs.form.validate()
      if (valid)
        await this.$axios
          .put(
            `academic-affairs/lecture-topic-4-grouping/${this.dropList.id}/`,
            this.dropList
          )
          .then(() => {
            this.$emit('alert', 'update')
            this.resetForm()
            this.drawer = false
            this.getData()
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })
    },
  },
  computed: {
    headers() {
      return [
        { title: this.$t('lecture_topic_ar'), key: 'topic_ar' },
        { title: this.$t('lecture_topic_en'), key: 'topic_en' },

        { title: this.$t('actions'), key: 'actions', sortable: false },
      ]
    },
  },
}
</script>
