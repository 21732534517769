<template>

  <div class="dashboard">
    <v-expansion-panels
      class="mb-6"
      v-if="(role >= 0 && role <= 5) || role == 8"
    >
      <v-expansion-panel elevation="1">
        <v-expansion-panel-title class="px-4">
          <div class="d-flex justify-space-between w-75">
            <div>
              <v-icon>mdi-tune</v-icon>
              <span class="mx-1">{{ $t("select-criteria") }}</span>
            </div>
            <div class="pt-1">
              <span
                v-for="item in LocationBreadcrumb"
                :key="item"
                class="text-indigo mx-1"
                >{{ item }}</span
              >
            </div>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row class="py-2">
            <v-col class="mt-1" cols="6" md="3" sm="6">
              <v-autocomplete
                color="indigo"
                density="compact"
                v-model="date"
                item-title="date"
                item-value="date"
                prepend-inner-icon="mdi-calendar-today"
                clearable
                hide-details
                :items="dates"
                :label="$t('hijri-date')"
                @update:modelValue="getData()"
              />
            </v-col>
            <VCol
              class="mt-1"
              cols="12"
              md="3"
              sm="6"
              v-if="role == 0 || role == 1"
            >
              <VAutocomplete
                :items="filterGovernorates(1)"
                v-model="fk_governorate"
                item-title="name_ar"
                item-value="id"
                :label="$t('governorate')"
                persistent-hint
                density="compact"
                hide-details
                clearable
                :no-data-text="$t('not-found')"
                prepend-inner-icon="mdi-city"
                @update:modelValue="
                  getSummerSchools(),
                    getData(),
                    (fk_directorate = null),
                    (fk_summer = null)
                "
              ></VAutocomplete>
            </VCol>
            <VCol
              class="mt-1"
              cols="12"
              md="3"
              sm="6"
              v-if="(role >= 0 && role <= 2) || role == 8"
            >
              <VAutocomplete
                :items="filterDirectorates(fk_governorate)"
                v-model="fk_directorate"
                item-title="name_ar"
                item-value="id"
                :label="$t('directorate')"
                persistent-hint
                hide-details
                density="compact"
                clearable
                :no-data-text="$t('not-found')"
                prepend-inner-icon="mdi-map-outline"
                @update:modelValue="
                  getSummerSchools(), getData(), (fk_summer = null)
                "
              ></VAutocomplete>
            </VCol>
            <VCol
              class="mt-1"
              cols="12"
              md="3"
              sm="6"
              v-if="role >= 0 && role <= 3"
            >
              <VAutocomplete
                v-model="fk_summer"
                item-value="id"
                item-title="name_ar"
                prepend-inner-icon="mdi-domain"
                :items="summer_schools"
                :label="$t('summer-centre')"
                persistent-hint
                hide-details
                density="compact"
                :no-data-text="$t('not-found')"
                clearable
                @update:modelValue="getData()"
              ></VAutocomplete>
            </VCol>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-item-group mandatory class="mt-2">
      <v-row justify="center" v-if="true">
        <v-col
          cols="6"
          sm="6"
          md="3"
          class="mb-2"
          v-if="
          
            role >= 0 &&
            role <= 3
          "
        >
 
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="3"
          class="mb-2"
        >
          <v-item>
            <v-card class="cutom_card p-5 cutom_card"
              :class="alertcolor"
              height="80"
              border="start"
              border-color="red-accent-2"
              elevation="2"
            >
              <div class="d-flex justify-space-between align-center mt-4 mx-5 "  >
                <div class="text-center">
                  <span class="text-medium-emphasis">{{
                    $t("student")
                  }}</span>
                  <span class="">
                    <h1>{{ students.count }}</h1>
                  </span>
                </div>
                <div>
                  <v-icon
                    size="50"
                    class="rounded pa-4 bg-red-accent-2"
                    color="white"
                    >mdi-school</v-icon
                  >
                </div>
              </div>
            </v-card>
          </v-item>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="3"
          class="mb-2"
        >
          <v-item>
            <v-card class="cutom_card"
              :class="alertcolor"
              height="80"
              border="start"
              border-color="indigo-accent-2"
              elevation="2"
              @click="toggle"
            >
              <div class="d-flex justify-space-between align-center  mt-4 mx-5 ">
                <div class="text-center">
                  <span class="text-medium-emphasis">{{
                    $t("employees")
                  }}</span>
                  <span >
                    <h1>{{ teachers.count }}</h1>
                  </span>
                </div>
                <div>
                  <v-icon
                    size="50"
                    class="rounded pa-4 bg-indigo-accent-2"
                    color="white"
                    >mdi-account-tie</v-icon
                  >
                </div>
              </div>
            </v-card>
          </v-item>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="3"
          class="mb-2"
          v-if="role > 3"
        >
          <v-item>
            <v-card class="cutom_card"
              :class="alertcolor"
              height="80"
              border="start"
              border-color="green-accent-2"
              elevation="2"
              @click="toggle"
            >
              <div class="d-flex justify-space-between align-center  mt-4 mx-5">
                <div class="text-center">
                  <span class="text-medium-emphasis">{{
                    $t("studied-lectures")
                  }}</span>
                  <span >
                    <h1>{{ subjects }}</h1>
                  </span>
                </div>
                <div>
                  <v-icon
                    size="50"
                    class="rounded pa-4 bg-green-accent-2"
                    color="white"
                    >mdi-book-multiple</v-icon
                  >
                </div>
              </div>
            </v-card>
          </v-item>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="3"
          class="mb-2"
        >
          <v-item>
            <v-card class="cutom_card"
              height="80"
              :class="alertcolor"
              
              border="start"
              border-color="orange-accent-2"
              elevation="2"
              @click="toggle"
            >
              <div class="d-flex justify-space-between align-center  mt-4 mx-5">
                <div class="text-center">
                  <span class="text-medium-emphasis">{{
                    $t("activity")
                  }}</span>
                  <span >
                    <h1>{{ activities }}</h1>
                  </span>
                </div>
                <div>
                  <v-icon
                    size="50"
                    class="rounded pa-4 bg-orange-accent-2"
                    color="white"
                    >mdi-file-multiple</v-icon
                  >
                </div>
              </div>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>
    <bar-chart
      :statistics="Statistics"
    />
    <chart-pie
      :students="students"
      :teachers="teachers"
    /> 
  </div>
</template>

<script>
import BarChart from '@/components/ComponentGlobal/BarChart'
import ChartPie from '@/components/ComponentGlobal/ChartPie'
export default {

  computed:{
    
    alertcolor(){
      return this.$vuetify.theme.dark?'blue darken-4':'blue lighten-4';
    }
  },
    created() {
    this.$axios.get('system-management/report-view-set/data').then((e) => {
      this.iteming = e.data.data

      this.students.allStudentMaleFemale = this.iteming.studnet_f
      this.students.allStudentMale = this.iteming.studnet_m
    })
  },
  name: "Home",
  data() {
    return {
      iteming:{},
      fk_governorate: null,
      fk_directorate: null,
      fk_summer: null,
      dates: [],
      circulars:[],
      date: null,
      teachers: {
        count: 0,
        "male": 0,
        "female": 0,
        "allEmployeeMale": 0,
        "allEmployeeMaleFemale": 0
    },
      students: {count:0,male:0,female:0,allStudentMale:7,allStudentMaleFemale:8},
      activities: 0,
      subjects: 0,
      summer_count: 0,
      summer_schools: [],
      Statistics:{
    "male": 0,
    "female": 0,
    "levels_statistics": [
        {
            "level": "مستوى اول ",
            "male": 0,
            "female": 0
        },
        {
            "level": "مستوى ثاني ",
            "male": 0,
            "female": 0
        },
        {
            "level": "مستوى ثالث",
            "male": 0,
            "female": 0
        },
        {
            "level": " مستوى رابع",
            "male": 0,
            "female": 0
        },
      
    ]
},
      user_profile: {},
    };
  },
  components: {
    ChartPie,
    BarChart,
  },
};
</script>

<style scoped>
  .cutom_card.v-card--variant-flat {
    background-color: inherit !important;
    box-shadow: inherit !important;
  }
  .cutom_card{
    border-right:7px  solid #1098f7 ;
    border-radius: 10px;
    box-shadow:none !important ;
  }

</style>