<template>
  <nav-drawer
    v-show="drawer"
    :title="update_id ? 'update' : 'create'"
    v-model="drawer"
    temporary
  >
    <template v-slot:body>
      <v-form ref="form">
        <custom-text-field
          v-model="college_data.course_name"
          icon="file-certificate-outline"
          :label="$t('course_name')"
          :rules="[$required, $ar_letters_only, $max_length(100)]"
        />
        <custom-text-field
          v-model="college_data.courses_type"
          icon="shape-plus-outline"
          :label="$t('courses_type')"
          :rules="[$required, $ar_letters_only, $max_length(100)]"
        />
        <custom-text-field
          v-model="college_data.place"
          icon="map-marker-outline"
          :label="$t('place')"
          :rules="[$required, $ar_letters_only, $max_length(100)]"
        />
          <DatetimePicker
            v-model="college_data.date"
            :label="$t('dataofbirth')"
            datetime_picker="date_of_birth"
            :rules="[$required]"
            clearable
            density="compact"
          ></DatetimePicker>
        <custom-text-field
          v-model="college_data.supervising_enitiy"
          icon="domain"
          :label="$t('supervising_enitiy')"
          :rules="[$required, $ar_letters_only, $max_length(100)]"
        />
        <custom-text-field
          v-model="college_data.holding_period"
          icon="clock-start"
          :label="$t('holding_period')"
          :rules="[$required, $ar_letters_only, $max_length(100)]"
        />
        <custom-text-note v-model="college_data.note" />
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn
        :type="update_id ? 'update' : 'save'"
        :click="update_id ? updateData : saveData"
      />
      <custom-btn @click="resetForm()" />
    </template>
  </nav-drawer>
  <custom-data-table-2
    :headers="headers"
    :items="items"
    :pagination="pagination"
    :method="getData"
    :delItem="delData"
    :editItem="editData"
    :actionList="true"
    :perm="{}"
    exports
    :create="() => this.opendrawer()"
  />
</template>
<script>
export default {
  props: {
    perm: Object,
    id: {
      type: Number,
    },
  },
  created() {},

  data() {
    return {
      items: [],
      pagination: undefined,
      college_data: {},
      updated_id: undefined,
      drawer: null,
      headers: [
        {
          title: this.$t('course_name'),
          key: 'course_name',
        },
        {
          title: this.$t('courses_type'),
          key: 'courses_type',
        },
        { title: this.$t('place'), key: 'place' },
        { title: this.$t('date'), key: 'date' },
        {
          title: this.$t('supervising_enitiy'),
          key: 'supervising_enitiy',
        },
        {
          title: this.$t('holding_period'),
          key: 'holding_period',
        },
        { title: this.$t('note'), key: 'note' },

        { title: this.$t('actions'), key: 'actions' },
      ],
    }
  },
  methods: {
    opendrawer() {
      this.resetForm()
      this.drawer = true
      this.is_update = false
    },
    async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
      await this.$axios('/student-affairs/courses-quranic/filter-paginate/', {
        params: {
          search: txt_search,
          page: page,
          page_size: perPage,
          sort_by: order_data,
          field: 'fk_student',
          value: this.id,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.items = response.data.results
           this.pagination = response.data.pagination
        })
        .catch(() => {})
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        this.college_data.fk_student = this.id;
        await this.$axios
          .post('/student-affairs/courses-quranic/', this.college_data)
          .then(() => {
            this.$emit('alert', 'add')
            this.resetForm()
            this.getData()
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })
      }
    },
    editData(data) {
      this.update_id = data.id
      this.drawer = true
      this.college_data = { ...data }
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        await this.$axios
          .put(
            `/student-affairs/courses-quranic/${this.college_data.id}/`,
            this.college_data
          )
          .then(() => {
            this.$emit('alert', 'update')
            this.getData()
            this.resetForm()
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })
      }
    },
    async delData(delete_id) {
      if (delete_id != undefined) {
        var status
        await this.$axios
          .delete('/student-affairs/courses-quranic/' + delete_id + '/')
          .then(() => {
            status = true
          })
          .catch((error) => {
            status = error
          })
        return status
      }
    },
    resetForm() {
      this.subject_lecture = {}
      this.update_id = null
      this.drawer = false
      this.$refs.form.reset()
    },
  },
}
</script>
