<template>
    <nav-drawer
        v-if="drawer"
        v-model="drawer"
        temporary
        title="اضافةالفصول"
    >
    <template v-slot:body>
    <v-form ref="form">
    <v-row >    
    <!-- <list-specialization v-model="dropList.fk_specialization"   /> -->
    <drop-list name="level" v-model="dropList.level" :rules="[$required]"  ></drop-list>
    <drop-list name="semester"  v-model="dropList.name" :rules="[$required]"></drop-list>
        <custom-text-field
            v-model="dropList.percentage_in_cumulative_grade"
            icon="percent"
            :label="$t('percentage_in_cumulative_grade')"
            density="compact"
            type="number"
            :rules="[$required , $validateMaxNumber(dropList.number_of_exemption,1,100)]"
        ></custom-text-field> 


        <custom-text-field
            v-model="dropList.allowed_failure_subjects"
            icon="sign-caution"
            :label="$t('allowed_failure_subjects')"
            density="compact"
            type="number"
            :rules="[$required]"
        ></custom-text-field>

        <custom-text-field
            v-model="dropList.number_of_exemption"
            icon="counter"
            :label="$t('number_of_exemption')"
            :rules="[$required]"
            density="compact"
            type="number"

            
        ></custom-text-field>
    </v-row>
    </v-form>
    </template>
    <template v-slot:action>
    <custom-btn v-if="!is_update" type="save"  :click="saveData" class="ml-2" />
    <custom-btn v-if="is_update" type="update"  :click="updateData" />
    <custom-btn
            label="الغاء"
            col="6"
            color="white"
            text_color="black"
            @click.stop="drawer = !drawer"
            />
    </template>
    </nav-drawer>
    <custom-data-table-2
        :headers="headers"
        :items="items"
        :pagination="pagination"
        :method="getClassDataById"
        :delItem="delData"
        :editItem="editData"
        style="z-index: auto"
        export
        :create="()=>opendailog()"
        :perm="{}"
        :actionList="actionList"
    >
    </custom-data-table-2>
</template>
<script>
import { mapState } from 'vuex';

export default {
    props: {
        fk_college: {
        type: Number,
        },
        fk_specail: {
        type: Number,
        },
    },
    computed:{
        ...mapState(['fk_college'])
    },
    async created() {
    this.dropList.is_4_college=false;

    if (this.fk_college || this.fk_specail ) {
        await this.getClassDataById();
    }
    },
    data() {
        return {
        college_id:undefined,
        items: [],
        pagination: undefined,
        dropList:{ is_4_college : false},
        is_update: false,
        updated_id: undefined,
        drawer: null,
        headers: [
            { title: this.$t('clss_name'), key: 'semester_name',sortable: false },
            { title: this.$t('level'), key: 'level_name' ,sortable: false},
            { title: this.$t('specialization'), key: 'specialization_name',sortable: false },
            { title: this.$t('college'), key: 'college_name',sortable: false },
            { title: this.$t('avarage'), key: 'percentage_in_cumulative_grade' },
            { title: this.$t('failures'), key: 'allowed_failure_subjects' },
            { title: this.$t('forgive'), key: 'number_of_exemption' },
            { title: this.$t('actions'), key: 'actions',sortable:false },
        ],
        }
    },

    methods: {
        actionList(data){
        return [
            [
            'semester_subjects_add',
            ()=>this.addsubjects(data)
            ],
        ]

    },

        async getClassDataById(page = 1, perPage = 10, order_data = '', txt_search = ''){
            if (this.$route.params.fk_college && this.$route.params.fk_specail == undefined) {
                this.college_id = this.$route.params.fk_college
                await this.$axios('system-management/semester-data/filter/', {
                    params: {
                        search: txt_search,
                    page: page,
                    page_size: perPage,
                    sort_by: order_data,
                    field:'fk_college',
                    value: this.college_id,
                },
        })
            .then((response) => {
                this.items = response.data
            this.pagination = response.data.pagination
            })
            .catch(() => {})
            }else if(this.$route.params.fk_specail){
                await this.$axios('system-management/semester-data/filter/', {
                    params: {
                    search: txt_search,
                    page: page,
                    page_size: perPage,
                    sort_by: order_data,
                    field:'fk_specialization',
                    value: this.$route.params.fk_specail,
                    },
                })
                    .then((response) => {
                    this.items = response.data
                    this.pagination = response.data.pagination
                    })
                    .catch(() => {})
                    }
        },
        addsubjects(data){
                this.update_id = data.id
                this.$router.push(`/subject_assigin/${this.update_id}`);  
        },
        opendailog() {
        this.drawer = true
        this.is_update = false
        this.resetForm()
        },
        resetForm() {
            this.dropList={}
            this.is_update = false
            this.updated_id = undefined
            },
        editData(data, isPermission = false) {
            this.update_id = data.id
            if (!isPermission) {
            this.drawer =true
            this.dropList= {...data}
            this.is_update = true
            } else {
            return true
            }
        },
        async updateData() {
        const { valid } = await this.$refs.form.validate();
        if (valid) {
            if (this.$route.params.fk_college && this.$route.params.fk_specail == undefined) {
                this.dropList.fk_college= this.$route.params.fk_college;
                this.dropList.is_4_college =true;
                this.dropList.data_entry = this.user;
                await this.$axios
                .put(
                    this.base_url + "system-management/semester-data/" + this.update_id +'/',
                    this.dropList,
                )
                .then(() => {
                    this.$emit("successAlert", this.$t("data_updated"));
                    this.resetForm();
                    this.getClassDataById();
                    this.drawer =!this.drawer;
                })
                .catch((e) => {
                    if (e.response.data.non_field_errors[0] == "الحقول fk_college, name يجب أن تشكل مجموعة فريدة.") {
                        this.$emit("errorAlert", this.$t("uniq_class"));
                        }            
                        else{
                            this.$emit("errorAlert", this.$t("title"));
                        }  
                        });
            }else if (this.$route.params.fk_specail) {
                    this.dropList.fk_college= null;
                    this.dropList.fk_specail= this.$route.params.fk_specail;
                    this.dropList.is_4_college =false;
                    this.dropList.data_entry = this.user;
                await this.$axios
                .put(
                    this.base_url + "system-management/semester-data/" + this.update_id +'/',
                    this.dropList,
                
                )
                .then(() => {
                    this.$emit("successAlert", this.$t("data_updated"));
                    this.resetForm();
                    this.getClassDataById();
                    this.drawer =!this.drawer;
                })
                .catch((e) => {
                    if (e.response.data.non_field_errors[0] == "الحقول fk_specialization, name يجب أن تشكل مجموعة فريدة.") {
                        this.$emit("errorAlert", this.$t("uniq_class"));
                        }            
                        else{
                            this.$emit("errorAlert", this.$t("title"));
                        }  
                        });
            }
        }
        },
        async delData(delete_id, isPermission = false) {
        if (!isPermission) {
            if (delete_id != undefined) {
            var status
            await this.$axios
                .delete('system-management/semester-data/' + delete_id +'/')
                .then(() => {
                status = true
                })
                .catch((error) => {
                status = error
                })
            return status
            }
        } else {
            return true
        }
        },
        async saveData() {
        const { valid } = await this.$refs.form.validate();
            if (valid) {
            if (this.$route.params.fk_college && this.$route.params.fk_specail == undefined) {
                    this.dropList.fk_college= this.$route.params.fk_college;
                    this.dropList.fk_specialization= null;
                    this.dropList.is_4_college =true;
                    this.dropList.data_entry = this.user;
                await this.$axios
                    .post('system-management/semester-data/', this.dropList)
                    .then(() => {
                    this.$emit("successAlert", this.$t("data_added"));
                    this.resetForm();
                    this.getClassDataById();
                    this.drawer =!this.drawer;
                    })
                    .catch((e) => {
                    if (e.response.data.non_field_errors[0] == "الحقول fk_college, name يجب أن تشكل مجموعة فريدة.") {
                        this.$emit("errorAlert", this.$t("uniq_class"));
                        }            
                        else{
                            this.$emit("errorAlert", this.$t("title"));
                        }  
                        });
            }else if(this.$route.params.fk_specail){
                        this.dropList.fk_college= null;
                        this.dropList.fk_specialization= this.$route.params.fk_specail;
                        this.dropList.is_4_college =false;
                await this.$axios
                    .post('system-management/semester-data/', this.dropList)
                    .then(() => {
                        this.$emit("successAlert", this.$t("data_added"));
                        this.resetForm();
                        this.getClassDataById();
                        this.drawer =!this.drawer;
                    })
                    .catch((e) => {
                    if (e.response.data.non_field_errors[0] == "الحقول fk_specialization, name يجب أن تشكل مجموعة فريدة.") {
                        this.$emit("errorAlert", this.$t("uniq_class"));
                        }            
                        else{
                            this.$emit("errorAlert", this.$t("title"));
                        }  
                        });
            }
            
            }
        },
    },
    }
</script>
