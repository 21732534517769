<template>


  <custom-auto-complete
    :items="items"
    item-title="name"
    item-value="id"
    :label="$t('type-of-commit')"
    icon="account-tie-outline"
    :col="col"
    :rules="rules"
    density="compact"
     />
</template>
<script>
export default {
  name:'ListCommitestype',
  
props:{
  rules:{
    type:Object
  },
  col:{
    type:Number,
    default(){
      return 3
    }
  }
},
  data() {
    return {
      items: [] ,
       committeeType: [
        {
          name: "اجتماعي",
          id: 1,
        },
        {
          name: "ثقافي",
          id: 2,
        },
      ],
    }
  },
  created() {
    
        this.items = this.committeeType;
  
  },

}
</script>