<template>
  <v-col cols="12" :md="col">
    <v-textarea
      v-model="value"
      clearable
      :label="label ? label : $t('note')"
      no-resize
      :counter="counter"
      density="compact"
      :rows="rows"
      :rules="rules ? rules : [$max_length(250)]"
      :prepend-inner-icon="'mdi-' + icon"
      hide-details="auto"
    />
  </v-col>
</template>

<script>
export default {
  name: 'CustomTextNote',
  props: {
    modelValue: {
      type: [Number, String],
      default: null,
    },
    col: {
      type: Number,
      default: 12,
    },
    label: {
      type: String,
    },
    icon: {
      type: String,
      default: 'note-outline',
    },
    counter: {
      type: String,
      default: '250',
    },
    rows: {
      type: String,
      default: '3',
    },
    rules: {
      type: Array,
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
  methods: {
    updateDate(newValue) {
      this.$emit('update:modelValue', newValue)
    },
  },
}
</script>
