<template>
  <nav-drawer
    v-show="drawer"
    v-model="drawer"
    temporary
    :title="is_update"
    :is_update="is_update"
  >
    <template v-slot:body>
      <v-form ref="form">
        <v-row>
          <custom-text-field
            v-model="college_data.name_ar"
            icon="abjad-arabic"
            :label="$t('name_ar')"
            :rules="[
              $required,
              $max_length(100),
              $min_length(2),
              $ar_letters_only,
            ]"
          />
          <custom-text-field
            v-model="college_data.name_en"
            icon="alpha-e"
            :label="$t('name_en')"
            :rules="[$max_length(100), $min_length(2), $en_letters_only]"
          />
        </v-row>
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn
        :type="is_update ? 'update' : 'save'"
        :click="is_update ? updateData : saveData"
      />
      <custom-btn @click.stop="drawer = !drawer" />
    </template>
  </nav-drawer>

  <custom-data-table-2
    :headers="headers"
    :items="items"
    :pagination="pagination"
    :method="getData"
    :delItem="delData"
    :editItem="editData"
    :actionList="actionList"
    :perm="{}"
    exports
    :create="() => resetForm()"
  >
  </custom-data-table-2>
</template>
<script>
export default {
  props: {
    perm: Object,
    fk_college: {
      type: Number,
    },
    fk_specail: {
      type: Number,
    },
  },
  data() {
    return {
      items: [],
      pagination: undefined,
      college_data: {},
      is_update: false,
      drawer: false,

      headers: [
        { title: this.$t('name_ar'), key: 'name_ar' },
        { title: this.$t('name_en'), key: 'name_en' },
        { title: this.$t('college'), key: 'college_name' },
        { title: this.$t('actions'), key: 'actions', sortable: false },
      ],
    }
  },

  methods: {
    actionList(data) {
      return [['specialization_add', () => this.addspecailzation(data)]]
    },
    async addspecailzation(data) {
      this.$router.push({
        name: 'SpecializationsView',
        params: {
          fk_section: data.id,
          fk_college: this.$route.params.fk_college,
        },
      })
    },
    async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
      if (this.$route.params.fk_college) {
        await this.$axios('/system-management/sections/filter-paginate/', {
          params: {
            search: txt_search,
            page: page,
            page_size: perPage,
            sort_by: order_data,
            field: 'fk_college',
            value: this.$route.params.fk_college,
          },
        })
          .then((response) => {
            this.items = response.data.results
            this.pagination = response.data.pagination
          })
          .catch(() => {})
      }
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        ;(this.college_data.fk_college = this.$route.params.fk_college),
          await this.$axios
            .post('/system-management/sections/', this.college_data)
            .then(() => {
              this.$emit('alert', 'add')
              this.resetForm()
              this.getData()
            })
            .catch((error) => {
              if (error.response.data) {
                let message = Object.values(error.response.data)[0]
                this.$emit('warningAlert', message[0].replace(/[a-zA-Z]/g, ''))
              } else {
                this.$emit('alert', 'errorData')
              }
            })
      }
    },
    editData(data, isPermission = false) {
      this.update_id = data.id
      if (!isPermission) {
        this.drawer = true
        this.college_data = { ...data }
        this.is_update = true
      } else {
        return true
      }
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        if (this.$route.params.fk_college) {
          this.college_data.fk_college = this.$route.params.fk_college
          await this.$axios
            .put(
              this.base_url +
                '/system-management/sections/' +
                this.college_data.id +
                '/',
              this.college_data,
            )
            .then(() => {
              this.$emit('alert', 'update')

              this.resetForm()

              this.getData()
            })
            .catch(() => {
              this.$emit('alert', 'errorData')
            })
        }
      }
    },
    async delData(delete_id, isPermission = false) {
      if (!isPermission) {
        if (delete_id != undefined) {
          var status
          await this.$axios
            .delete('/system-management/sections/' + delete_id + '/')
            .then(() => {
              status = true
            })
            .catch((error) => {
              status = error
            })
          return status
        }
      } else {
        return true
      }
    },
    resetForm() {
      this.$refs.form.reset()
      this.is_update = false
      this.drawer = this.drawer ? !this.drawer : !this.drawer
    },
  },
}
</script>
