
<template>
    <v-dialog
      v-model="value"
      transition="dialog-top-transition"
      dir="rtl"
      scrollable
      width="350"
      elevation="12"
  >
      <v-sheet class="pa-2"> 
          <div class="text-center pa-2">
            <VIcon
                class="mb-1"
                color="error"
                icon="mdi-cancel"
                size="55"
            ></VIcon>
            <h2 class="  mb-2 text-blue-grey-darken-4 " >{{$t('failure')}}</h2>
            <h4 class=" mb-6 text-blue-grey-darken-5 " >{{$t(messages)}} </h4>
            <v-divider/>
            <v-expansion-panels >
                <v-expansion-panel >
                    <v-expansion-panel-title >{{$t('details')}}</v-expansion-panel-title>
                    <v-expansion-panel-text class="scroll">
                    <v-list-item v-for="data in data_message" :key="data" class="text-start" prepend-icon="mdi-circle-small">
                        <span style="font-size:0.8rem">{{data}}</span>
                    </v-list-item>
                    </v-expansion-panel-text>
                </v-expansion-panel>
                </v-expansion-panels>
            </div>
            <v-btn size="small" @click="value=false" variant="text" color="indigo">{{$t('ok')}}</v-btn>
        </v-sheet>
    </v-dialog>
</template>

<script>

export default{
    props:{
        data_message:{
            type:Array,
            required:true
        },
        modelValue:{
            type:Boolean,
            default: false,
        },
        messages:{
            type:String,
            default: 'cannot-delete',
        },
        
    },
    data(){
        return{
            protected_records:false
        }
    },
    computed:{
        value: {
            get(){
                return this.modelValue
            },
            set(value){
                this.$emit('update:modelValue',value)
            }
        },
    }
}

</script>
<style scoped>
.scroll{
    max-height: 200px;
    overflow: scroll;
}
</style>