<template>
  <div>
    <div class="sf d-lg-flex d-sm-block pa-2">
      <div class="hw-20 w-lg-25 w-sm-100 ">
        <slot name="header-start">
          <div class="d-flex  flex-column justify-center h-100">
            <div class="text-h5 headert">
              الجمهورية العربية اليمنية  
            </div> 
            <div class="text-h5 headert">وزارة التربية والتعليم</div>
            <div class="text-subtitle-1 headert" >
              الاكاديمية العليا للقران الكريم وعلومة
            </div>
          </div>
        </slot>
      </div>
      <div class=" hw-10  p-2 w-lg-25 w-sm-100 mt-2 mt-lg-0 mt-md-0 "  id="logom" style=" margin-right: 30%!important;" >
        <slot name="header-center">
          <div class="d-flex  flex-column ">
            <div id="nameofgod" class="text-h5  d-none d-lg-block d-md-block">{{ $t("in_the_name_of_allah") }}</div>
            <img  :src="mainImage" alt="" width="36% " id="nameofimg" />
          </div>
          <slot name="header-title">
          
          </slot>
        </slot>
      </div>
      <div class="hw-20 p-2 w-lg-25 w-sm-100 mt-2 mt-lg-0 mt-md-0">
        <slot name="header-end">
          <div class="d-flex  flex-column justify-center h-100">
            <div class="text-h5 headert">
              Arabic Rebublic Of Yemen
            </div>
            <div class="text-h5 headert">Prime Minister Office</div>
            <div class="text-subtitle-1 headert " style="text-align:center">
              Hole Quran Acadmic
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mainImage: require("@/assets/images/login/logo3.png"),
    };
  },
};
</script>

<style scoped>
.flex {
  flex: 1;
}
</style>
