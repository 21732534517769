<template>
  <custom-auto-complete
    :items="items"
    item-title="name"
    item-value="id"
    :label="$t('education')"
    icon="school"
    :col="col"
    :rules="rules"
  />
</template>


<script>


import { getEducationalLevel } from '@/plugins/methods/global-methods'

export default {
    props:{
    col:{
      type:Number,
      default(){
        return 3;
      }
    },
    rules:{
      type:Object,
       
    }

  },
  
  data() {
    return {
      items: [],
    }
  },

  async created() {
    this.items = await getEducationalLevel()
  },
}
</script>