<template>
  <v-col>
    <v-row>
      <drop-list
        name="country"
        v-model="value.country_1"
        @update:modelValue="
          ;(value.gov_1 = undefined),
            (value.dir_1 = undefined),
            (value.user_fk_address.fk_region = undefined)
        "
        col="3"
      />
      <drop-list
        name="gov_for_country"
        v-model="value.gov_1"
        :param="value.country_1"
        @update:modelValue="
          ;(value.dir_1 = undefined),
            (value.user_fk_address.fk_region = undefined)
        "
        col="3"
      />
      <drop-list
        name="dir_for_gov"
        v-model="value.dir_1"
        :param="value.gov_1"
        @update:modelValue="value.user_fk_address.fk_region = undefined"
        col="3"
      />
      <drop-list
        name="region_by_dir"
        v-model="value.user_fk_address.fk_region"
        :param="value.dir_1"
        col="3"
        :rules="[$required]"
      />
    </v-row>
    <v-row>
      <custom-text-field
        v-model="value.user_fk_address.street"
        :label="$t('street')"
        icon="traffic-light-outline"
        col="3"
        :rules="[$required, $max_length(100)]"
      />

      <custom-text-field
        v-model="value.student_data.job"
        :label="$t('work')"
        icon="account-outline"
        col="3"
        :rules="[$max_length(50)]"
      />
      <custom-text-field
        v-model="value.student_data.job_entity"
        :label="$t('employer')"
        icon="briefcase-outline"
        col="3"
        :rules="[$max_length(50)]"
      />

      <drop-list
        name="marital_status"
        v-model="value.student_data.marital_status"
        col="3"
        :rules="[$required]"
      />
    </v-row>
    <v-row>
      <custom-text-field
        v-model="value.user.phone_no"
        :label="$t('phonenumber')"
        icon="phone-outline"
        type="number"
        col="3"
        :rules="[$max_length(15)]"
      />

      <custom-text-field
        v-model="value.user.mobile_no"
        :label="$t('mobilenumber')"
        icon="cellphone"
        type="number"
        col="3"
        :rules="[$max_length(15)]"
      />

      <custom-text-field
        v-if="value.student_data.marital_status == 1"
        v-model="value.student_data.childern_count"
        :label="$t('number_children')"
        type="number"
        icon="human-child"
        col="3"
        :rules="[$max_length(2)]"
      />
      <custom-text-field
        v-if="value.student_data.marital_status == 1"
        v-model="value.student_data.wife_count"
        :label="$t('wife_count')"
        type="number"
        icon="account-outline"
        col="3"
        :rules="[$max_value(4)]"
      />
    </v-row>
    <v-row>
      <v-col cols="3" class="mt-2">
        <v-checkbox
          density="compact"
          v-model="value.student_data.is_owned_housing"
          :label="$t('house_owned')"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-form ref="form">
      <v-card
        :title="$t('chronic_disabilites')"
        prepend-icon="mdi-heart-pulse"
      >
        <v-card-text>
          <v-col>
            <v-row>
              <drop-list
                v-model="data.condition_type"
                name="type_condition"
                col="3"
                :rules="[$required]"
              />

              <custom-text-field
                v-model="data.condition"
                :label="$t('condition')"
                icon="h"
                col="3"
                :rules="[$required, $max_length(150)]"
              />
     
                <datetime-picker
                  v-model="data.date_reported"
                  :calendar="'gregorg'"
                  placeholder="YYYY-MM-DD"
                  :label="$t('date')"
                  :datetime_picker="'date_reported' + key"
                  col="3"
                />
                <custom-btn type="add" @click="add" class="align-self-center ms-5"/>

          
            </v-row>
          </v-col>
        </v-card-text>
      </v-card>
    </v-form>

    <v-data-table-virtual :headers="headers" density="compact" :items="items">
      <template #item="{ item, index }">
        <tr>
          <td>{{ index + 1 }}</td>
          <td>{{ item.condition_type }}</td>
          <td>{{ item.condition }}</td>
          <td>{{ item.date_reported }}</td>
          <td>
            <v-icon color="error" @click="remove(key)"
              >mdi-trash-can-outline</v-icon
            >
          </td>
        </tr>
      </template>
    </v-data-table-virtual>
  </v-col>
</template>
<script>
export default {
  name: 'FirstWindow',
  props: {
    dropList: Object,
  },
  data() {
    return {
      data:{},
      headers: [
        { title: '#', key: 'index', sortable: false },
        {
          title: this.$t('type_condition'),
          key: 'condition_type',
          sortable: false,
        },
        {
          title: this.$t('condition'),
          key: 'condition',
          sortable: false,
        },
        {
          title: this.$t('date'),
          key: 'date_reported',
          sortable: false,
        },
        { title: this.$t('actions'), key: 'actions', sortable: false },
      ],

      items: [],
    }
  },
   methods: {
    async add() {
      const { valid } = await this.$refs.form.validate()
      if (valid){
        this.items.push({...this.data})
        this.value?.physical_condition.push({...this.data})
        this.data = {}
      }
      
    },
    remove(index) {
       this.value.physical_condition.splice(index,1)
       this.items.splice(index,1)
    },
    
  },

  computed: {
    value: {
      get() {
        return this.dropList
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
}
</script>
