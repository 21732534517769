<template>
  <v-btn size="small" class="mx-3 bg-ccc" @click="onClick()">
    <span>{{ $t('clear') }}</span>
    <v-icon icon="mdi-broom" color="golden" end></v-icon
  ></v-btn>
</template>
<script>
export default {
  name: 'BtnClear',
  props: {
    click: Function,
  },
  methods: {
    onClick() {
      try{
        this.click
 
      }catch{
      console.log("error");

      }
   
    },
  },
}
</script>
 