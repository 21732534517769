<template>
<nav-drawer
  title="أضافة منطقة / عزلة"
  v-if="drawer"
    v-model="drawer"
    temporary>
  <template v-slot:body>
<v-form ref="reginform">
    <v-row class="px-6 pt-5" >
      <v-text-field
        v-model="dropList.name_ar"
        prepend-inner-icon="mdi-abjad-arabic"
        :label="$t('reginname')"
        density="compact"
        :rules="[$required , $validateArabicText , $max_length(100)]"
      ></v-text-field>
    </v-row>
    <v-row class="px-6">
      <v-text-field
        v-model="dropList.name_en"
        prepend-inner-icon="mdi-alpha-e"
        :label="$t('reginEname')"
        density="compact"
        :rules="[$validateEnglishText , $max_length(100)]"
      ></v-text-field>
    </v-row>
</v-form>
  </template>
  <template v-slot:action>
          <custom-btn v-if="!is_update" type="save" col="5" :click="saveData"  />
          <custom-btn v-if="is_update" type="update" col="5" :click="updateData"  />
      <custom-btn
        label="الغاء"
        col="6"
        color="white"
        text_color="black"
        @click.stop="drawer = !drawer"
      />
  </template></nav-drawer>
  <custom-data-table-2
    :headers="headers"
    :items="items"
    :pagination="pagination"
    :delItem="delData"
    :method="getData"
    :editItem="editData"
    style="z-index: auto"
    :perm="{}"
    exports
    :create="()=>opendailog()"
  >
  </custom-data-table-2>
</template>
<script>
export default {
  props:{
        perm:Object,
        id: {
        type: Number,
        },
    },
  data() {
    return {
      items: [],
      pagination: undefined,
      dropList: {},
      is_update: false,
      updated_id: undefined,
      drawer: null,
      update_id: undefined,
      headers: [
          { title: this.$t('reginname'), key: 'name_ar' },
        { title: this.$t('reginEname'), key: 'name_en' },
        { title: this.$t('actions'), key: 'actions',sortable:false },
      ],
    }
  },
  methods: {
  opendailog() {
      this.drawer = true
      this.is_update = false
      this.resetForm()
    },
        resetForm() {
      this.dropList={}
      this.is_update = false
      this.updated_id = undefined
    },
    async saveData() {
      const { valid } = await this.$refs.reginform.validate();
        if (valid) {
          if (this.$route.params.id) {
          this.dropList.data_entry = this.user;
          this.dropList.fk_directorate=this.$route.params.id;
          await this.$axios
            .post('common/region-data/', this.dropList)
            .then(() => {
              this.$emit("successAlert", this.$t("data_added"));
              this.resetForm();
              this.drawer=false;
              this.getData();
            })
            .catch((error) => {
              if (error.response.status == 400) {
                  this.$emit("warningAlert", this.$t("error_in_repeted_data"));
              }
              else{
                this.$emit("errorAlert", this.$t("error_in_data"));
              }
              
            });
          }
        }
      },
  async updateData() {
      const { valid } = await this.$refs.reginform.validate();
      if (valid) {
        if (this.$route.params.id) {
        this.dropList.data_entry = this.user;
        this.dropList.fk_directorate=this.$route.params.id;
        await this.$axios
          .put(
            this.base_url + "common/region-data/" + this.update_id +'/',
            this.dropList,
          )
          .then(() => {
            this.$emit("successAlert", this.$t("data_updated"));
            this.resetForm();
            this.drawer=false;
        this.getData();
          })
          .catch(() => {
            this.$emit("errorAlert", this.$t("error_in_data"));
          });
        }
      }
    },
  editData(data, isPermission = false) {
        this.update_id = data.id
        if (!isPermission) {
          this.drawer =true
          this.dropList.fk_country = data.fk_country
          this.dropList.name_ar = data.name_ar
          this.dropList.name_en = data.name_en
          this.is_update = true
        } else {
          return true
        }
      },
      async delData(delete_id, isPermission = false) {
      if (!isPermission) {
        if (delete_id != undefined) {
          var status
          await this.$axios
            .delete('common/region-data/' + delete_id +'/')
            .then(() => {
              status = true
            })
            .catch((error) => {
              status = error
            })
          return status
        }
      } else {
        return true
      }
    },
    async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
      if (this.$route.params.id){
        await this.$axios('common/region-data/filter/', {
          params: {
            search: txt_search,
          page: page,
          page_size: perPage,
          sort_by: order_data,
          field:'fk_directorate',
          value: this.$route.params.id,
        },
      })
        .then((response) => {
          console.log("555555555555");
          this.items = response.data
          this.pagination = response.data.pagination
        })
        .catch(() => {})}
    },
  },
}
</script>
<style scoped>
.v-navdra {
  z-index: 1;
  transform: translateX(0%) !important;
  position: fixed !important;
  left: 0px !important;
  height: calc((100% - 0px) - 0px) !important;
  top: 0px !important;
  bottom: 0px !important;
  box-shadow: 0 10px 6px rgba(0, 0, 0, 0.16) !important;
  width: 270px !important;
}
</style>
