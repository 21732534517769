<template>
  <slot name="body" v-if="$route.name == url"></slot>
  <router-view v-else></router-view>
</template>
<script>
export default {
  props: {
    url: String,
  },
};
</script>
