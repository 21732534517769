<template>

    <!-- filter data  -->
    <v-card v-if="!exist" class="pa-2 " >
        <h3 class="text-grey-darken-2 px-2">
        {{ $t('select-criteria') }}
        </h3>
        <v-form ref="form">
          <v-col>
                <v-row>
          <drop-list
            name="batch"
            v-model="filter.batch"
            col="3"
            @update:modelValue="
              ;(filter.semester = undefined),
                (filter.group = undefined),
                (filter.fk_student = undefined)
            "
            :rules="[$required]"
          />
          <!-- <drop-list
            name="semester_4_batch"
            v-model="filter.semester"
            :param="filter.batch"
            col="3"
            @update:modelValue="
              ;(filter.group = undefined),
                (filter.fk_student = undefined)
            "
            :rules="[$required]"
          />
          <drop-list
            name="group_4_semester"
            v-model="filter.group"
            :param="filter.semester"
            col="3"
            @update:modelValue="filter.fk_student = undefined"
            :rules="[$required]"
          /> -->
          <drop-list
            name="StudentBatch"
            v-model="filter.fk_student"
            :param="filter.batch"
            col="3"
            :rules="[$required]"
          />
        </v-row>
        <v-col cols="6" sm="2" class="d-flex align-center">
            <v-btn
            density="comfortable"
            class="w-100"
            color="indigo"
            @click="checkFilterData"
            :rules="[$required]"
            
            >
            <span class="text-white">{{ $t('show') }}</span>
            <VIcon icon="mdi-presentation" color="white" end />
            </v-btn>
        </v-col>
          </v-col>
        </v-form>
    </v-card>
    <v-card v-if="exist" id="lectures-report" flat class="justify-center fullsc">
        <v-btn  id="printbtn" class="mt-5 mx-5" color="blue" @click="printcard()"><v-icon> mdi mdi-printer </v-icon> <span>طباعة </span> </v-btn>
        <v-card class=" mx-5 my-2 " style="border: 2px solid green; border-radius: 20px;">
        <ReportHeader class="pb-0" >
        <template v-slot:header-end>   
        <v-col v-if="items.student.student_image" class=" hw-20  p-2 w-lg-25 w-sm-100 mt-2 mt-lg-0 mt-md-0 ">
        <img  :src="items.student.student_image" width="100%" height="100%" style="object-fit:cover; logo-sm" class="std_img">
        </v-col>
        <v-col v-else class=" hw-20  p-2 w-lg-25 w-sm-100 mt-2 mt-lg-0 mt-md-0 ">
        <img  src="/img/1.f1175776.png" width="100%" height="100%" style="object-fit:cover; logo-sm" class="std_img">
        </v-col>
        </template>
        </ReportHeader>
        </v-card>
        <h3 >
            <div class="d-flex justify-center py-2">
            <div class="text-h6 text-lg-h5" v-if="items">
                {{ $t('studentmarksreport') }} {{items.student.student_name}} برقم اكاديمي {{items.student.academice_no}}
            </div>
            </div>
        </h3>
        <v-card-text >
        <div   class=" mx-3 my-2 " >
            <v-card v-if="!items" style="border:2px solid green; border-radius: 20px;"  >
            <h3 class="text-center text-grey-darken-2">
                {{ $t('not-found-items') }}
            </h3>
            </v-card>
            <v-card
            style="border: 2px solid green; border-radius: 20px; position: relative;"
            class="px-5 py-5"
            v-if="items"
            >
<v-col class="image-responsive-lg image-responsive-sm">
<!-- <img :src="this.base_url+items.student_image" width="100%" height="200px"> -->
</v-col>
  <template v-for="s in levelsNames " :key="s" >
    <table  class="table align-center  flex-column justify-center  " style="width:100%; border-bottom:0;">
        <tbody style=" text-align: center;">
        <tr>
          <td  style=" background:#0000ff; color:white; border-bottom:0; " class="pt-2 pb-4 px-5"> المستوى {{s.level_name}}  للعام {{this.items.student.enrollment_year_m}} </td>
        </tr>
        </tbody>
      </table>
  <!--الفصل الاول -->
      <!-- <td>dsahkjhsdakhjasd : {{uniquesemstersNames(s.level_name)[0].semesters}}</td> -->
      <!-- <td>LEVEL iS:  {{s}}</td> -->
      <!-- <td>level ,llop  iS:  {{levelsNames}}</td> -->
    <v-row  class="table" v-for="i in uniquesemstersNames(s.level_name)" :key="i" >
      <template v-for="l in i.semesters" :key="l" > 
        <table  class="table align-center flex-column justify-center" style="width:100%; border:0;">
                    <tbody class="no_tab" style=" text-align: center; padding: 20px;">
                    </tbody>
                  </table>
      <v-col class="table_large_p" cols="12" lg="12" sm="12" >
    <div  class="table_large" style="overflow:auto;" width="100%">
                  <table  class="table align-center flex-column justify-center  qulifaction " style=" border-top:0;   table-layout: fixed;">
                      <thead style="justify-contrnt:start; text-align:right; ">
                    <tr >
                      <td colspan="8" v-if="i" style=" background:#faebd7; color:black;   " class="pt-2 pb-3 px-5 ">{{l.semster_name}}     </td>
                    </tr>
                    <tr>
                        <th  style="font-size:meduim; padding: 9px; background:#faebd7; color:black;  "> {{$t("id")}}  </th>
                        <th  style="font-size:meduim; padding: 9px; background:#faebd7; color:black;   "> {{$t("subject")}}  </th>
                        <th  style=" font-size:meduim; background:#faebd7; color:black;   "> {{$t("total_grade")}}    </th>
                        <th  style="font-size:meduim; background:#faebd7; color:black;  padding-left:20px; ">{{$t("status")}}   </th>
                        <th  style="font-size:meduim; padding: 9px; background:#faebd7; color:black;   "> {{$t("id")}}  </th>
                        <th  style="font-size:meduim; padding: 9px; background:#faebd7; color:black;   "> {{$t("subject")}}  </th>
                        <th  style=" font-size:meduim; background:#faebd7; color:black;   "> {{$t("total_grade")}}    </th>
                        <th  style="font-size:meduim; background:#faebd7; color:black;  padding-left:20px; ">{{$t("status")}}   </th>
                    </tr>
                      </thead>
                    <tbody >
                      <template v-for="(list,index) in l.subjects" :key="index" style="justify-contrnt:center; align-right padding: 20px; border-top:0;">
                      <template  v-if="index%2 ==0" >
                      <tr  >
                      <td  style="font-size:meduim; padding: 9px;  border-top:0; "> {{index +1}}</td>
                      <td  style="font-size:meduim; padding: 9px;  border-top:0; "> {{list.subject_name}}</td>
                      <td  style=" font-size:meduim;border-top:0; ">{{list.total_grade}}</td>
                      <td  style="font-size:meduim;border-top:0; padding-left:30px ">  {{list.estimate}}</td>
                        <template  v-if="index +1  < l.subjects.length  " >
                      <td  style="font-size:meduim; padding: 9px;  border-top:0; "> {{index+2}}</td>
                      <td  style="font-size:meduim; padding: 9px;  border-top:0; "> {{l.subjects[index+1].subject_name}}</td>
                      <td  style=" font-size:meduim;border-top:0; ">{{l.subjects[index+1].total_grade}}</td>
                      <td  style="font-size:meduim;border-top:0; padding-left:30px ">  {{l.subjects[index+1].estimate}}</td>
                        </template>
                          <template  v-else  >
                      <td  style="font-size:meduim; padding: 9px;  border-top:0; "> </td>
                      <td  style="font-size:meduim; padding: 9px;  border-top:0; "> </td>
                      <td  style=" font-size:meduim;border-top:0; "></td>
                      <td  style="font-size:meduim;border-top:0; padding-left:30px ">  </td>
                        </template>
                      </tr>
                      </template>
                      </template>
                      <tr>
                      <td  style="font-size:meduim; padding: 9px;  border-top:0; "> {{$t("total_grade")}}</td>
                      <td  style="font-size:meduim; padding: 9px;  border-top:0; "> {{l.total_grade}}</td>
                      <td  style="font-size:meduim; padding: 9px;  border-top:0; "> {{$t("evalution")}}</td>
                      <td  style="font-size:meduim; padding: 9px;  border-top:0; "> {{l.estimate_name}}</td>
                      <td  style="font-size:meduim; padding: 9px;  border-top:0; "> {{$t("avarge")}}</td>
                      <td colspan="3" style="font-size:meduim; padding: 9px;  border-top:0; "> {{l.avg}}</td>
                      </tr> 
                    </tbody>
                  </table>
    </div>
      </v-col>
      </template>
    </v-row>
<v-row >
        <v-col cols="12" lg="12" sm="12" >
                  <table  class="table  flex-column   " style="    text-align: right; width:100%; border-top:0;   table-layout: fixed;">
                      <thead style="justify-contrnt:start; text-align:right; padding: 20px;">
                    <tr >
                        <th  style="font-size:meduim; padding: 9px; background:#0000ff; color:white;  "> {{$t("avarge")}} للمستوى {{i}}</th>
                        <th  style=" font-size:meduim; background:#0000ff; color:white;  "> {{$t("total_grade")}}  للمستوى {{i}}  </th>
                        <th  style="font-size:meduim; padding: 9px; background:#0000ff; color:white;  "> {{$t("evalution")}}  للمستوى {{i}} </th>
                    </tr>
                      </thead>
                    <tbody  style="justify-contrnt:center; align-right padding: 20px; border-top:0;">
                    <tr >
                    <td  style="font-size:meduim; padding: 9px;  border-top:0; "> {{s.avg}}</td>
                    <td  style="font-size:meduim;border-top:0; padding-left:30px "> {{s.total_grade}}</td>
                    <td  style="font-size:meduim;border-top:0; padding-left:30px "> {{s.estimate}}</td>
                    </tr>
                    </tbody>
                  </table>
      </v-col>
</v-row>
  </template>    
<v-row  class="text-center font-weight-bold  mb-2">
  <v-col class="text_footer" cols="6" lg="3" >{{$t("control")}} </v-col>
  <v-col class="text_footer" cols="6" lg="3" >  {{$t("student_affairs_manager")}}  </v-col>
  <v-col class="  text_footer mb-lg-0 mt-lg-0" cols="6" lg="3" > {{$t("general_register")}} </v-col>
  <v-col class=" text_footer  mb-lg-0 mt-lg-0" cols="6" lg="3" >  {{$t("academic_main")}} </v-col>
</v-row>
            </v-card>
        </div>
        </v-card-text>
    </v-card>
    <Alert
        v-model="process_alert"
        :icon-color="icon_color"
        :icon="icon"
        :title="alert_title"
        :message="alert_message"
    />
    </template>
<script>
  export default {
    computed:{
    avargesum(){
      return(n) =>{
        const total_grade_sum = this.items?.student?.levels?.filter(item => item.semesters).map((item=> item.total_grade));
        const itemsemster = this.items?.student?.levels?.filter(item =>item.semesters ).map((item=> item.semster_name));
        return total_grade_sum / itemsemster.length
      }
    },
    avargelevelsum(){
      return(n) =>{
        const filteredLevels =this.items?.student?.levels?.filter(level => level.semesters.length > 0);
        const levelmathing =filteredLevels.filter(level => level.level_name === n);
        if(levelmathing.length >0){
        const total_grade_sum = levelmathing.reduce((sum,item)=> {
          const semsterTotalsGradeSum =item.semesters.reduce((semesterSum,semester)=>semesterSum + semester.avg,0);
          return sum + semsterTotalsGradeSum;
        },0);
        const itemsemster = levelmathing.length;
        if(itemsemster >0){
        console.log("55555555555555");
          return total_grade_sum / itemsemster
        }else{
          return 0;
        }
        }else{
          return 0;
        }
      }
    },
    elevationlevel(){
      return(n) =>{
        const the_avg = this.avargelevelsum(n)
        if(the_avg >= 90){
          return 'ممتاز'  
        }
        else if(the_avg >= 80){
          return 'جيد جدا'  
        }
        else if(the_avg >= 60){
          return 'جيد'  
        }
        else if(the_avg >= 50){
          return 'مقبول'  
        }
        else{
          return 'راسب'  
        }
      }
    },
    elevation(){
      return(n) =>{
        const the_avg = this.avargesum(n)
        if(the_avg >= 90){
          return 'ممتاز'  
        }
        else if(the_avg >= 80){
          return 'جيد جدا'  
        }
        else if(the_avg >= 60){
          return 'جيد'  
        }
        else if(the_avg >= 50){
          return 'مقبول'  
        }
        else{
          return 'راسب'  
        }
      }
    },
    caolcutesum(){
      return(n) =>{
        
        return this.items?.student?.levels?.filter(item => item.semesters).map((item=> item.total_grade));
      }
      },
    levelsNames(){
      return [...new Set(this.items?.student?.levels?.filter(item => item.level_name))];
    },
    uniquesemstersNames(){
      return(s)=>{
      return this.items?.student?.levels?.filter(item => item.level_name == s);
      }
    },
    caolcutelevelsum(){
      return(n) =>{
        const filteredLevels =this.items?.student?.levels?.filter(level => level.semesters.length > 0);
        const levelmathing =filteredLevels.filter(level => level.level_name === n);
        const total_grade_sum = levelmathing.reduce((sum,item)=> {
          const semsterTotalsGradeSum =item.semesters.reduce((semesterSum,semester)=>semesterSum + semester.total_grade,0);
          return sum + semsterTotalsGradeSum;
        },0);
        return total_grade_sum;
      }
      },
    filteredlevel(){
      return(n) =>{
      return this.items?.student?.levels?.filter(item => item.semesters)
      }
      },
    filteredlevelItems(){
      return(n ) =>{
      return this.items?.student?.levels?.filter(item =>item.level_name == n)
      }
      },
    },
    data() {
      return {

        students: [],
        items: [],
        filter:{fk_student:undefined},
        exist:false,
        semsters_list:'الفصل الاول',
        TOTALS:[]
        
      };
    },
    methods: {
      printcard(){
          window.print()
      },
      async getstudentdetails() {
          await this.$axios.get("student-affairs-report/case-statement/", {
            params: {
              fk_student: this.filter.fk_student,
            },
          })
            .then((response) => {
              console.log("ddddddddddddd");
              this.items = response.data;
              this.exist=true;
            })
            .catch(() => {});
      },
      async checkFilterData() {
          const { valid } = await this.$refs.form.validate();
              if (valid) {
          
          this.getstudentdetails();
        }
      },
    },
  };
</script>
<style scoped >

  .no_tab{
    border: none!important;
  }
  table , th , td{
  border:1px solid #0000ff ;
  text-align: center;
  border-collapse: collapse;
  table-layout: fixed;
  }
  .wp{
    width: 104px;
  };
  table{
  table-layout: fixed;
  width: 100%;
  };
  
 
</style>
  <!-- border: 1px solid rgb(255, 0, 0); -->
    <!-- border: 1px solid rgb(255, 0, 0); -->
