<template>
  <custom-auto-complete
    :items="items"
    :placeholder="$t('subject_section_placeholder')"
    item-title="name"
    item-value="id"
    :label="$t('subject_section')"
    icon="format-page-break"
    :col="col"
    :rules="rules"
  />
</template>
<script>

export default {
  props: {
    col: {
      type: Number,
      default() {
        return 12
      },
    },
    rules: {
      type: Object,
    },
  },
  name: 'ListSupplies',
  data() {
    return {
      items: [],
    }
  },
  async created() {
    await this.$axios('choices/choice-data/', {
    params: {
      param: 'SubjectSectionChoice',
    },
  }).then((e) => this.items = e.data)
  },
}
</script>
