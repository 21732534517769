// import AxiosPlugin from '@/plugins/axios-plugin.js';
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/Globals/LoginView.vue'
import CreateUsers from '@/views/UsersManage/CreateUsers.vue'
import ClassViews from '@/views/ManagementSystem/Public/ClassViews.vue'
// import SeasonsManagement from '@/views/AcademicAffairs/SeasonsManagement.vue';
import SubjectAssigin from '@/views/ManagementSystem/Public/AssiginSmesterSubjects.vue'
import AssiginLecturesDefnition from '@/views/ManagementSystem/Public/‏‏‏‏AssiginLecturesDefnition.vue'
import LecturesSubjects from '@/views/ManagementSystem/Public/‏‏AssiginLecturesSubjects.vue'
import SubjectForSemester from '@/views/AcademicAffairs/SubjectForSemester.vue'
import CreateEmployeeView from '@/views/EmployeeManagement/CreateEmployeeView.vue'
import AcadmicDashboard from '@/views/AcadmicDashboard.vue'
import RegisterdStudents from '@/views/StudentsAffairs/RegisterdStudents.vue'
import StudentMarks from '@/views/Reports/StudentAffairs/StudentMarks.vue'

import DistributionOfStudentsBatch from '@/views/StudentsAffairs/DistributionOfStudentsBatch.vue'

import LectureForSubject from '@/views/AcademicAffairs/LectureForSubject.vue'
import TopicsForLecture from '@/views/AcademicAffairs/TopicsForLecture.vue'
import NamesForLecture from '@/views/AcademicAffairs/NamesForLecture.vue'

import SpecializationsView from '@/views/General/SpecializationsView'
import StudentDataDetails from '@/views/StudentsAffairs/StudentDetails/StudentDataDetails.vue'
import DesiresWorkMasarah from '@/views/StudentsAffairs/StudentDetails/Screen/DesiresWorkMasarah.vue'
import CheckRequiredDocuments from '@/views/StudentsAffairs/StudentDetails/Screen/CheckRequiredDocuments.vue'
import VerifyRequiredCondition from '@/views/StudentsAffairs/StudentDetails/Screen/VerifyRequiredCondition.vue'
import GuardianData from '@/views/StudentsAffairs/StudentDetails/Screen/GuardianData.vue'
import UserData from '@/views/StudentsAffairs/UserData.vue'
import SemesterForBatch from '@/views/AcademicAffairs/SemesterForBatch.vue'
import SpecializatoinForBatch from '@/views/AcademicAffairs/SpecializatoinForBatch.vue'
import GroupsForSemester from '@/views/AcademicAffairs/GroupsForSemester.vue'
import SectionsView from '@/views/General/SectionsView.vue'
import Directorate_Managnment from '@/views/General/DirectorateView.vue'
import RegionsView from '@/views/General/RegionsView.vue'
import GovernorateView from '@/views/General/GovernorateView.vue'
import ClassView from '@/views/ManagementSystem/Public/ClassViews.vue'
import AccommodationInit from '@/views/Accommodation/AccommodationInit.vue'
import BedInit from '@/views/Accommodation/BedInit.vue'
import HallView from '@/views/ManagementSystem/Public/HallView.vue'
import MonitorsViews from '@/views/CoordinationManagement/MonitorsViews.vue'
import AcceptanceTestGroups from '@/views/CoordinationManagement/AcceptanceTestGroups'
import SchedulesForGroups from '@/views/AcademicAffairs/SchedulesForGroups.vue';
import NewRegistration from '@/views/StudentsAffairs/RegistrationAndAdmission/NewRegistration/NewRegistration.vue';
// import SchedulesForBatch from '@/views/AcademicAffairs/SchedulesForBatch.vue';
import AddmissionExamsSchedule from '@/views/CoordinationManagement/AddmissionExamsSchedule.vue'
import DrawalService from '@/views/StudentManagement/StudentServices/DrawalService.vue'
import GrivanceServices from '@/views/StudentManagement/StudentServices/GrivanceServices.vue'
import HousingService from '@/views/StudentManagement/StudentServices/HousingService.vue'
import LeaveOfAbsenceService from '@/views/StudentManagement/StudentServices/LeaveOfAbsenceService.vue'
import ReplacementCardService from '@/views/StudentManagement/StudentServices/ReplacementCardService.vue'
import StopRegistrationService from '@/views/StudentManagement/StudentServices/StopRegistrationService.vue'
import TransformService from '@/views/StudentManagement/StudentServices/TransformService.vue'
import TransferToAnother from '@/views/StudentManagement/StudentServices/TransferToAnother.vue'
import AffidavitService from '@/views/StudentManagement/StudentServices/AffidavitService.vue'
import Period4ScheduleInSemester from '@/views/AcademicAffairs/PeriodInSemester.vue'
import TestFormView from '@/views/AcademicAffairs/TestFormView.vue'
 
import axios from 'axios'

import {url} from '@/plugins/methods/global-methods'

// import testView from '@/views/AcademicAffairs/testView.vue'

 
const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
]
export async function fetchDynamicRoutes() {
  try {
    var token = localStorage.getItem("token") != null ? "Bearer " + localStorage.getItem("token") : "";
    const response = await axios.get(`${url()}front-end/sidbar/`,{
        headers: {
          Authorization: token,
        },
      },
    )
    const dynamicRoutes = await Promise.all(
      response.data.results
        ?.filter((item) => item.path != null)
        ?.map(async (route) => {
          try {

            const copmponent = await import(`@/views/${route.component}.vue`)
            return {
              path: route.path,
              name: route.path,
              component: copmponent.default,
              props: {
                perm: {
                  systems: route.systems,
                  add: route.permission_add,
                  edit: route.permission_edit,
                  del: route.permission_delete,
                },
              },
            }
          } catch (e){
            
             return {
              path: route.path,
              name: route.path,
              component: import(`@/views/HomeView.vue`),
            }
          }
        
    }),
    )
    // const obj = dynamicRoutes.find(item=>item.name=='BatchManagement');
    // obj.children = []              
    // obj.children.push({}})
    
   function findRout(data){                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
      for(const obj of data){
        if(obj.children){
          const found = this.findRout(obj.children)
          if(found) return found
          else return obj
        }
      }
    } 
        
    var routes_paths = {
      path: '/',
      name: 'home',
      component: HomeView,
      children: [
        ...dynamicRoutes,
        {
          path: 'UsersManageList/CreateUsers',
          name: 'CreateUsers',
          component: CreateUsers,
          props: true,
          // beforeEnter: requirePermission("school.change_teacher"),
        },
        {
          path: "semester-for-batch/:id",
          name: "semester-for-batch",
          component:SemesterForBatch,
          props: true,
        },
        {
          path: "period4-schedul-in-semester/:fk_semester",
          name: "period4-schedul-in-semester",
          component:Period4ScheduleInSemester,
          props: true,
        },

        {
          path: 'SectionsView/:id/:fk_college',
          name: 'SectionsView',
          component: SectionsView,
          props: ['id', 'fk_college'],
          // beforeEnter: requirePermission("school.change_teacher"),
        },
        {
          path: 'SpecializationsView/:fk_section/:fk_college',
          name: 'SpecializationsView',
          component: SpecializationsView,
          props: true,
          // beforeEnter: requirePermission("school.change_teacher"),
        },
        {
          path: 'class-views/:id/:fk_specail',
          name: 'class-views',
          component: ClassViews,
          props: ['id', 'fk_section' ,'fk_specail'],
          // beforeEnter: requirePermission("school.change_teacher"),
        },

        {
          path: '/subject_assigin/:id/',
          name: 'subject_assigin',
          component: SubjectAssigin,
          props: true,
        },
        {
          path: '/lectures_subjects/:id/',
          name: 'LecturesSubjects',
          component: LecturesSubjects,
          props: true,
        },
        {
          path: '/AssiginLecturesDefnition/:id/',
          name: 'AssiginLecturesDefnition',
          component: AssiginLecturesDefnition,
          props: true,
          // beforeEnter: requirePermission("system_management.change_lecturetopic"),
        },
        {
          path: '/student_data_details/:id',
          name: 'StudentDataDetails',
          component: StudentDataDetails,
          props: true,
        },
        {
          path: '/verify_required_condition/:id',
          name: 'VerifyRequiredCondition',
          component: VerifyRequiredCondition,
          props: true,
        },
    
        {
          path: '/check_required_documents/:id',
          name: 'CheckRequiredDocuments',
          component: CheckRequiredDocuments,
          props: true,
        },
        {
          path: 'desires_work_masarah/:id',
          name: 'DesiresWorkMasarah',
          component: DesiresWorkMasarah,
          props: true
        },
       

        {
          path: 'semester-for-specializatoin/:id',
          name: 'semester-for-specializatoin',
          component: SemesterForBatch,
          props: true,
        },
        {
          path: 'subject-for-semester/:id/:fk',
          name: 'subject-for-semester',
          component: SubjectForSemester,
          props: ['id', 'fk'],
          // beforeEnter: requirePermission("school.change_teacher"),
        },
        {
          path: 'subject-for-groups/:id/:fk',
          name: 'subject-for-groups',
          component: SubjectForSemester,
          props: ['id', 'fk'],
          // beforeEnter: requirePermission("school.change_teacher"),
        },
        {
          path: 'specializatoin-for-batch/:id/:fk_college',
          name: 'specializatoin-for-batch',
          component: SpecializatoinForBatch,
          props: ['id', 'fk_college'],
          // beforeEnter: requirePermission("school.change_teacher"),
        },
        {
          path: 'groups-for-semester/:fk_semester',
          name: 'groups-for-semester',
          component: GroupsForSemester,
          props: true,
        },
        {
          path: 'guardian-data/:id',
          name: 'guardian-data',
          component: GuardianData,
          props: true,
        },
        {
          path: 'user-data/:id',
          name: 'user-data',
          component: UserData,
          props: true,
        },
    
        {
          path: 'lecture-for-subject/:id/:fk_semester_group_4B/:type',
          name: 'lecture-for-subject',
          component: LectureForSubject,
          props: true,
        },
        {
          path: 'topics-for-lectur/:fk_lecture_name_grouping',
          name: 'topics-for-lectur',
          component: TopicsForLecture,
          props: true,
        },
        {
          path: 'names-for-lectur/:fk_subject_lecture_4b',
          name: 'names-for-lectur',
          component: NamesForLecture,
          props: true,
        },
        {
          path: "UsersManageList/:id/edit",
          name: "user-edit",
          component: CreateUsers,
          props: true,
          // beforeEnter: requirePermission("school.change_teacher"),
        },
      

        {
          path: 'SectionsView/',
          name: 'SectionsView',
          component: SectionsView,
          props: true,
          // beforeEnter: requirePermission("school.change_teacher"),
        },
        {
          path: 'DistributionOfStudentsBatch/',
          name: 'DistributionOfStudentsBatch',
          component: DistributionOfStudentsBatch,
          // props: true,
          // beforeEnter: requirePermission("school.change_teacher"),
        },
        {
          path: 'RegisterdStudents/',
          name: 'RegisterdStudents',
          component: RegisterdStudents,
          // props: true,
          // beforeEnter: requirePermission("school.change_teacher"),
        },
        {
          path: 'StudentMarks/',
          name: 'StudentMarks',
          component: StudentMarks,
          // props: true,
          // beforeEnter: requirePermission("school.change_teacher"),
        },
        

        
       
        {
            path: "CollegeClass/:id/:fk_college",
            name: "college-class",
            component: ClassView,
            props: ['id', 'fk_college'],
            // beforeEnter: requirePermission("school.change_teacher"),
        },

        {
          path: '/subject_assigin/:id',
          name: 'subject_assigin',
          component: SubjectAssigin,
          props: true,
        },
        {
          path: '/lectures_subjects/:id/',
          name: 'LecturesSubjects',
          component: LecturesSubjects,
          props: true,
        },
        {
          path: '/AssiginLecturesDefnition/:id/',
          name: 'AssiginLecturesDefnition',
          component: AssiginLecturesDefnition,
          props: true,
          // beforeEnter: requirePermission("system_management.change_lecturetopic"),
        },
        {
          path: 'create-employee',
          name: 'CreateEmployeeView',
          component: CreateEmployeeView,
          props: true,
          // beforeEnter: requirePermission("school.change_teacher"),
        },
        {
          path: 'edit-employee/:id',
          name: 'EditEmployeeView',
          component: CreateEmployeeView,
          props: true,
          // beforeEnter: requirePermission("school.change_teacher"),
        },
        {
          path: 'create-teacher',
          name: 'CreateTeacherView',
          component: CreateEmployeeView,
          props: true,
          // beforeEnter: requirePermission("school.change_teacher"),
        },
        {
          path: 'edit-teacher/:id',
          name: 'EditTeacherView',
          component: CreateEmployeeView,
          props: true,
          // beforeEnter: requirePermission("school.change_teacher"),
        },
     
        {
          path: "RegionsView/:id",
          name: "RegionsView",
          component: RegionsView,
          props: true,
        },
        {
          path: "monitors-views/:id",
          name: "monitors-views",
          component: MonitorsViews,
          props: true,
        },
        {

          path: '/verify_required_condition/:id',
          name: 'VerifyRequiredCondition',
          component: VerifyRequiredCondition,
          props: true,
        },      
        {
          path: 'check_required_documents/:id',
          name: 'CheckRequiredDocuments',
          component: CheckRequiredDocuments,
          props: true,
        },
      
  
        {
          path: 'semester-for-specializatoin/:id',
          name: 'semester-for-specializatoin',
          component: SemesterForBatch,
          props: true,
        },
        {
          path: 'GovernorateView/:id',
          name: 'Governorate_View',
          component: GovernorateView,
          props: true,
        },
        {
          path: 'DirectorateManagnment/:id',
          name: 'Directorate_Managnment',
          component: Directorate_Managnment,
          props: true,
        },
        {
          path: 'specializatoin-for-batch/:id/:fk_college',
          name: 'specializatoin-for-batch',
          component: SpecializatoinForBatch,
          props: ['id', 'fk_college'],          
        },
        {
          path: 'hall-view/:id',
          name: 'HallView',
          component: HallView,
          props: true,
        },
        {
          path: 'accommodation/:id',
          name: 'Accommodation',
          component: AccommodationInit,
          props: true,
        },
        {
          path: 'beds/:id',
          name: 'beds',
          component: BedInit,
          props: true,
        },
        {
          path: 'RegionsView/:id',
          name: 'RegionsView',
          component: RegionsView,
          props: true,
        },

        {
          path: 'AcceptanceTestGroups/:id',
          name: 'AcceptanceTestGroups',
          component: AcceptanceTestGroups,
          props: true,
        },
        {
          path: "schedules-for-groups/:fk_semester_group_4b/:fk_semester",
          name: "SchedulesForGroups",
          component: SchedulesForGroups,
          props: true,
      },
        {
          path: "schedules-for-groups/:fk_semester_group_4b/:fk_semester",
          name: "SchedulesForGroups",
          component: SchedulesForGroups,
          props: true,
      },
      {
          path: "new-registration",
          name: "NewRegistration",
          component: NewRegistration,
          props: true,
      },
      {
        path: "AcadmicDashboard",
        name: "AcadmicDashboard",
        component: AcadmicDashboard,
        alias:'/'
       
    },
    {
          path: "addmission-exams-schedule/:fk_differentiaion_batch/:fk_differentiaion_group",
          name: "addmission-exams-schedule",
          component: AddmissionExamsSchedule,
          props: true,
      },
      {
        path: "DrawalService",
        name: "DrawalService",
        component: DrawalService,
    },

    {
      path: "GrivanceServices",
      name: "GrivanceServices",
      component: GrivanceServices,
  },
  {
    path: "HousingService",
    name: "HousingService",
    component: HousingService,
},
{
  path: "LeaveOfAbsenceService",
  name: "LeaveOfAbsenceService",
  component: LeaveOfAbsenceService,
},
{
  path: "StopRegistrationService",
  name: "StopRegistrationService",
  component: StopRegistrationService,
},
{
  path: "ReplacementCardService",
  name: "ReplacementCardService",
  component: ReplacementCardService,
},
{
  path: "TransferToAnother",
  name: "TransferToAnother",
  component: TransferToAnother,
},
{
  path: "TransformService",
  name: "TransformService",
  component: TransformService,
},
{
  path: "AffidavitService",
  name: "AffidavitService",
  component: AffidavitService,
},
{
  path: "test-view/:fk_subject",
  name: "TestView",
  component: TestFormView,
  props: true,
},

      ],
    }
    return [...routes, routes_paths]
  } catch (error) {

    return routes
  }
}

export default routes
