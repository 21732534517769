<template>
  <nav-drawer
    v-show="drawer"
    :title="update_id ? 'update' : 'create'"
    v-model="drawer"
    temporary
  >
    <template v-slot:body>
      <v-form ref="form">
        <custom-text-field
          v-model="college_data.supervisor"
          icon="account-tie"
          :label="$t('supervisor')"
          :rules="[$required, $max_length(100)]"
        />
        <custom-text-field
          v-model="college_data.work"
          icon="briefcase-outline"
          :label="$t('work')"
          :rules="[$required, $max_length(100)]"
        />
        <custom-text-field
          v-model="college_data.place"
          icon="map-marker-outline"
          :label="$t('place')"
          :rules="[$required, $max_length(100)]"
        />

        <custom-text-field
          v-model="college_data.supervising_enitiy"
          icon="home-city-outline"
          :label="$t('supervising_enitiy')"
          :rules="[$required, $max_length(100)]"
        />
        <custom-text-field
          v-model="college_data.holding_period"
          icon="clock-start"
          :label="$t('holding_period')"
          :rules="[$required, $max_length(30)]"
        />
        <custom-text-note
          v-model="college_data.interruption_reason"
          :label="$t('interruption_reason')"
          icon="text"
          :rules="[$required, $max_length(500)]"
        />
        <v-checkbox
          v-model="college_data.status"
          :label="$t('status')"
          density="compact"
        ></v-checkbox>
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn
        :type="update_id ? 'update' : 'save'"
        :click="update_id ? updateData : saveData"
      />
      <custom-btn @click="resetForm()" />
    </template>
  </nav-drawer>
  <custom-data-table-2
    :headers="headers"
    :items="items"
    :pagination="pagination"
    :method="getData"
    :delItem="delData"
    :editItem="editData"
    :actionList="true"
    :perm="{}"
    exports
    :create="() => this.opendrawer()"
  />
</template>
<script>
export default {
  props: {
    perm: Object,
    id: {
      type: Number,
    },
  },
  created() {},

  data() {
    return {
      items: [],
      pagination: undefined,
      college_data: { status: false },
      updated_id: undefined,
      drawer: null,

      headers: [
        { title: this.$t('place'), key: 'place' },
        {
          title: this.$t('supervisor'),
          key: 'supervisor',
        },
        {
          title: this.$t('supervising_enitiy'),
          key: 'supervising_enitiy',
        },
        {
          title: this.$t('work'),
          key: 'work',
        },
        {
          title: this.$t('holding_period'),
          key: 'holding_period',
        },
        {
          title: this.$t('status'),
          key: 'status',
          type: 'check',
        },
        {
          title: this.$t('interruption_reason'),
          key: 'interruption_reason',
        },

        { title: this.$t('actions'), key: 'actions' },
      ],
    }
  },
  methods: {
    opendrawer() {
      this.resetForm()
      this.college_data.status = false

      this.drawer = true
      this.is_update = false
    },
    async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
      await this.$axios('/student-affairs/work-in-quranic/filter-paginate/', {
        params: {
          search: txt_search,
          page: page,
          page_size: perPage,
          sort_by: order_data,
          field: 'fk_student',
          value: this.id,
        },
      })
        .then((response) => {
          this.items = response.data.results
          this.pagination = response.data.pagination
        })
        .catch(() => {})
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        this.college_data.fk_student = this.id
        await this.$axios
          .post('/student-affairs/work-in-quranic/', this.college_data)
          .then(() => {
            this.$emit('alert', 'add')
            this.resetForm()
            this.getData()
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })
      }
    },
    editData(data) {
      this.update_id = data.id
      this.drawer = true
      this.college_data = { ...data }
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        await this.$axios
          .put(
            `/student-affairs/work-in-quranic/${this.college_data.id}/`,
            this.college_data
          )
          .then(() => {
            this.$emit('alert', 'update')
            this.getData()
            this.resetForm()
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })
      }
    },
    async delData(delete_id) {
      if (delete_id != undefined) {
        var status
        await this.$axios
          .delete('/student-affairs/work-in-quranic/' + delete_id + '/')
          .then(() => {
            status = true
          })
          .catch((error) => {
            status = error
          })
        return status
      }
    },
    resetForm() {
      this.subject_lecture = {}
      this.update_id = null
      this.drawer = false
      this.$refs.form.reset()
    },
  },
}
</script>
