<template>
  <v-col
    :class="type == 'export' || type == 'create' ? `pa-0 ${type}` : 'pa-1'"
    :cols="col"
  >
    <v-btn
      density="comfortable"
      :color="color"
      :loading="loading"
      @click="onClick"
      :style="customStyle"
      :variant="type == 'reset' ? 'text' : 'elevated'"
      :append-icon="icon"
      :disabled="disabled"
      :class="
        type == 'reset'
          ? 'px-2 text-orange'
          : type == 'export' ? 'text-teal':
            type == 'additional_exports'?'text-teal':type == 'imports'?
            'px-2 text-orange': 'px-4'
      "
    >
      <span>{{ label }}</span>
      <!-- <VIcon :icon="icon"  end></VIcon> -->
    </v-btn>
  </v-col>
</template>
<script>
export default {
  name: 'CustomBtn',
  data() {
    return {
      loading: false,
    }
  },
  props: {
    text:String,
    click: Function,
    text_color: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'cancel',
    },
    col: {
      type: String,
      default: 'auto',
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    perm: Object,
    icon: {
      type: String,
      default(props) {
        if (props.type === 'create') {
          return 'mdi-plus-circle-outline'
        } else if (props.type === 'add' || props.type === 'update') {
          return 'mdi-content-save'
        } else if (props.type === 'export') {
          return 'mdi-file-excel-outline'
        } else if (props.type === 'del') {
          return 'mdi-trash-can-outline'
        } else if (props.type === 'save') {
          return 'mdi-content-save'
        } else if (props.type === 'plus') {
          return 'mdi-plus-circle-outline'
        } else if (props.type === 'done') {
          return 'mdi-check'
        } else if (props.type === 'reset') {
          return 'mdi-broom'        
        } else if (props.type === 'show') {
          return 'mdi-eye-outline'
        } else if (props.type === 'imports') {
          return 'mdi-file-excel-outline'
        } 
         else if (props.type === 'additional_exports') {
          return 'mdi-file-excel-outline'
        } 
        
        else {
          return 'mdi-cancel'
        }
      },
    },
  },
  methods: {
     onClick() {
      if (this.click) {
        this.loading = true
        try {
          this.click().then(() => {
            this.loading = false
          }) 
        } catch {
          this.loading = false
          this.click()
        }
      }
        


    },
  },
  computed: {
    color() {
      if (this.type === 'del') {
        return 'red'
      } else if (
        this.type === 'add' ||
        this.type === 'create' ||
        this.type === 'done' ||
        this.type === 'save' || this.type=='show'
      ) {
        this
        return 'indigo'
      } else if (this.type === 'update') {
        return 'green'
      }
      return 'white'
    },
    label() {
      if(this.text)
      return this.text
      else
      switch (this.type) {
        case 'create':
          return this.$t('create')
        case 'additional_exports':
          return this.$t('additional_exports')
        case 'imports':
          return this.$t('imports')
        case 'add':
          return this.$t('add')
        case 'update':
          return this.$t('update')
        case 'del':
          return this.$t('del')
        case 'save':
          return this.$t('save')
        case 'export':
          return this.$t('export')
        case 'show':
          return this.$t('show')
        case 'done':
          return this.$t('done')
        case 'reset':
          return this.$t('reset')
        case 'cancel':
          return this.$t('cancel')
         
      }
    },
    customStyle() {
      if (this.type == 'create') {
        return 'border-top-right-radius: revert;border-bottom-right-radius: revert;'
      } else if (this.type == 'export') {
        return 'border-top-left-radius: revert; border-bottom-left-radius: revert;'
      }
      return ''
    },
    // icon_color() {
    //   if (this.type == 'export') {
    //     return 'green'
    //   } else if (this.type == 'cancel' || this.type == 'reset') {
    //     return 'black'
    //   }
    //   return 'white'
    // },
  },
}
</script>
