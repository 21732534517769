<template>
  <custom-auto-complete
    :items="items"
    item-title="name"
    item-value="id"
    :label="$t('status_document')"
    :col="col"
    :rules="rules"
  />
</template>
<script>
import { getCheckConditionStatusChoice } from '@/plugins/methods/global-methods'

export default {
  props: {
    rules: {
      type: Object,
    },
    // filter: {
    //   type: Boolean,
    //   default:true
    // },
    col: {
      type: Number,
      default() {
        return 12
      },
    },
  },
  data() {
    return {
      items: [],
    }
  },
  async created() {
    this.items = await getCheckConditionStatusChoice()
  },
  //   methods:{
  //     filterdList(){
  //         if (this.filter) {
  //             console.log(this.items.filter(item => item.id != 1));
  //             return this.items.filter(item => item.id != 1)
  //         }
  //         return this.items
  //     }
  //   }
}
</script>
