<template>
    <nav-drawer
      v-show="drawer"
      :title="update_id ? 'update' : 'create'"
      v-model="drawer"
      temporary
    >
      <template v-slot:body>
        <v-form ref="form">
          <v-row>
            <drop-list
              name="building"
              v-model="dropList.fk_building"
              :rules="[$required]"
              :param="'True'"
              @update:modelValue="dropList.fk_student_accommodation=undefined"
              />
              <drop-list
              name="accommodations"
              v-model="dropList.fk_student_accommodation"
              :rules="[$required]"
              :param="dropList.fk_building"
              @update:modelValue="getData()"
            />
            <custom-text-field
              v-model="dropList.bed_no"
              icon="numeric"
              :label="$t('bed_number')"
              type="number"
              :rules="[ $validateNumber,$required, $max_value(999)]"
              />
            <drop-list
              name="unit_status"
              v-model="dropList.bed_status" 
              :rules="[$required]"
            />
          </v-row>
        </v-form>
      </template>
      <template v-slot:action>
        <custom-btn
          :type="update_id ? 'update' : 'save'"
          :click="update_id ? updateData : saveData"
        />
        <custom-btn @click.stop="resetForm(), (drawer = !drawer)" />
      </template>
    </nav-drawer>
    <custom-card
    expanstion="true"
    :title="$t('select-criteria')"
  >
    <template v-slot>
      <v-row class="align-center">
        <drop-list
            name="building"
            v-model="filter.fk_building"
            :param="'True'"
            col="4"
            @update:modelValue="filter.fk_student_accommodation=undefined"
            />
            <drop-list
            name="accommodations"
            v-model="filter.fk_student_accommodation"
            :param="filter.fk_building"
            col="4"
            @update:modelValue="getData()"
          />
      </v-row>
    </template>
  </custom-card>
    <custom-data-table-2
      :headers="headers"
      :items="items"
      :pagination="pagination"
      :method="getData"
      :delItem="delData"
      :editItem="editData"
      :actionList="true"
      :perm="{}"
      :create="()=>{resetForm();drawer = true }"
  />
</template>
<script>
import DropList from '@/components/DropList/DropList.vue'
  export default {
  components: { DropList },
    props: {
      perm: Object,
    },
  
    data() {
      return {
        items: [],
        filter:{},
        pagination: undefined,
        dropList: {},
        updated_id: undefined,
        drawer: false,
        headers: [
          // { title: this.$t('buildings'), key: 'building_name' },
          { title: this.$t('accommodations'), key: 'fk_student_accommodation' },
          { title: this.$t('bed_number'), key: 'bed_no' },
          { title: this.$t('bed_status'), key: 'bed_status' },
          // { title: this.$t('unit_capacity'), key: 'uint_capacity', },
          // { title: this.$t('unit_status'), key: 'uint_status_name', },
          { title: this.$t('actions'), key: 'actions' },
        ],
      }
    },
    // async created() {
    //   if (this.id) {
    //     this.is_update = true
    //     await this.getUserDataById(this.id)
    //   }
    //   await this.$axios('activities/activity-type-data/all/')
    //     .then((response) => {
    //       this.list_activity_type = response.data
    //     })
    //     .catch(() => {})
    // },
    methods: {
      openDrawer() {
        this.drawer = !this.drawer
        this.resetForm()
      },
      resetForm() {
        this.$refs.form.reset()
        this.dropList = {}
        this.update_id = undefined
        this.is_update = false
      },
      async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
        await this.$axios('accommodation/bed/', {
          params: {
            fk_student_accommodation:this.filter.fk_student_accommodation,
            search: txt_search,
            page: page,
            page_size: perPage,
            sort_by: order_data,
          },
        })
          .then((response) => {
            let newItem = []
            response.data.results.forEach((element) => {
              newItem.push({
                ...element,
              })
            })
  
            this.items = newItem
            this.pagination = response.data.pagination
          })
          .catch(() => {})
      },
      editData(data) {
        this.resetForm()
        this.update_id = data.id
        this.dropList = {
          ...data
        }
        this.dropList.fk_student_accommodation = data.fk_student_accommodation_id
        this.dropList.bed_status = data.bed_status_id
        this.drawer = true
      },
      async delData(delete_id) {
        if (delete_id != undefined) {
          var status
          await this.$axios
            .delete('accommodation/bed/' + delete_id + '/')
            .then(() => {
              status = true
              this.getData()
            })
            .catch((error) => {
              status = error
            })
          return status
        }
      },
      async saveData() {
        const { valid } = await this.$refs.form.validate()
        if (valid) {
          await this.$axios
            .post('accommodation/bed/', this.dropList)
            .then(() => {
              this.$emit('alert', 'add')
              this.resetForm()
              this.getData()
            })
            .catch((error) => {
              if((error.response?.data?.error ||'') == 'لا يمكن الاضافة' ){
                this.$emit('errorAlert', this.$t('accommodation_full'))
              }
              else
                this.$emit('alert', 'errorData')
            })
        }
      },
      async updateData() {
        const { valid } = await this.$refs.form.validate()
        if (valid) {
          await this.$axios
            .put(`accommodation/bed/${this.dropList.id}/`, this.dropList)
            .then(() => {
              this.$emit('alert', 'update')
              this.resetForm()
              this.getData()
              this.drawer = false
            })
            .catch(() => {
              this.$emit('alert', 'errorData')
            })
        }
      },
    },
  }
  </script>
  