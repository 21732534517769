<template>
  <nav-drawer
    v-show="drawer"
    v-model="drawer"
    temporary
    :title="is_update"
    :is_update="is_update"
  >
    <template v-slot:body>
      <v-form ref="form">
        <v-row>
          <custom-text-field
            v-model="college_data.group_no"
            :label="$t('group_no')"
            icon="numeric"
            :rules="[$required, $max_length(10), $numeric]"
          />
          <drop-list
            name="group_period_choice"
            v-model="college_data.period"
            :rules="[$required]"
          ></drop-list>
          <v-col cols="12">
            <v-form ref="date">
              <v-row class="py-2">
                <datetime-picker
                  v-model="college_data.time_from"
                  :label="$t('time_from')"
                  datetime_picker="time_from"
                  icon="mdi-calendar-clock"
                  type="time"
                  col="6"
                  col_class="px-1"
                  :rules="[
                    compare_time(college_data.time_from, college_data.time_to),
                  ]"
                />
                <datetime-picker
                  v-model="college_data.time_to"
                  :label="$t('time_to')"
                  datetime_picker="time_to"
                  icon="mdi-calendar-clock"
                  type="time"
                  col="6"
                  col_class="px-1"
                  @update:model-value="validate()"
                  :rules="[
                    compare_time(college_data.time_from, college_data.time_to),
                  ]"
                  @update:modelValue="validate()"
                />
              </v-row>
            </v-form>
          </v-col>
          <custom-text-field
            v-model="college_data.Distribution_from"
            :label="$t('distribution_from')"
            icon="ray-end"
            :rules="[
              $required,
              $max_length(10),
              $less_than(college_data.Distribution_to),
            ]"
          />
          <custom-text-field
            v-model="college_data.Distribution_to"
            :label="$t('distribution_to')"
            icon="ray-start"
            :rules="[
              $required,
              $max_length(10),
              $more_than(college_data.Distribution_from),
            ]"
          />
          <custom-text-note v-model="college_data.note" />
        </v-row>
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn
        :type="is_update ? 'update' : 'save'"
        :click="is_update ? updateData : saveData"
      />
      <custom-btn @click.stop="drawer = !drawer" />
    </template>
  </nav-drawer>

  <custom-data-table-2
    :headers="headers"
    :items="items"
    :pagination="pagination"
    :method="getData"
    :delItem="delData"
    :editItem="editData"
    :actionList="actionList"
    :perm="{}"
    exports
    :create="() => resetForm()"
  >
  </custom-data-table-2>
  <v-dialog
    color="indigo"
    v-model="dialog"
    width="600"
    min-height="311"
    scrollable
  >
    <v-card>
      <v-card-title class="d-flex pt-3">
        <div>
          {{
            $t('add') + ' ' + $t(`halls`)
          }}
        </div>
        <v-spacer></v-spacer>
        <v-slide-x-reverse-transition mode="out-in">
          <v-icon
            :key="`icon-${isEditing}`"
            :icon="isEditing ? 'mdi-check' : 'mdi-circle-edit-outline'"
            @click="isEditing = !isEditing"
            style="margin-inline-end: 16px"
          />
        </v-slide-x-reverse-transition>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="dialog">
          <v-row class="py-2">
            <v-col cols="11">
              <VRow
                v-for="(data, index) in dialog_data"
                :key="index"
                class="align-center"
              >
                <v-col cols="12" md="7">
                  <v-autocomplete
                    :disabled="!isEditing"
                    v-model="data.fk_differentiation_exam_hall"
                    :items="hall_list"
                    item-value="id"
                    item-title="name_ar"
                    density="compact"
                    clearable=""
                    prependInnerIcon="mdi-test-tube"
                    :label="$t(`exam_hall`)"
                    :rules="[$required, duplicate]"
                    hide-details="auto"
                  >
                  </v-autocomplete>
                </v-col>
                <custom-text-field
                  v-model="data.student_count"
                  col="4"
                  :label="$t(`student_count`)"
                  :disabled="!isEditing"
                  :rules="[$required]"
                  icon="human-male-male"
                >
                </custom-text-field>
                <v-col cols="1" md="1">
                  <v-btn
                    :disabled="!isEditing"
                    class="align-self-end"
                    variant="elevated"
                    size="small"
                    elevation="4"
                    density="compact"
                    icon
                    @click="removeItem(index)"
                  >
                    <v-tooltip activator="parent" location="top">{{
                      $t('delete')
                    }}</v-tooltip>
                    <v-icon icon="mdi-minus-thick" color="error"></v-icon>
                  </v-btn>
                </v-col>
              </VRow>
            </v-col>
            <v-col cols="1" class="pt-0">
              <v-btn
                :disabled="!isEditing"
                variant="elevated"
                size="small"
                elevation="4"
                density="compact"
                icon
                @click="addDataDialog()"
              >
                <v-tooltip activator="parent" location="top">{{
                  $t('add')
                }}</v-tooltip>
                <v-icon icon="mdi-plus-thick" color="success"></v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <custom-btn
          :disabled="!isEditing"
          type="save"
          :click="() => saveData(true)"
        />
        <custom-btn @click="dialog = !dialog" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { getExamHall } from '@/plugins/methods/global-methods'

export default {
  props: {
    perm: Object,
    id: {
      type: Number,
    },
  },
  data() {
    return {
      items: [],
      pagination: undefined,
      college_data: {},
      is_update: false,
      update_id: null,
      drawer: false,
      hall_list: [],
      dialog: false,
      isEditing: false,
      dialog_data: [],
      student_count: null,
      compare_time: (start, end) => {
        if (start && end) {
          const [start_hr, start_min] = start.split(':').map(Number)
          const [end_hr, end_min] = end.split(':').map(Number)

          const startTime = start_hr * 60 + start_min
          const endTime = end_hr * 60 + end_min

          return startTime <= endTime || this.$t('wrong_time')
        }
      },
      duplicate: (value) => {
        const list = this.dialog_data.filter(
          (item) => item.fk_differentiation_exam_hall == value,
        )
        if (list?.length > 1) {
          return this.$t('duplicate_data')
        } else {
          return true
        }
      },

      headers: [
        { title: this.$t('group_no'), key: 'group_no' },
        { title: this.$t('period'), key: 'period' },
        {
          title: this.$t('time_from'),
          key: 'time_from',
        },
        { title: this.$t('time_to'), key: 'time_to' },
        {
          title: this.$t('distribution_from'),
          key: 'Distribution_from',
        },
        {
          title: this.$t('distribution_to'),
          key: 'Distribution_to',
        },
        { title: this.$t('note'), key: 'note' },
        { title: this.$t('actions'), key: 'actions', sortable: false },
      ],
    }
  },

  methods: {
    actionList(data) {
      return [
        ['select_halls', () => this.viewDialog(data)],
        [
          'add_scheuel',
          () =>
            this.$router.push({
              name: 'addmission-exams-schedule',
              params: { fk_differentiaion_batch: this.id,fk_differentiaion_group: data?.id },
            }),
        ],
      ]
    },
    async viewDialog(data) {
      this.update_id = data.id
      this.student_count = data.student_count
      this.hall_list = await getExamHall()
      const list = await this.getDialogData(data.id)
      this.dialog_data = list?.length || 0 ? list : [{}]
      this.dialog = true
      this.isEditing = false
    },
    async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
      await this.$axios(
        '/coordination/differentiation-group/filter-paginate/',
        {
          params: {
            search: txt_search,
            page: page,
            page_size: perPage,
            sort_by: order_data,
            field: 'fk_differentiaion_batch',
            value: this.id,
          },
        },
      )
        .then((response) => {
          this.items = response.data.results

          this.pagination = response.data.pagination
        })
        .catch(() => {})
    },

    async getDialogData(id) {
      const data = await this.$axios(
        `/coordination/differentiation-group-exam-hall/filter/`,
        {
          params: {
            field: 'fk_differentiation_group',
            value: id,
          },
        },
      )
      return data.data
    },

    async saveData(dialog = false) {
      const url = !dialog
        ? `/coordination/differentiation-group/`
        : `/coordination/differentiation-group-exam-hall/${this.update_id}/connect-group-with-exam-halls/`
      const { valid } = !dialog
        ? await this.$refs.form.validate()
        : await this.$refs.dialog.validate()
      if (valid) {
        this.college_data.fk_differentiaion_batch = this.id
        await this.$axios
          .post(url, !dialog ? this.college_data : this.dialog_data)
          .then(() => {
            this.$emit('alert', 'add')
            !dialog
              ? (this.resetForm(), this.getData())
              : (this.isEditing = !this.isEditing)
          })
          .catch((error) => {
            if (error.response.data) {
              let message = Object.values(error.response.data)[0]
              this.$emit(
                'warningAlert',
                message[0]?.replace(/[a-zA-Z]/g, '') ??
                  message.student_count[0]?.replace(/[a-zA-Z]/g, ''),
              )
            } else {
              this.$emit('alert', 'errorData')
            }
          })
      }
    },
    editData(data) {
      this.update_id = data.id
      this.drawer = true
      this.college_data = { ...data }
      this.is_update = true
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        await this.$axios
          .put(
            this.base_url +
              '/coordination/differentiation-group/' +
              this.college_data.id +
              '/',
            this.college_data,
          )
          .then(() => {
            this.$emit('alert', 'update')

            this.resetForm()
            this.getData()
          })
          .catch((error) => {
            if (error.response.data) {
              let message = Object.values(error.response.data)[0]
              this.$emit(
                'warningAlert',
                message[0]?.replace(/[a-zA-Z]/g, '') ??
                  message.student_count[0]?.replace(/[a-zA-Z]/g, ''),
              )
            } else {
              this.$emit('alert', 'errorData')
            }
          })
      }
    },
    async delData(delete_id, isPermission = false) {
      if (!isPermission) {
        if (delete_id != undefined) {
          var status
          await this.$axios
            .delete('/coordination/differentiation-group/' + delete_id + '/')
            .then(() => {
              status = true
            })
            .catch((error) => {
              status = error
            })
          return status
        }
      } else {
        return true
      }
    },

    async addDataDialog() {
      const { valid } = await this.$refs.dialog.validate()
      if (valid) {
        this.dialog_data.unshift({})
      }
    },
    removeItem(index) {
      this.dialog_data.splice(index, 1)
    },
    async validate() {
      setTimeout(() => this.$refs.date.validate(), 400)
    },
    resetForm() {
      this.$refs.form.reset()
      this.is_update = false
      this.drawer = this.drawer ? !this.drawer : !this.drawer
    },
  },
}
</script>
