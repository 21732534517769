<template>
  <custom-auto-complete
    :items="list"
    item-title="name_ar"
    item-value="id"
    :label="$t('branch')"
    icon="office-building"
    col="3"
  />
</template>
<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.$axios("system-management/branch/all/").then((response) => {
      this.list = response.data;
    });
  },
};
</script>
