<template>
  <VDialog v-model="value" class="text-center " width="300" :dir="$i18n.locale=='ar'?'rtl':'ltr'">
    <v-card class="ma-auto " width="300">
        <v-card-title class="bg-indigo text-white">
          <h3>
            {{ $t("delete-confirm") }}
          </h3>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-3 py-4">
          <small>{{ $t("confirm_delete") }}</small>
        </v-card-text>
        <v-divider />
        <v-card-actions style="min-height:40px; height:40px" class="pa-0 ma-0 d-flex mx-3"> 
          <custom-btn :text="$t('delete')" class="text-error w-50"  :click="confirmDelete" />
          
            <v-divider vertical/>
            <v-btn @click="value=false" :disabled="loading" class="w-50" >
              <span class="text-indigo"> {{ $t("cancel") }}</span>
            </v-btn>
        </v-card-actions>
      </v-card>
  </VDialog>
</template>
<script>
export default {
  props: {
    title: String,
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    async confirmDelete() {
      await this.$emit("confirm-delete");
     },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>