<template>
  <v-locale-provider :rtl="local == 'en' ? false : true">
    <v-navigation-drawer
      v-model="drawer"
      :temporary="breakPointXS || breakPointSM"
      :permanent="breakPointMD"
      :rail="rail"
      :width="sideBarWidth"
      class="sidebar"
    >
      <side-bar @changeRail="changeRail" class="h-100" />
    </v-navigation-drawer>
    <app-header @changeDrawer="changeDrawer" @changeLocal="changeLocal"></app-header>
    <v-main>
      <VContainer class="h-100" ref="homePage">
        <v-card class="mb-2">
          <v-row class="py-1">
            <v-col cols="12" md="8" sm="6">
              <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <v-breadcrumbs
                color="indigo"
                class="d-flex location-bread justify-end"
                :items="LocationBreadcrumb"
              ></v-breadcrumbs>
            </v-col>
          </v-row>
        </v-card>
        <router-view
          v-if="!error_connection"
          @alert="alert"
          @errorAlert="setErrorAlert"
          @successAlert="setSuccessAlert"
          @warningAlert="setInfoAlert"
          @infoAlert="setInfoAlert"
        ></router-view>
        <v-sheet
          v-if="error_connection"
          class="d-flex align-center justify-center flex-wrap text-center mx-auto px-4 mt-8"
          elevation="2"
          height="200"
          max-width="500"
          rounded
        >
          <div>
            <v-icon color="indigo" size="50">mdi-database-alert-outline</v-icon>
            <h1 style="font-size: 2rem">{{ $t("sorry") }}</h1>
            <h3>
              {{ $t("error-connection") }}
            </h3>
            <v-btn @click="windowReload" color="indigo" class="mt-4" rounded>
              {{ $t("retry") }}
            </v-btn>
          </div>
        </v-sheet>
      </VContainer>
    </v-main>
  </v-locale-provider>
  <AlertMessages
    v-model="show_alert"
    :iconColor="alert_icon_color"
    :icon="alert_icon"
    :title="alert_title"
    :message="alert_message"
    class="text-white"
  >
  </AlertMessages>
  <v-dialog
    color="indigo"
    :close-on-content-click="false"
    v-model="loadingPage"
    persistentid="loadingPage"
    width="auto"
  >
    <h1 v-if="!loadingImage" class="text-white">l o a d i n g . . .</h1>
    <div style="width: 80px; height: 80px">
      <v-img :src="loadingImage"></v-img>
    </div>
  </v-dialog>
</template>

<script>
import SideBar from "@/views/Layouts/SideBar.vue";
import AppHeader from "@/views/Layouts/AppHeader.vue";
import { mapState } from "vuex";
import moment from "moment-hijri";

export default {
  components: { SideBar, AppHeader },
  name: "HomeView",
  data() {
    return {
      local: "ar",
      sideBarWidth: 250,
      drawer: JSON.parse(localStorage.getItem("drawer")),
      rail: JSON.parse(localStorage.getItem("rail")),
      expand: null,
      show_alert: false,
      alert_icon_color: undefined,
      alert_icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
    };
  },
  mounted() {
    document.addEventListener("keydown", this.handelKeydown);
  },
  beforeMount() {
    document.removeEventListener("keydown", this.handelKeydown);
  },
  computed: {
    breadcrumbs() {
      return this.$route.matched.map((e) => {
        return { title: e.name };
      });
    },
    ...mapState({
      breadcrumb: "breadcrumb",
    }),
    showBreadcrumb() {
      if (this.$route.path == "/" || this.$route.path == "/summer/home") {
        // if(localStorage.getItem('role')==4)
        //     return true
        return false;
      }
      return true;
    },
    LocationBreadcrumb() {
      const list_data = [];
      if (this.user_profile?.governorate) list_data.push(this.user_profile?.governorate);
      if (this.user_profile?.directorate) list_data.push(this.user_profile?.directorate);
      if (this.user_profile?.date_today) list_data.push(this.user_profile?.date_today);
      return list_data;
    },
    breakPointXS() {
      return this.$vuetify.display.xs;
    },
    breakPointSM() {
      return this.$vuetify.display.sm;
    },
    breakPointMD() {
      return this.$vuetify.display.md;
    },
    breakPointLG() {
      return this.$vuetify.display.lg;
    },
  },
  methods: {
    windowReload() {
      window.location.reload();
    },
    getCurrentDate() {
      let current_date = moment().format("iYYYY/iMM/iDD");
      return current_date;
    },
    getCurrentDayName() {
      let formatter = new Intl.DateTimeFormat("ar", { weekday: "long" });
      let current_day_name = formatter
        .formatToParts(new Date())
        .find((part) => part.type === "weekday").value;
      return current_day_name;
    },
    changeDrawer(value) {
      this.drawer = value;
    },
    changeLocal(value) {
      this.local = value;
    },
    changeRail(value) {
      this.rail = value;
    },

    alert(type) {
      if (type == "add" || type == "update") {
        this.alert_icon_color = "success";
        this.alert_icon = "check-circle";
        this.alert_title = this.$t("success");
        this.alert_message =
          type == "add" ? this.$t("data_added") : this.$t("data_updated");
        this.showAlert();
      } else if (type == "errorData") {
        this.alert_icon_color = "error";
        this.alert_icon = "cancel";
        this.alert_title = this.$t("failure");
        this.alert_message = this.$t("error_in_data");
        this.showAlert();
      }
    },
    setErrorAlert(message) {
      this.alert_icon_color = "error";
      this.alert_icon = "cancel";
      this.alert_title = this.$t("failure");
      this.alert_message = message;
      this.showAlert();
    },
    setSuccessAlert(message) {
      this.alert_icon_color = "success";
      this.alert_icon = "check-circle";
      this.alert_title = this.$t("success");
      this.alert_message = message;
      this.showAlert();
    },
    setWarningAlert(message) {
      this.alert_icon_color = "warning";
      this.alert_icon = "alert";
      this.alert_title = this.$t("warning");
      this.alert_message = message;
      this.showAlert();
    },
    setInfoAlert(message) {
      this.alert_icon_color = "info";
      this.alert_icon = "information";
      this.alert_title = this.$t("info");
      this.alert_message = message;
      this.showAlert();
    },
    showAlert() {
      this.show_alert = true;
      setTimeout(() => {
        this.show_alert = false;
      }, 1500);
    },
  },
};
</script>
<style>
.v-card-table {
  margin-top: 10px;
}
.v-expansion-panel-title__overlay {
  display: none;
}
</style>
