<template>
  <v-card>
    <v-card-item>
      <v-form ref="form">
        <v-col>
          <v-row>
            <custom-text-field
              v-model="value.differentiation_no"
              :label="$t('differentiation_no')"
              icon="numeric"
              col="3"
              disabled
            />
            <custom-text-field
              v-model="value.fk_enrollment_year.year_m"
              :label="$t('enrollment_year')"
              icon="calendar"
              col="3"
              disabled
            /> 

            <drop-list
              v-model="value.accommodation_type"
              name="accommodation_type"
              col="3"
              :rules="[$required]"
            />

            <custom-text-field
              v-if="value.accommodation_type == 1"
              v-model="value.reason_4_ask_4_housing"
              :label="$t('reason_4_ask_4_housing')"
              icon="application-edit-outline"
              col="3"
              :rules="[$max_length(250)]"
            />
          </v-row>
          <v-row>
            <drop-list
              name="committee_opinion"
              v-model="value.committee_opinion"
              col="3"
              :rules="[$required]"
            />
            <custom-text-field
              v-model="value.discussion_committee"
              :label="$t('discussion_committee')"
              icon="account-group"
              col="3"
            />
          </v-row>
          <v-divider class="my-5" />
          <h4 class="my-3">{{ $t('sending_party') }}</h4>
          <v-row>
            <custom-text-field
              v-model="value.fk_entity.entity"
              :label="$t('sending_party')"
              icon="send"
              col="3"
              :rules="[
                value.fk_entity.discrption || value.fk_entity.phone_no
                  ? $required
                  : [],
                $max_length(60),
              ]"
            />
            <custom-text-field
              v-model="value.fk_entity.discrption"
              :label="$t('description')"
              icon="note-outline"
              col="3"
              :rules="[
                value.fk_entity.entity || value.fk_entity.phone_no
                  ? $required
                  : [],
                $max_length(250),
              ]"
            />
            <custom-text-field
              v-model="value.fk_entity.phone_no"
              :label="$t('phone_number')"
              icon="phone"
              col="3"
              :rules="[
                value.fk_entity.entity || value.fk_entity.discrption
                  ? $required
                  : [],
                $max_length(15),
              ]"
            />
            <custom-text-field
              v-model="value.discussion_committee_proposal"
              :label="$t('discussion_committee_proposal')"
              :rules="[$max_length(250)]"
              col="3"
            />
          </v-row>
        </v-col>
      </v-form>
    </v-card-item>
    <v-card-actions>
      <custom-btn type="update" :click="emit" />
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      value: {},
      dropList: {
        fk_entity: {},
        academic_data: {},
      },
    }
  },
  created() {
    this.value = { ...this.data }
  },
  methods: {
    async emit() {
      const { valid } = await this.$refs.form.validate()
      if (valid) this.$emit('updateEvent', this.updateData())
    },
    async updateData() {
      return await this.$axios.patch(
        `student-affairs/student-academic-data/${this.value.id}/`,
        this.value
      )
    },
  },
}
</script>