<template>
  <v-col cols="12" :md="col">
    <v-autocomplete
      v-model="selectSystemTypes"
      :items="list"
      :multiple="multiple"
      item-title="name"
      item-value="id"
      hide-details="auto"
      clearable
      :label="$t('system_type')"
      density="compact"
      prepend-inner-icon="mdi-list-status"
      :rules="rules"
    />
  </v-col>
</template>
<script>
export default {
  props: {
    col: {
      type: Number,
      default() {
        return 3;
      },
    },
    rules: {
      type: Object,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      list: [],
      
    };
  },
  async created() {
    await this.$axios("choices/choice-data/", {
      params: {
        param: "SystemTypeChoice",
      },
    })
      .then((response) => {
        this.list = response.data;
      })
      .catch(() => {});
  },
};
</script>
