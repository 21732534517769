<template>

  <custom-auto-complete
    :items="summercenters"
    item-title="name_ar"
    item-value="id"
    :label="$t('summer-centre')"
    icon="domain"
    :col="col"
    :rules="rules"
  />
</template>


<script>



export default {

  methods:{
    getSummer(){
      if(this.dir){
        this.$axios('api/summer/school-select-list?directorate='+this.dir +'governorate='+this.gov).then((e)=>{
        this.summercenters=  e.data;
    });
      }else{
          this.summercenters=[];
      }    
    },
  
  },
    props:{
    col:{
      type:Number,
      default(){
        return 3;
      },
    dir:{
      type:Object
    },
    gov:{
      type:Object
    }
    },
    rules:{
      type:Object,
       
    }

  },
  
  data() {
    return {
      items: [],
      summercenters:[],
    }
  },

  async created() {
  if(this.dir){
    this.summercenters = await this.roleList()
    }
  },
   watch:{
    gov(){
      this.getSummer();
    }
  }
}
</script>