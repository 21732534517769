<template>
{{dropList.fk_semester_subject_4b}}
  <!-- الفترات -->
  <nav-drawer
    v-show="drawer"
    :title="update_id ? 'update' : 'create'"
    v-model="drawer"
    temporary
  >
    <template v-slot:body>
      <v-form ref="form">
        <datetime-picker
          v-model="period.time_from"
          label="تاريخ البداية"
          type="time"
          datetime_picker="time_from"
          :rules="[$required, $isTime]"
        />
        <datetime-picker
          v-model="period.time_to"
          label="تاريخ النهاية"
          type="time"
          datetime_picker="time_to"
          :rules="[
            $isTime,
            $required,
            $time_min_max(period.time_from, period.time_to),
          ]"
        />
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn
        :type="update_id ? 'update' : 'save'"
        :click="update_id ? updateData : savePeriod"
      />
      <custom-btn @click.stop="resetForm(), (drawer = false)" />
    </template>
  </nav-drawer>
  <!-- تعديل الجدول  -->
  <nav-drawer
    v-show="drawer_1"
    :title="update_id ? 'update' : 'create'"
    v-model="drawer_1"
    temporary
  >
    <template v-slot:body>
      <v-form ref="form_1">
        <drop-list
          name="subject_for_batch"
          v-model="dropList.fk_semester_subject_4b"
          :param="fk_semester"
          :rules="[$required]"
          @update:modelValue="dropList.fk_teacher=undefined"

        />
        <drop-list
          name="employee_for_subject"
          v-model="dropList.fk_teacher"
          :param="dropList.fk_semester_subject_4b"
          :rules="[$required]"
        />
        <drop-list
          name="building"
          v-model="dropList.fk_building"
          param="0"
          :rules="[$required]"
          @update:modelValue="dropList.fk_hall=undefined"
        />
        <drop-list
          name="get_halls_4_building"
          v-model="dropList.fk_hall"
          :param="dropList.fk_building"
          :rules="[$required]"
        />
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn
        :type="update_id ? 'update' : 'save'"
        :click="update_id ? updateData : saveData"
      />
      <custom-btn @click.stop="resetForm(), (drawer = false)" />
    </template>
  </nav-drawer>

  <v-table>
    <thead>
      <tr>
        <th>{{$t('day')}}</th>
        <th v-for="(val, key) in headers" :key="key">
          <span>{{ val.order_no }} </span>
          <br />
          {{ val.time_from.slice(0, -3) }} - {{ val.time_to.slice(0, -3) }}
          <v-btn
            v-if="key == headers.length - 1"
            icon="mdi-minus"
            variant="tonal"
            density="compact"
            @click=";(dialogVisible = true), (del_period = val.id)"
          />
        </th>
        <th>
          <v-btn
            icon="mdi-plus"
            variant="tonal"
            density="compact"
            @click="openDialog"
          />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(day, day_key) in days" :key="day_key">
        <td>{{ day?.name }}</td>
        <td v-for="(val, key) in items[day_key]" :key="key">
          <v-row v-if="val">
            <div class="d-flex flex-column">
              <h6>{{ val.fk_hall }}</h6>
              <h3 class="mx-auto">{{ val.fk_semester_subject_4b_name_ar }}</h3>
              <h6>{{ val.fk_teacher_name }}</h6>
            </div>
            <v-col>
              <v-icon
                icon="mdi-pencil-outline"
                @click="editData(val)"
                size="small"
              />
              <v-icon
                icon="mdi-trash-can-outline"
                size="small"
                @click=";(delete_id = val.id), (dialogVisibleData = true)"
              />
            </v-col>
          </v-row>

          <v-btn
            v-else
            v-bind="activatorProps"
            icon="mdi-plus"
            variant="tonal"
            density="compact"
            @click="
              ;openDialog_1(),
                (dropList.day_id = day.id),
                (dropList.key = key)
            "
          />
        </td>
      </tr>
    </tbody>
  </v-table>

  <custom-delete-dialog v-model="dialogVisible" @confirmDelete="delPeriod" />
  <custom-delete-dialog v-model="dialogVisibleData" @confirmDelete="delData" />
</template>
<script>
import { getChoices ,getSubjectForBatch} from '@/plugins/methods/global-methods'
export default {
  props: {
    fk_semester_group_4b: Number,
    fk_semester: Number,
  },
  data() {
    return {
      items: [],
      days: [],
      delete_id: undefined,
      del_period: undefined,
      dropList: {},
      period: {},
      dialogVisible: false,
      dialogVisibleData: false,
      visi_dialog: false,
      updated_id: undefined,
      drawer: null,
      drawer_1: null,
      filter_days: [],
      headers: [],
      subjects:[]
    }
  },
  async mounted() {
    this.getPeriod()
    await this.getDays()
    this.getData()
  },
 async created() {
      this.subjects= await getSubjectForBatch(this.fk_semester).catch(() => {
            this.$emit('alert', 'errorData')
          })
  },
  methods: {
    filter_item(day_id) {
      const data = this.items.filter((e) => e.day == day_id)
      if (this.headers.length == data.length) return data
      else {
        let data_list = []
        this.headers.map((h) => {
          const list_filter = data.find(
            (e) => e.fk_period_4_schedule == h.order_no
          )
          if (list_filter) data_list.push(list_filter)
          else data_list.push(undefined)
        })
        return [...data_list]
      }
    },
    openDialog() {
      this.resetForm()
      this.drawer = true
    },
    openDialog_1() {
      this.resetForm()
      this.drawer_1 = true
    },
    resetForm() {
      this.$refs.form.reset()
      this.$refs.form_1.reset()
      this.dropList = {}
      this.period = {}
      this.delete_id = undefined
      this.del_period = undefined
      this.update_id = undefined
      this.drawer_1 = false
    },
    async getData() {
      await this.$axios('academic-affairs/schedules-4B/', {
        params: {
          fk_semester_group_4b: this.fk_semester_group_4b,
        },
      })
        .then((response) => {
          this.items = []
          this.days.map((d) => {
            const list = response.data.filter((f) => f.day == d.id)
            let data = []
            this.headers.map((h) => {
              const list_filter = list.find(
                (e) => e.fk_period_4_schedule == h.id
              )
              data.push(list_filter)
            })
            this.items.push(data)
          })
        })
        .catch(() => {
          this.$emit('alert', 'errorData')
        })
    },
    async editData(data) {
      this.resetForm()
      this.update_id = data.id
      this.dropList = { ...data }
      this.drawer_1 = true
    },
    async getPeriod() {
      await this.$axios('academic-affairs/period-4Schedule/filter/', {
        params: {
          field: 'fk_semester_group_4b',
          value: this.fk_semester_group_4b,
        },
      })
        .then((response) => {
          this.headers = response.data
        })
        .catch(() => {
          this.$emit('alert', 'errorData')
        })
    },
 
    async getDays() {
      await getChoices('DayChoice')
        .then((response) => {          
          this.days = response.filter((e) => e.id < 7)
          this.filter_days = [...this.days.filter((e) => e.id == 1)]
        })
        .catch(() => {
          this.$emit('alert', 'errorData')
        })
    },
    async delPeriod() {
      if (this.del_period != undefined) {
        await this.$axios
          .delete('academic-affairs/period-4Schedule/' + this.del_period + '/')
          .then(async () => {
            this.resetForm()
            await this.getPeriod()
            await this.getDays()
            await this.getData()
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })
        this.dialogVisible = false
      }
    },
    async savePeriod() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        await this.$axios
          .post('academic-affairs/period-4Schedule/', {
            fk_semester_group_4b: this.fk_semester_group_4b,
            ...this.period,
          })
          .then(async () => {
            this.$emit('alert', 'add')
            this.resetForm()
            this.drawer = false
            await this.getPeriod()
            await this.getData()
          })
          .catch((e) => {
            if (
              e.response?.data?.detail ==
              'الفترة او جزء من الفترة موجودة مسبقاً'
            )
              this.$emit('errorAlert', 'الفترة او جزء من الفترة موجودة مسبقاً')
            else this.$emit('alert', 'errorData')
          })
      }
    },
    async saveData() {
      const { valid } = await this.$refs.form_1.validate()
      if (valid)
        await this.$axios
          .post('academic-affairs/schedules-4B/', {
            fk_period_4_schedule: this.headers[this.dropList.key].id,
            ...this.dropList,
            day: this.dropList.day_id,
            fk_semeter_subject:this.subjects.find(item=>item.subject_id==this.dropList.fk_semester_subject_4b).fk_semeter_subject
          })
          .then(() => {
            this.$emit('alert', 'add')
            this.resetForm()
            this.getData()
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })
    },
    addDay() {
      const index = this.filter_days?.length

      if (index < 6) {
        let item = this.days.find((e) => e.id == index + 1)
        this.filter_days.push(item)
      }
    },
    removeDay() {
      if (this.filter_days?.length > 1) this.filter_days.length--
    },
    async delData() {
      await this.$axios
        .delete('academic-affairs/schedules-4B/' + this.delete_id + '/')
        .then(() => {
          this.resetForm()
          this.$emit('alert', 'del')
          this.getData()
        })
        .catch(() => {
          this.$emit('alert', 'errorData')
        })
      this.dialogVisibleData = false
    },
    async updateData() {
      const { valid } = await this.$refs.form_1.validate()
      if (valid)
        await this.$axios
          .put(
            `academic-affairs/schedules-4B/${this.dropList.id}/`,
            this.dropList
          )
          .then(() => {
            this.$emit('alert', 'update')
            this.resetForm()
            this.visi_dialog = false
            this.getData()
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })
    },
  },
}
</script>