import Vuex from "vuex";
import router from "@/router/router";
import users from "./Users/users.module";
import summer_globals from "@/store/Globals/summer_globals.module";
import summer from "@/store/Summer/summer.module";
import school from "@/store/School/school.module";
// eslint-disable-next-line
import axios from "axios";
import { jwtDecode } from "jwt-decode";
const store = new Vuex.Store({
  state: {
    theme:'light',
    Bearer: undefined,
    User: undefined,
    permissions: ["school.dashboard"],
    lang: "ar",
    // role:undefined,
    direction: "rtl",
    expire: undefined,
    isAuthenticated: undefined,
    username: undefined,
    login_error: false,
    loading: false,
    drawer: true,
    role_list: [
      "sys_admin",
      "gen_admin",
      "gov_admin",
      "dir_admin",
      "summer_admin",
      "user",
    ],

    API_URL: "http://127.0.0.1:9009/api/token/",  

    breadcrumb: [],
  }, 
  mutations: {
    // setrole(state,role){
    //     state.role=role
    // },
    toggleTheme(state) {
      state.theme = state.theme==='dark'?'light':'dark'
    },
    updateBreadcrumbValue(state, value) {
      state.breadcrumb = value;
    },
    updateLoadingValue(state, value) {
      state.loading = value;
    },
    updateDrawer(state, value) {
      state.drawer = value;
    },
    setAuthenticated(state, bearer) {
      state.Bearer = bearer;
    },
    setPermission(state, permission) {
      state.permissions = permission;
    },
    setUser(state, user) {
      state.User = user;
    },
    logout(state) {
      state.Bearer = "";
      // state.permissions=value
      state.User = "";
    },
    setlang(state, lang) {
      state.lang = lang;
    },
    setdir(state, dir) {
      state.direction = dir;
    },
    setExpire(state, expire) {
      state.expire = expire;
    },
    setUsername(state, username) {
      state.username = username;
    },
    setLoginError(state) {
      state.login_error = true;
    },
  },
  getters: {
    // getrole:(state)=>state.role,

    getbrebar: (state) => state.Bearer,
    getpermission: (state) => state.permissions,
    getuser: (state) => state.User,
    getdrawer: (state) => state.drawer,
    checkrole:
      (state) =>
      (role = []) =>
        role.includes(state.role_list[localStorage.getItem("role")]),
    checkPermission:
      (state) =>
      (perm, role = []) => {
        if (
          (role.includes(state.role_list[localStorage.getItem("role")]) ||
            role.length == 0) &&
          localStorage.getItem("userinfo").includes(perm)
        )
          return true;
        else return false;
      },

    getlang: (state) => state.lang,

    getexpire: (state) => state.expire,
    getdir: (state) => state.direction,

    header(state) {
      return "Bearer " + state.Bearer;
    },
  },

  /* eslint-disable no-unused-vars */
  actions: {
    async setAuthenticated({ commit, getters, state, dispatch }, response) {
      if (response && response.data) {
        const token = response.data.access;
        const decoded = jwtDecode(token);
        commit("setAuthenticated", token);
        commit("setExpire", decoded.exp);
        commit("setUser", decoded.user_id);
        commit("setPermission", response.data.permission);
        localStorage.setItem("role", decoded.role);

        localStorage.setItem("token", token);
        localStorage.setItem("refresh_token", response.data.refresh);

        const user = await axios
          .get("users/api/profile/", {
            headers: { Authorization: "Bearer " + token },
            // params: {
            //   user: decoded.user_id,
            // },
          })
          .catch((error) => {
            commit("setLoginError");
          });
        if (user && user.data && user.data.username) {
          localStorage.setItem("username", user.data.username);
          commit("setUsername", user.data.username);
        }
        window.location.reload();
        setTimeout(() => {
          dispatch("logout");
          window.location.reload();
        }, (store.getters.getexpire - Date.now() / 1000) * 1000);
        localStorage.setItem("userinfo", response.data.permission);
          router.push('/')
      }
    },
    async updatePermission({ commit }, response) {
      try {
        commit("setPermission", response);
      } catch (error) {
        commit("setLoginError");
      }
    },
    async updateAuthenticated({ commit }, response) {
      try {
        const decoded = jwtDecode(response);
        commit("setExpire", decoded.exp);
        commit("setAuthenticated", response);
        commit("setUser", decoded.user_id);
      } catch (error) {
        commit("setLoginError");
      }
    },
    updatelanguage({ commit }, value) {
      commit("setlang", value);
    },
    updatedirection({ commit, state }, value) {
      commit("setdir", value);
    },
    async logout({ commit }) {
      console.log("--sfd--")
      await axios.post("users/api/logout/",{
        refresh_token: localStorage.getItem("refresh_token")
      },{
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }).then(()=>{
        commit("logout");
        localStorage.removeItem("username");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("token");
        localStorage.removeItem("userinfo");
        localStorage.removeItem("role");
        window.location.reload()
      }).catch(()=>{
        localStorage.removeItem("username");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("token");
        localStorage.removeItem("userinfo");
        localStorage.removeItem("role");
      });
    },
  },
  modules: {
    users,
    summer_globals,
    summer,
    school
  },
});

export default store;
