<template>

  <custom-auto-complete
    :items="activity"
    item-title="name_ar"
    item-value="id"
    :label="$t('activity')"
    icon="soccer"
    :col="col"
    :rules="rules"
  />
</template>
<script>

export default {
  data() {
    return {
      activity: [],
    }
  },
  async created() {
   this.$axios('activities/activity-data/all/').then((e)=>{
     this.activity = e.data
   })
  },
}
</script>