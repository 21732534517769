<template>
  <nav-drawer
    v-show="drawer"
    v-model="drawer"
    :title="is_update ? 'update' : 'create'"
    temporary
  >
    <template v-slot:body>
      <v-form ref="form">
        <drop-list
          name="get_users_by_ET"
          col="12"
          v-model="dropList.fk_user"
          icon="human-male-board"
          :rules="[$required]"
        />
        <datetime-picker
          col="12"
          cclass="px-2"
          v-model="dropList.date"
          datetime_picker="date"
          :label="$t('date')"
          :rules="[$required]"
        ></datetime-picker>
        <datetime-picker
          type="time"
          col="12"
          v-model="dropList.time_from"
          cclass="px-2"
          icon="mdi-timer-play-outline"
          datetime_picker="time_from"
          :label="$t('startperiod')"
        >
        </datetime-picker>
        <datetime-picker
          type="time"
          col="12"
          v-model="dropList.time_to"
          cclass="px-2"
          icon="mdi-timer-stop-outline"
          datetime_picker="time_to"
          :label="$t('endperiod')"
          :rules="[$required, $time_min_max(dropList.time_from, dropList.time_to)]"
        ></datetime-picker>
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn v-if="!is_update" type="save" @click="saveData()" />
      <custom-btn v-if="is_update" type="update" @click="updateData()" />
      <custom-btn type="cancel" @click="drawer = false" />
    </template>
  </nav-drawer>

  <custom-data-table-2
    :headers="headers"
    :items="items"
    :pagination="pagination"
    :method="getData"
    :delItem="delData"
    :editItem="editData"
    :actionList="true"
    :perm="{}"
    exports
    :create="() => opendailog()"
  />
</template>
<script>
export default {
  props: {
    perm: Object,
    id: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      items: [],
      pagination: undefined,
      dropList: {},
      is_update: false,
      drawer: null,
      headers: [
        { title: this.$t("monetor_name"), key: "fk_user_name" },
        {
          title: this.$t("buildings"),
          key: "fk_building_name_ar",
        },
        {
          title: this.$t("committees_name"),
          key: "fk_exam_hall_name_ar",
        },
        { title: this.$t("startperiod"), key: "time_from" },
        { title: this.$t("endperiod"), key: "time_to" },
        { title: this.$t("date"), key: "date" },
        { title: this.$t("actions"), key: "actions" },
      ],
    };
  },

  methods: {
    opendailog() {
      this.drawer = true;
      this.is_update = false;
      this.resetForm();
    },
    resetForm() {
      this.dropList = {};
      this.$refs.form.reset();
      this.is_update = false;
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        await this.$axios
          .put(
            this.base_url +
              "coordination/differentiation-monitor/" +
              this.update_id +
              "/",
            this.dropList
          )
          .then(() => {
            this.$emit("successAlert", this.$t("data_updated"));
            this.resetForm();
            this.drawer = false;
            this.getData();
          })
          .catch(() => {
            this.$emit("errorAlert", this.$t("updated_failure"));
          });
      }
    },
    editData(data) {
      this.update_id = data.id;
      this.drawer = true;
      (this.dropList = { ...data }), (this.dropList.fk_building = data.fk_building);

      // this.fk_building_name_ar = this.dropList.fk_building
      this.is_update = true;
    },
    async delData(delete_id) {
      if (delete_id != undefined) {
        var status;
        await this.$axios
          .delete("coordination/differentiation-monitor/" + delete_id + "/")
          .then(() => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
    async getData() {
      await this.$axios(`coordination/differentiation-monitor/filter/`, {
        params: {
          field: "fk_exam_hall",
          value: this.id,
        },
      })
        .then((response) => {
          this.items = response.data;
        })
        .catch(() => {});
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.dropList.fk_exam_hall = this.id;
        await this.$axios
          .post("coordination/differentiation-monitor/", this.dropList)
          .then(() => {
            this.$emit("successAlert", this.$t("data_added"));
            this.resetForm();
            this.getData();
            this.drawer = false;
          })
          .catch(() => {
            this.$emit("errorAlert", this.$t("error_in_data"));
          });
      }
    },
  },
};
</script>
