<template>
  <v-col>
            <drop-list
              name="batch_coordination"
              v-model="dropList.fk_differentiation_batch"
              col="12"
              @update:model-value="getitemData"
            />
          </v-col>
  <v-row>
    <v-col>
      <custom-card class="ma-5 w-50">
        <template v-slot>
          <v-col>
            <custom-text-field
              v-model="search_1"
              :label="$t('search-here')"
              col="12"
              icon="magnify"
              :hide_details="true"
            />
            <v-data-table
              v-model="selected_1"
              v-model:search="search_1"
              :headers="headers_1"
              :items="items_1"
              show-select
              items-per-page="-1"
              
            >
              <template #bottom />
            </v-data-table>
          </v-col>
        </template>
      </custom-card>
    </v-col>
    <v-col class="pt-0 px-3 my-auto" cols="12" xs="12" sm="12" md="1" lg="1">
      <div class="d-flex align-center flex-column">
        <v-btn
          class="bg-indigo mb-3"
          size="small"
          color="white"
          density="default"
          variant="tonal"
          @click="addTo"
        >
          <v-icon color="white"> mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn
          class="bg-indigo mb-3"
          size="small"
          color="white"
          density="default"
          variant="tonal"
          @click="removeFrom"
        >
          <v-icon color="white">mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col>
      <custom-card class="ma-5 w-50">
        <template v-slot>
          <custom-text-field
            v-model="search_2"
            :label="$t('search-here')"
            col="12"
            icon="magnify"
            :hide_details="true"
          />
          <v-data-table
            v-model="selected_2"
            v-model:search="search_2"
            :headers="headers_2"
            :items="items_2"
            show-select
            items-per-page="-1"
          >
            <template #bottom />
          </v-data-table>
        </template>
      </custom-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
    data() {
        return {
            item_3:[],
            dropList:{},
            selected_1:[],
            search_1:null,
            items_1:[
            
            ],
          headers_1: [
          { title: this.$t('name_ar'), key: 'student_name' , sortable: false },        
          ],
            selected_2:[],
              search_2:null,
              items_2:[
                
              ],
          headers_2: [
        { title: this.$t('name_ar'), key: 'student_name' , sortable: false },        
        ]
        }
    },
  // computed:{
  //     checkkk(){
  //         if (this.items_1.length == 0) {
          
  //         return this.headers_1.map((i)=> ({...i, sortable : false}))
  //       }else{
          
  //         return this.headers_1.forEach((obj)=> obj.sortable = false)
  //       }
  //     }
  // },

    methods: {
      // check(){
      //   if(this.items_1.length == 0){
      //     return 
      //   }
      //   return this.headers_1;
      // },
        getitemData(){
          if (this.dropList.fk_differentiation_batch != undefined || this.dropList.fk_differentiation_batch != null  ) {
            
            this.$axios('coordination/student-admission-status/'+ this.dropList.fk_differentiation_batch +'/').then((e)=>{
              this.items_1 =e.data.student_is_not_admission;
              this.items_2 =e.data.student_admission;
        })
        }
        else{
          this.items_1=[];
          this.items_2=[];
        }
        },
        addTo(){
          
          
          const data_include = this.items_1.filter((item)=>this.selected_1.includes(item.id))
            const data_exclude = this.items_1.filter((item)=>!this.selected_1.includes(item.id))
            this.items_1 = data_exclude
            data_include.map(v=>{                                                           
                this.items_2.push(v)  
            })
            this.items_1.forEach(obj=>{
              obj.differentiationz_status=false
            })
            this.items_2.forEach(obj=>{
              obj.differentiation_status=true
            })
            this.selected_1=[]  
            const updateObject={};
            this.item_3= this.items_1.concat(this.items_2)
            this.$axios
            .post('coordination/student-admission-status/', this.item_3)
            .then(() => {
              this.$emit('alert', 'add')
            })
            .catch(() => {
                this.$emit('alert', 'errorData')
            })
          }, 
        removeFrom(){
            const data_include = this.items_2.filter((item)=>this.selected_2.includes(item.id))
            const data_exclude = this.items_2.filter((item)=>!this.selected_2.includes(item.id))
            this.items_2 = data_exclude
            data_include.map(v=>{                            
                this.items_1.push(v)                                
            })
            this.selected_2=[]    
            this.items_1.forEach(obj=>{
              obj.differentiation_status=false
            })
            this.items_2.forEach(obj=>{
              obj.differentiation_status=true
            })
            this.item_3= this.items_1.concat(this.items_2)
            console.log("remove item3",this.item_3);
            this.$axios
            .post('coordination/student-admission-status/', this.item_3)
            .then(() => {
              this.$emit('alert', 'add')
            })
            .catch(() => {
                this.$emit('alert', 'errorData')
            })
        }
    },
    
}
</script>