import Axios from 'axios'
import { base_url } from '@/main'

// export const url =  () =>"http://127.0.0.1:9009/"
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
export const url =  () =>"https://quranacademy.opensoftye.com/"
// export const url =  () =>"https://academycollege.opensoftye.com/"

// export const api_url = "https://gmsl.gov.ye/";



//نوع الوثائق
export const getdocmunet = async () =>
  await Axios.get(base_url + '/system-management/document-type-data/').then(
    (e) => e.data.results,
  )

//المواهب
export const getTalentChoices = async () =>
  await Axios.get(base_url + 'student-affairs/talent/all/').then((e) => e.data)

//الفروع
export const getBranch = async () =>
  await Axios.get(base_url + 'system-management/branch/').then((e) => e.data.results)

export const getGovernorate = async () =>
  await Axios.get(base_url + 'governorate/').then((e) => e.data)
// export const getConditions = async()=> await Axios.get(base_url+"/system-management/condition-data/").then((e)=> e.data.results);
export const getqualificationtype = async () =>
  await Axios.get(base_url + 'choices/choice-data/', {
    params: {
      param: 'QualificationTypeChoice',
    },
  }).then((e) => e.data)

export const getSections = async () =>
  await Axios.get(base_url + '/system-management/sections/all/').then(
    (e) => e.data,
  )
const role_list = [
  'sys_admin',
  'gen_admin',
  'gov_admin',
  'dir_admin',
  'summer_admin',
  'user',
]

export function checkPermission(perm, role = []) {
  if (
    (role.includes(role_list[localStorage.getItem('role')]) ||
      role.length == 0) &&
    localStorage.getItem('userinfo').includes(perm)
  )
    return true
  else return false
}

// export const getSpecializationList = async()=> await Axios.get(base_url+"api/summer/day-summer").then((e)=>e.data.results);

///////////////////////////////////////////choice////////////////////////////////////////////////
///////////////////////////////////////////choice////////////////////////////////////////////////
export const getEducationalLevel = async () =>
  await Axios.get(base_url + 'choices/choice-data/', {
    params: {
      param: 'EducationalLevelChoice',
    },
  }).then((e) => e.data)

export const getLevelChoice = async () =>
  await Axios.get(base_url + 'choices/choice-data/', {
    params: {
      param: 'LevelChoice',
    },
  }).then((e) => e.data)

export const getSubjects = async () =>
  await Axios.get(base_url + 'system-management/subjects/all/')
    .then((response) => response.data)
    .catch((error) => error)

export const getDocumentType = async () =>
  await Axios.get(base_url + 'system-management/document-type-data/all/')
    .then((response) => response.data)
    .catch((error) => error)
export const getSystemTypeChoice = async () =>
  await Axios.get(base_url + 'choices/choice-data/', {
    params: { param: 'SystemTypeChoice' },
  })
    .then((e) => e.data)
    .catch(() => 'error')

//// جلب بيانات المباني
export const getBuildings = async (semester) =>
  await Axios.get(
    base_url +
    `system-management/building-data/${semester ? 'filter' : 'all'}/`,
    {
      params: {
        field: 'is_housing',
        value: semester,
      },
    },
  )
    .then((e) => e.data)
    .catch(() => 'error')

// جلب القاعات حسب المبنى
export const getHalls = async (param) =>
  await Axios.get(base_url + 'system-management/hall-data/filter/', {
    params: {
      field: 'fk_building',
      value: param,
    },
  })
    .then((e) => e.data)
    .catch(() => 'error')

// جلب اللجان حسب المبنى
export const getCommittees = async (param) =>
  await Axios.get(base_url + 'system-management/hall-data/filter/', {
    params: {
      field: 'fk_building',
      value: param,
    },
  })
    .then((e) => e.data)
    .catch(() => 'error')

//// جلب بيانات السكنات
export const getAccommodations = async (semester) =>
  await Axios.get(
    base_url +
    `accommodation/student-accommodation/${semester ? 'filter' : 'all'}/`,
    {
      params: {
        field: 'fk_building',
        value: semester,
      },
    },
  )
    .then((e) => e.data)
    .catch(() => 'error')

//// جلب بيانات الاسرة
export const getBeds = async (semester) =>
  await Axios.get(
    base_url + `accommodation/bed/${semester ? 'filter' : 'all'}/`,
    {
      params: {
        field: 'fk_student_accommodation',
        value: semester,
      },
    },
  )
    .then((e) => e.data)
    .catch(() => 'error')

export const getQualification = async () =>
  await Axios.get(base_url + 'academic-affairs/academic-qualification/all/')
    .then((response) => response.data)
    .catch((error) => error)
export const getSecondaryDepartmentChoice = async () =>
  await Axios.get(base_url + 'choices/choice-data/', {
    params: { param: 'SecondaryDepartmentChoice' },
  })
    .then((response) => response.data)
    .catch((error) => error)
export const getEducationalLevelChoice = async () =>
  await Axios.get(base_url + 'choices/choice-data/', {
    params: { param: 'EducationalLevelChoice' },
  })
    .then((response) => response.data)
    .catch((error) => error)

//id احضار بيانات الطالب بحسب ال
export const getDataStudent = async (student_id) =>
  await Axios.get(base_url + `student-affairs/students/${student_id}/`)
    .then((e) => e.data)
    .catch(() => 'error')

//احضار اختيارات حالة الشرط
export const getCheckConditionStatusChoice = async () =>
  await Axios.get('/choices/choice-data/', {
    params: { param: 'CheckConditionStatusChoice' },
  })
    .then((e) => e.data)
    .catch(() => 'error')

//انواع تحضير الطلاب
export const getPrepareChoice = async () =>
  await Axios.get('/choices/choice-data/', {
    params: { param: 'PrepareChoice' },
  })
    .then((e) => e.data)
    .catch(() => 'error')

//جلب المواد للفصل دون تكرار
export const getSubjectForSemesterWithoutRepetition = async (fk_semester) =>
  await Axios.get(
    base_url + 'system-management/semester-subject-data/data-for-save/',
    {
      params: {
        semester_pk: fk_semester,
      },
    },
  )
    .then((e) => {
      return e.data
    })
    .catch(() => 'error')


 
//جلب قسم المادة
export const getSubjectSection = async () =>
  await Axios.get(base_url + 'choices/choice-data/', {
    params: {
      param: 'SubjectSectionChoice',
    },
  })
    .then((e) => e.data)
    .catch(() => 'error')

export const getGradingSystem = async () =>
  await Axios.get(base_url + 'system-management/grading-system-data/all/')
    .then((e) => e.data)
    .catch(() => 'error')

//جلب الكليات
export const getCollege = async () =>
  await Axios.get(base_url + 'system-management/college-data/all/')
    .then((e) => e.data)
    .catch(() => 'error')

//جلب الكليات التي لم يسجل لها دفع
export const getCollegeWithOutRegesterInBatch = async (type) =>
  await Axios('academic-affairs/batch/data-for-save/', {
    params: {
      batch_type: type,
    },
  })
    .then((e) => {
      return type == 1 ? e.data.colleges : e.data.specializations
    })
    .catch(() => 'error')

//الفصول الموجوده في الخطة
export const getSemesterInPlane = async (fk_batch) =>
  await Axios('academic-affairs/semester-4B/data-for-save/', {
    params: {
      fk_batch: fk_batch,
      semester_type: '1',
    },
  })
    .then((e) => e.data.names)
    .catch(() => 'error')

///جلب االاعوام الدراسية للفصل
export const getYearInPlaneBatch = async (param) =>
  await Axios('academic-affairs/semester-4B/data-for-save/', {
    params: {
      fk_batch: param,
      semester_type: '1',
    },
  })
    .then((e) => e.data.academic_years)
    .catch(() => 'error')

//الاعوام  للفصول للتخصصات
export const getYearInPlaneSpecialization = async (param) =>
  await Axios('academic-affairs/semester-4B/data-for-save/', {
    params: {
      fk_specializatoin: param,
      semester_type: '2',
    },
  })
    .then((e) => e.data.academic_years)
    .catch(() => 'error')

/////// جلب نوع المادة نظري عملي
export const getSubjectType = async () =>
  await Axios.get(base_url + 'choices/choice-data/', {
    params: {
      param: 'SubjectTypeChoice',
    },
  })
    .then((e) => e.data)
    .catch(() => 'error')

/////// جلب نوع الوحدة  في السكنات
export const getUnitType = async () =>
  await Axios.get(base_url + 'choices/choice-data/', {
    params: {
      param: 'UintTypeChoice',
    },
  })
    .then((e) => e.data)
    .catch(() => 'error')

/////// جلب حالة الوحدة  في السكنات
export const getUnitStatus = async () =>
  await Axios.get(base_url + 'choices/choice-data/', {
    params: {
      param: 'UintStatusChoice',
    },
  })
    .then((e) => e.data)
    .catch(() => 'error')

/////// جلب حالة التسكين
export const getAssigningStatus = async () =>
  await Axios.get(base_url + 'choices/choice-data/', {
    params: {
      param: 'AssigningStatusChoice',
    },
  })
    .then((e) => e.data)
    .catch(() => 'error')

/////  التخصصات للكلية
export const getSpecializatoin4B = async () =>
  await Axios.get(
    base_url + 'system-management/specialization/get_specializations/',
    {
      params: {
        college_pk: 1,
      },
    },
  )
    .then((e) => e.data)
    .catch(() => 'error')

// export const get=async()=>await Axios.get(base_url+"choices/choice-data/",{
//     params: {
//       param:'SubjectTypeChoice'
//   }
//   }).then((e)=>e.data).catch(()=>"error");

// تخصص - كلية
export const getBatchOrSpecializatoin = () => [
  {
    id: 1,
    name: 'كلية',
  },
  {
    id: 0,
    name: 'تخصص',
  },
]

//ألدولة
export const getCountry = async () =>
  await Axios.get(base_url + 'common/country-data/')
    .then((e) => e.data.results)
    .catch(() => 'error')
////السرايا
export const getAccommodationGroup = async () =>
  await Axios.get(base_url + 'accommodation/accommodation-group/all/')
    .then((e) => e.data)
    .catch(() => 'error')
////  خدمات التسكين
export const getAccommodationService = async () =>
  await Axios.get(
    base_url +
    '/student-services/accommodation-service/get-approval-accommodation-service/',
  )
    .then((e) => e.data)
    .catch(() => 'error')

//المحافظة حسب الدولة
export const getGovForCountry = async (param) =>
  await Axios.get(base_url + 'common/governorate-data/filter/', {
    params: {
      value: param,
      field: 'fk_country',
    },
  })
    .then((e) => e.data)
    .catch(() => 'error')

//المديرية حسب المحافظة
export const getDirForGov = async (param) =>
  await Axios.get(base_url + 'common/directorate-data/filter/', {
    params: {
      value: param,
      field: 'fk_governorate',
    },
  })
    .then((e) => e.data)
    .catch(() => 'error')

//الدفع
export const getBatch = async () =>
  await Axios.get(base_url + 'academic-affairs/batch/all/')
    .then((e) => e.data)
    .catch(() => 'error')

//الفصول حسب الدفع
export const getSemesteForBarch = async (param) =>
  await Axios.get(base_url + 'academic-affairs/semester-4B/filter/', {
    params: {
      value: param,
      field: 'fk_batch',
    },
  })
    .then((e) => e.data)
    .catch(() => 'error')

//المجموعات حسب الفصول
export const getGroupBySemester = async (semester) =>
  await Axios.get(
    base_url +
    `/academic-affairs/semester-group4B/${semester ? 'filter' : 'all'}/`,
    {
      params: {
        field: 'fk_semester_4b',
        value: semester,
      },
    },
  )
    .then((e) => {
      return e.data
    })
    .catch(() => 'error')



////الطلاب حسب الدفعة
export const getStudentBatch = async (batch) =>
  await Axios.get(
    base_url +
    `/student-affairs/student-batch/${batch ? 'filter' : 'all'}/`,
    {
      params: {
        field: 'fk_batch',
        value: batch,
      },
    },
  )
    .then((e) => {
      return e.data
    })
    .catch(() => 'error')

////الطلاب حسب المجموعة
export const getStudent4Group = async (semester) =>
  await Axios.get(
    base_url +
    `/student-affairs/student-semester-group-4B/${semester ? 'filter' : 'all'}/`,
    {
      params: {
        field: 'fk_semester_group_4b',
        value: semester,
      },
    },
  )
    .then((e) => {
      return e.data
    })
    .catch(() => 'error')

//جلب مجموعة فلترة الانشطة
export const getActivityFilterGroup = async () =>
  await Axios.get(base_url + '/activities/activity-filter-group-data/all/')
    .then((e) => e.data)
    .catch(() => 'error')

//جلب بيانات المستخدمين
export const getUserData = async () =>
  await Axios.get(base_url + 'users/userdata/all/')
    .then((e) => e.data)
    .catch(() => 'error')

/////// دالة عامة لجلب الاختيارات بحسب اسم الاختيارات
export const getChoices = async (choices) =>
  await Axios.get(base_url + 'choices/choice-data/', {
    params: {
      param: choices
    }
  }).then((e) => {
    
    return e.data}).catch(() => "error");

export const getAttachment = async () => await Axios.get(base_url + "accommodation/attachment/").then((e) => e.data.results).catch(() => "error");

//المجموعه
export const getGroup = async () =>
  await Axios.get(base_url + 'system-management/grouping-data/all/', {})
    .then((e) => e.data)
    .catch(() => 'error')

//الفتره
export const getPeriod = async () =>
  await Axios.get(base_url + 'choices/choice-data/', {
    params: {
      param: 'GroupPeriodChoice',
    },
  })
    .then((e) => e.data)
    .catch(() => 'error')

// الفروع
export const getBranches = async () =>
  await Axios.get(base_url + 'system-management/branch/all/')
    .then((response) => response.data)
    .catch(() => 'error')
// الكليات بحسب رقم الفرع
export const getCollegesByBranchId = async (param) =>
  await Axios.get(base_url + 'system-management/college-branch/filter/', {
    params: {
      field: 'fk_branch',
      value: param,
    },
  })
    .then((response) => response.data)
    .catch(() => 'error')

// نوع النظام
export const JopSystemChoice = async () =>
  await Axios.get(base_url + 'choices/choice-data/', {
    params: {
      param: 'JopSystemChoice',
    },
  }).then((response) => response.data)
// حالة الموظف
export const EmployeeStatusChoice = async () =>
  await Axios.get(base_url + 'choices/choice-data/', {
    params: {
      param: 'EmployeeStatusChoice',
    },
  }).then((response) => response.data)
//المنطقة حسب المديرية
export const getRegionByDir = async (param) =>
  await Axios('common/region-data/filter/', {
    params: {
      field: 'fk_directorate',
      value: param,
    },
  })
    .then((e) => e.data)
    .catch(() => 'error')

//نوع العملة
export const getCurrency = async () =>
  await Axios('common/currency-data/')
    .then((e) => e.data.results)
    .catch(() => 'error')

//مجموعات التسكين
export const getAccommadationGroup = async () =>
  await Axios('accommodation/accommodation-group/')
    .then((e) => e.data.results)
    .catch(() => 'error')

/////  جلب التخصصات بحسب رقم الفرع
export const getSpecializationByBranchId = async (param) =>
  await Axios.get(
    base_url + 'system-management/specialization-branch/data-for-save/',
    {
      params: {
        branch_pk: param,
      },
    },
  )
    .then((e) => e.data)
    .catch(() => 'error')

/////  جلب مواد اختبارات القبول
export const getDiffSubject = async () =>
  await Axios.get(`/coordination/differentiation-subject/all/`)
    .then((e) => e.data)
    .catch(() => { })

//جلب  المستخدمين حسب النوع(موظف - مدرس)
export const getUsersByTecherEmploot = async () =>
  await Axios.get(base_url + 'coordination/differentiation-monitor/get-monitors/')
    .then((e) => e.data)
    .catch(() => 'error')

//جلب المستخدمين
export const getUsers = async (param) =>
  await Axios.get(base_url + 'common/directorate-data/filter/', {
    params: {
      value: param,
      field: 'fk_governorate',
    },
  })
    .then((e) => e.data)
    .catch(() => 'error')

///عنواين المحاضرات
export const getLectureName = async (param) =>
  await Axios('academic-affairs/lecture-name/filter/', {
    params: {
      field: 'fk_subject_lecture_4b',
      value: param,
    },
  })
    .then((e) => e.data)
    .catch(() => 'error')

/////  جلب مواد اختبارات القبول بحسب الدفعة
export const getDiffSubjectByBatch = async (batch = 0) =>
  await Axios.get(`/coordination/differentiation-subject-batch/${batch ? 'filter' : 'all'}/`, {
    params: {
      field: 'fk_differentiaion_batch',
      value: batch
    }
  })
    .then((e) => e.data)
    .catch(() => { })

// 

///دفع التنيسق والقبول  
export const batchCoordination = async () =>
  await Axios.get(base_url + 'coordination/differentiation-batch/all/')
    .then((e) => e.data)
    .catch(() => 'error')

//المجموعات حسب دفع التنيسق والقبول
export const groupBatchCoordination = async (param) =>
  await Axios.get(base_url + 'coordination/differentiation-group/filter/', {
    params: {
      value: param,
      field: 'fk_differentiaion_batch',
    },
  })
    .then((e) => e.data)
    .catch(() => 'error')


///// جلب لجان الاختبارات
export const getExamHall = async () =>
  await Axios.get('/coordination/differentiation-exam-hall/all/')
    .then((e) => e.data)
    .catch(() => { })

//وع الوثائق للتحقق من الوثائق
export const getTypeDocmunetForCheckDocument = async (param,stute) =>
  await Axios.get(base_url + 'student-affairs/registration-student/data-for-save/', {
    params: {
      fk_college: param
    }
  }).then(
    (e) =>{
      return  stute==0? e.data.required_document:e.data
    }
  )

//المواد لل semester في الدفعة
export const getSubjectForBatch = async (param) =>
  await Axios.get(
    base_url + 'academic-affairs/semester-subject4B/filter/',
    {
      params: {
        field: 'fk_semester_4b',
        value: param
      },
    },
  )
    .then((e) => {
      return e.data
    })
    .catch(() => 'error')


//جلب المدرسين 
export const getTeacherForSubject = async (param) =>
  await Axios.get(
    base_url + 'employee-affairs/employee-data/get-employee/',
    {
      params: {
        subject_id: param
      },
    },
  )
    .then((e) => {
      return e.data
    })
    .catch(() => 'error')

///جلب الفتره بحسب المجموعة
  export const getPeriodForGroups = async (param) =>
  await Axios.get(
    base_url + 'academic-affairs/period-4Schedule/filter/',
    {
      params: {
        field:'fk_semester_group_4b',
        value:param,
      },
    },
  )
    .then((e) => {  
      return e.data
    })
    .catch(() => 'error')

//حالة الخدمة
export const getServiceStatus = async () =>
  await Axios("choices/choice-data/", {
    params: {
      param: "RequestStatusChoice",
    },
  }).then((e) => e.data)
    .catch(() => 'error')

// الطلاب
export const getStudent = async () =>
  await Axios('student-affairs/students/')
    .then((e) => e.data.results)
    .catch(() => 'error')

//الطلاب الاكاديمية
export const getStudentِِAcadmic = async () =>
  await Axios('student-affairs/student-academic-data/')
    .then((e) => e.data.results)
    .catch(() => 'error')

// التخصصات
export const getSpecialization = async () =>
  await Axios('system-management/specialization/all/')
    .then((e) => e.data)
    .catch(() => 'error')

//الفصول
export const getSemester = async () =>
  await Axios.get(base_url + 'choices/choice-data/', {
    params: {
      param: 'SemesterChoice',
    },
  })
    .then((e) => e.data)
    .catch(() => 'error')  

//التصنيفات
export const getActiviteisClassficion = async () =>
  await Axios.get(base_url + 'activities/activity-classification-data/all/')
    .then((e) => e.data)
    .catch(() => 'error')

//انواع الانشطة حسب التصنيفات
export const getActivitiesType4Classficion = async (param) =>
  await Axios.get(base_url + 'activities/activity-type-data/filter/', {
    params: {
      value: param,
      field: 'fk_activity_classification',
    },
  })
  .then((e) => e.data)
    .catch(() => 'error')
    

  // المواد 
export const getSubjectsall = async () =>
await Axios.get(
  base_url + 'system-management/subjects/'
)
  .then((e) => {
    return e.data.results
  })
  .catch(() => 'error')
  

  //المحاضرات للمادة 
  export const getLectureForSubject = async (fk_semester_subject_4b,lecture_type) =>
    await Axios('academic-affairs/subject-lecture4B/data/', {
      params: {
        fk_semester_subject_4b: fk_semester_subject_4b,
        lecture_type: lecture_type,
        not_paginated:true
      },
    })
      .then((e) => e.data)
      .catch(() => 'error')


  export const getDailySchedules4B = async (param) =>
    await Axios('academic-affairs/daily-schedules-4B/filter/', {
      params: {
        field:'fk_period_4_schedule',
        value: param    
       },
    })
      .then((e) =>e.data)
      .catch(() => 'error')

///دفع التنسيق والقبول 
export const getBatchDifferentiation = async () =>
  await Axios.get(base_url + 'coordination/differentiation-batch/all/')
    .then((e) => e.data)
    .catch(() => 'error')

// المجموعات حسب دفع التنسيق والقبول  
export const getGroupForBarchDifferentiation = async (param) =>
  await Axios.get(base_url + 'coordination/differentiation-group/filter/', {
    params: {
      value: param,
      field: 'fk_differentiaion_batch',
    },
  })
    .then((e) => e.data)
    .catch(() => 'error')

  

    export const ExportToExcel=function(header,data,name='ملف'){
        return new Promise((resolve,reject)=>{
          try {
            const today = new Date()
            const options ={year:'numeric',month:'2-digit',day:'2-digit'}
            const dateString = today.toLocaleDateString('en-US',options).replace(/\//g,'-')
            const style = {
              font: {
                  bold: true,
                  color: { argb: "FFFFFF" },
              },
              fill: {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "2F75B5" },
              },
            };
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Data');
            worksheet.columns=header
            const row=worksheet.getRow(1)
            row.eachCell(cell=>{
                cell.style=style
            })
            worksheet.addRows(data);  
            worksheet.eachRow(row=>{
                row.values.forEach((value,col)=>{
                    const column = worksheet.getColumn(col);
                    const cell = value?value.length:0;
                    if(cell>column.width){
                        column.width=cell+2;
                    }
                })
                row.eachCell(cell=>{
                    cell.alignment={horizontal:'center',vertical:'middle'}
                    cell.border={
                        top:{style:'thin'},
                        left:{style:'thin'},
                        right:{style:'thin'},
                        bottom:{style:'thin'}
                    }
                })
            })
            const file_name=`${dateString}-${name}.xlsx`
            workbook.xlsx.writeBuffer().then((buffer)=>{
                const data = new Blob([buffer],{ type:'application/octet-stream'});
                saveAs(data,file_name)
            })
            resolve()
          } catch (error) {
            reject(error)
          }
          
        })
      }

    export const DataForImport=function(object1,data,file_name='ملف'){
      const headers= []
      console.log(object1)
      if (object1)
        for(const [key,value] of Object.entries(object1))
          headers.push({"key":key,"header":key})
      ExportToExcel(headers,data,file_name)
    }
//جلب المواد للفصل 
export const getSubjectForSemester= async (param) =>
  await Axios.get(
    base_url + 'academic-affairs/semester-subject4B/filter/',
    {
      params: {
        field: 'fk_semester_4b',
        value:param
      },
    },
  )
    .then((e) => {
      return e.data
    })
    .catch(() => 'error')
