<template>
  <nav-drawer v-show="drawer" v-model="drawer" :title="is_update" temporary>
    <template v-slot:body>
      <v-form ref="form">
        <v-row>
          <custom-text-field
            v-model="specialization.name_ar"
            icon="abjad-arabic"
            :label="$t('name_ar')"
            :rules="[
              $required,
              $ar_letters_only,
              $max_length(100),
              $min_length(3),
            ]"
          ></custom-text-field>
          <custom-text-field
            v-model="specialization.name_en"
            icon="alpha-e"
            :label="$t('name_en')"
            :rules="[$en_letters_only, $max_length(100), $min_length(3)]"
          ></custom-text-field>
          <drop-list
            v-model="specialization.degree"
            name="degree_choice"
          ></drop-list>
          <!-- <list-degree v-model="specialization.degree" :rules="[$required]"> -->
          <!-- </list-degree> -->
          <custom-text-field
            type="number"
            v-model="specialization.capacity"
            icon="chevron-double-up"
            :label="$t('capacity')"
            :rules="[$required, $max_length(5)]"
          ></custom-text-field>
          <custom-text-field
            type="number"
            v-model="specialization.number_of_levels"
            icon="equal"
            :label="$t('number_of_levels')"
            :rules="[$required, $max_length(3)]"
          ></custom-text-field>
          <drop-list
            v-model="specialization.fk_currency"
            name="currency"
          ></drop-list>
          <!-- <list-currency
            v-model="specialization.fk_currency"
            col="12"
            :rules="[$required]"
          ></list-currency> -->
          <custom-text-field
            type="number"
            v-model="specialization.fee"
            icon="cash-multiple"
            :label="$t('fee')"
            :rules="[$required, $max_length(8)]"
          ></custom-text-field>
          <v-col cols="12" class="pa-0">
            <v-checkbox
              v-model="specialization.summer_semester"
              :label="$t('summer_semester')"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn
        :type="is_update ? 'update' : 'save'"
        :click="is_update ? updateData : saveData"
      />
      <custom-btn @click.stop="drawer = !drawer" />
    </template>
  </nav-drawer>
  <custom-data-table-2
    :headers="headers"
    :items="items"
    :pagination="pagination"
    :method="getData"
    :delItem="delData"
    :editItem="editData"
    :actionList="actionList"
    :perm="{}"
    exports
    :create="() => resetForm()"
  >
  </custom-data-table-2>
  <!-- Rquired Conditions -->
  <v-dialog
    color="indigo"
    v-model="dialoge"
    width="600"
    min-height="311"
    scrollable
  >
    <v-card>
      <v-card-title class="d-flex pt-3">
        <div>
          {{
            $t('add') + ' ' + $t(`${dialog_type}`)
          }}
        </div>
        <v-spacer></v-spacer>
        <v-slide-x-reverse-transition mode="out-in">
          <v-icon
            :key="`icon-${isEditing}`"
            :icon="isEditing ? 'mdi-check' : 'mdi-circle-edit-outline'"
            @click="isEditing = !isEditing"
            style="margin-inline-end: 16px"
          />
        </v-slide-x-reverse-transition>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="dialog">
          <v-row>
            <v-col cols="11">
              <VRow v-for="(data, index) in dialog_data" :key="index">
                <v-col>
                  <v-autocomplete
                    v-if="dialog_type == 'con'"
                    :disabled="!isEditing"
                    v-model="data.fk_condition"
                    :items="conditionsData"
                    item-value="id"
                    item-title="name_ar"
                    density="compact"
                    :label="$t(`${dialog_type}`)"
                    :rules="[$required ,duplicate]"
                    style="max-width: 390px"
                    hide-details="auto"
                  >
                  </v-autocomplete>
                  <v-autocomplete
                    v-else-if="dialog_type == 'doc'"
                    :disabled="!isEditing"
                    v-model="data.fk_documnet_type"
                    :items="documentsData"
                    item-value="id"
                    item-title="name_ar"
                    density="compact"
                    :label="$t(`${dialog_type}`)"
                    :rules="[$required ,duplicate]"
                    style="max-width: 390px"
                    hide-details="auto"
                  >
                  </v-autocomplete>
                  <v-autocomplete
                    v-else
                    :disabled="!isEditing"
                    v-model="data.fk_qualification"
                    :items="qualificationData"
                    item-value="id"
                    item-title="name_ar"
                    density="compact"
                    :label="$t(`${dialog_type}`)"
                    :rules="[$required ,duplicate]"
                    style="max-width: 390px"
                    hide-details="auto"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="2">
                  <v-checkbox
                    :disabled="!isEditing"
                    v-model="data.is_mandatory"
                    label="اجباري"
                    density="compact"
                    hide-details="auto"
                  ></v-checkbox>
                </v-col>
                <v-col cols="1" class="align-self-center">
                  <v-btn
                    :disabled="!isEditing"
                    variant="elevated"
                    size="small"
                    elevation="4"
                    density="compact"
                    icon
                    @click="removeItem(index)"
                  >
                    <v-tooltip activator="parent" location="top">{{
                      $t('delete') +
                      ' ' +
                      $t(`${dialog_type}`)
                    }}</v-tooltip>
                    <v-icon icon="mdi-minus-thick" color="error"></v-icon>
                  </v-btn>
                </v-col>
              </VRow>
            </v-col>
            <v-col cols="1">
              <v-btn
                :disabled="!isEditing"
                class="align-self-end"
                variant="elevated"
                size="small"
                elevation="4"
                density="compact"
                icon
                @click="addDataDialog()"
              >
                <v-tooltip activator="parent" location="top">{{
                  $t('add') +
                  ' ' +
                  $t(`${dialog_type}`)
                }}</v-tooltip>
                <v-icon icon="mdi-plus-thick" color="success"></v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <custom-btn
          :disabled="!isEditing"
          type="save"
          :click="() => saveDialoge(dialog_type)"
        />
        <custom-btn @click="dialoge = !dialoge" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { data } from '@/plugins/global-varibal'
export default {
  props: {
    perm: Object,
  },
  data() {
    return {
      data:{is_mandatory:true},
      items: [],
      pagination: undefined,
      specialization: {},
      is_update: false,
      drawer: null,
      conditionsData: [],
      documentsData: [],
      qualificationData: [],
      dialog_data: [{}],
      dialog_type: null,
      dialoge: false,
      isEditing: false,
      duplicate: (value) => {
        const list = this.dialog_data.filter(
          (item) =>
            item.fk_condition == value ||
            item.fk_documnet_type == value ||
            item.fk_qualification == value,
        )
        if (list?.length > 1) {
          return this.$t('duplicate_data')
        } else {
          return true
        }
      },
      headers: [
        { title: this.$t('name_ar'), key: 'name_ar' },
        { title: this.$t('name_en'), key: 'name_en', show: false },
        {
          title: this.$t('section_name'),
          key: 'section_name',
        },
        { title: this.$t('capacity'), key: 'capacity' },
        {
          title: this.$t('number_of_levels'),
          key: 'number_of_levels',
        },
        {
          title: this.$t('summer_semester'),
          key: 'summer_semester',
          type: 'check',
        },
        {
          title: this.$t('currency_name'),
          key: 'currency_name',
          show: false,
        },
        { title: this.$t('fee'), key: 'fee', show: false },
        { title: this.$t('degree'), key: 'degree_name' },
        { title: this.$t('actions'), key: 'actions', sortable: false },
      ],
    }
  },
  async created() {
    await this.getDialogData()
  },
  methods: {
    actionList(data) {
      return [
        ['conditions_add', () => this.viewDialoge(data.id)],
        ['document_add', () => this.viewDialoge(data.id, 'doc')],
        [
          'qualification_add',
          () => this.viewDialoge(data.id, 'qua'),
        ],
        ['add_classes', () => this.addclasses(data)],
      ]
    },
    async addclasses(data) {
      this.$router.push({
        name: 'class-views',
        params: {
          id: data.id,
          fk_section: this.$route.params.fk_section,
          fk_specail: data.id,
        },
      })
    },
    async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
      if (this.$route.params.fk_section) {
        await this.$axios(
          '/system-management/specialization/filter-paginate/',
          {
            params: {
              role: this.specialization.role,
              search: txt_search,
              page: page,
              page_size: perPage,
              sort_by: order_data,
              field: 'fk_section',
              value: this.$route.params.fk_section,
            },
          },
        )
          .then((response) => {
            this.items = response.data.results
            this.pagination = response.data.pagination
          })
          .catch(() => {})
      }
    },
    async getDialogData(id) {
      if (!id) {
        await this.$axios('/system-management/condition-data/')
          .then((response) => {
            this.conditionsData = response.data.results
          })
          .catch(() => {})
        await this.$axios('system-management/document-type-data/')
          .then((response) => {
            this.documentsData = response.data.results
          })
          .catch(() => {})
        await this.$axios('system-management/qualification-data/')
          .then((response) => {
            this.qualificationData = response.data.results
          })
          .catch(() => {})
      } else {
        const data = await this.$axios(
          `/system-management/${this.dialog_type == 'con' ? 'required-condition' : this.dialog_type == 'doc' ? 'required-document' : 'required-qualification'}/filter/`,
          {
            params: {
              field: 'fk_specialization',
              value: id,
            },
          },
        )
        return data.data
      }
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        if (this.$route.params.fk_section) {
          this.specialization.fk_section = this.$route.params.fk_section
          this.specialization.summer_semester =
            this.specialization?.summer_semester ?? false
          await this.$axios
            .post('/system-management/specialization/', this.specialization)
            .then(() => {
              this.$emit('successAlert', this.$t('data_added'))
              this.resetForm()
              this.getData()
            })
            .catch((error) => {
              if (error.response.data) {
                let message = Object.values(error.response.data)[0]
                this.$emit('warningAlert', message[0].replace(/[a-zA-Z]/g, ''))
              } else {
                this.$emit('alert', 'errorData')
              }
            })
        }
      }
    },
    async saveDialoge(data) {
      const { valid } = await this.$refs.dialog.validate()
      if (valid) {
        await this.$axios
          .put(
            `/system-management/${data == 'con' ? 'required-condition' : data == 'doc' ? 'required-document' : 'required-qualification'}/${this.update_id}/`,
            this.dialog_data,
          )
          .then(() => {
            this.$emit('alert', 'add')
            // this.dialoge = false
            this.isEditing = !this.isEditing
          })
          .catch((error) => {
            if (error.response.data) {
              let message = Object.values(error.response.data)[0]
              this.$emit('warningAlert', message[0].replace(/[a-zA-Z]/g, ''))
            } else {
              this.$emit('alert', 'errorData')
            }
          })
      }
    },
    editData(data, isPermission = false) {
      if (!isPermission) {
        this.drawer = true
        this.specialization = { ...data }
        this.is_update = true
      } else {
        return true
      }
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        await this.$axios
          .put(
            this.base_url +
              '/system-management/specialization/' +
              this.specialization.id +
              '/',
            this.specialization,
          )
          .then(() => {
            this.$emit(
              'successAlert',
              this.$t('data_updated'),
            )
            this.resetForm()
            this.getData()
          })
          .catch(() => {
            this.$emit('errorAlert', this.$t('title'))
          })
      }
    },
    async delData(delete_id, isPermission = false) {
      if (!isPermission) {
        if (delete_id != undefined) {
          var status
          await this.$axios
            .delete('/system-management/specialization/' + delete_id + '/')
            .then(() => {
              status = true
            })
            .catch((error) => {
              status = error
            })
          return status
        }
      } else {
        return true
      }
    },
    async viewDialoge(data_id, type = 'con') {
      this.dialog_type = type
      this.update_id = data_id
      const list = await this.getDialogData(data_id)
      this.dialog_data = list?.length || 0 ? list : [{is_mandatory:true}]
      this.dialoge = true
      this.isEditing = false
    },
    async addDataDialog() {

      const { valid } = await this.$refs.dialog.validate()
      if (valid) {
        this.dialog_data.unshift({ is_mandatory: true })
      }
    },
    removeItem(index) {
      this.dialog_data.splice(index, 1)
    },
    resetForm() {
      this.specialization = {}
      this.is_update = false
      this.drawer = this.drawer ? !this.drawer : !this.drawer
      this.$refs.form.reset()
      this.specialization.degree = 1
    },
  },
}
</script>
