<template>
  <nav-drawer v-show="drawer" v-model="drawer" temporary :title="is_update">
    <template v-slot:body>
      <v-form ref="form">
        <v-row>
          <drop-list
            name="batch"
            v-if="!fk_subject"
            v-model="college_data.fk_batch"
            @update:model-value="
              (college_data.fk_semester_4b = null),
                (college_data.fk_semester_subject_4b = null)
            "
            :rules="[$required]"
          />
          <drop-list
            name="semester_4_batch"
            v-if="!fk_subject"
            v-model="college_data.fk_semester_4b"
            @update:model-value="college_data.fk_semester_subject_4b = null"
            :param="college_data.fk_batch"
            :rules="[$required]"
          />
          <drop-list
            name="subject_for_semester"
            v-if="!fk_subject"
            v-model="college_data.fk_semester_subject_4b"
            :param="college_data.fk_semester_4b"
            :rules="[$required]"
          />
          <custom-text-field
            v-model="college_data.form_no"
            :label="$t('form_no')"
            :rules="[$required, $max_value(65535)]"
            icon="numeric"
          >
          </custom-text-field>
          <custom-file-input
            v-model="file"
            type="file"
            :label="$t('file')"
            @change="file ? (college_data.file = file) : (college_data.file = null)"
            :herf="!file && is_update ? college_data?.file || false : false"
            :rules="[is_update ? true : $required]"
          ></custom-file-input>
        </v-row>
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn
        :type="is_update ? 'update' : 'save'"
        :click="is_update ? updateData : saveData"
      />
      <custom-btn @click.stop="drawer = !drawer" />
    </template>
  </nav-drawer>

  <custom-data-table-2
    :headers="headers"
    :items="items"
    :pagination="pagination"
    :method="getData"
    :delItem="delData"
    :editItem="editData"
    :actionList="actionList"
    :perm="{}"
    exports
    :create="() => resetForm()"
  >
  </custom-data-table-2>

  <!-- Start Grouping -->
  <v-dialog color="indigo" v-model="dialog" width="600" scrollable>
    <v-card>
      <v-card-title class="py-3">
        <v-icon>mdi</v-icon>
        {{ $t("select_group") }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-0">
        <v-form ref="dialog">
          <v-table density="compact" width="100%">
            <thead>
              <tr>
                <th class="text-start">{{ $t("group") }}</th>

                <th class="text-start">{{ $t("choose") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(group, index) in grouping_data" :key="index">
                <td>
                  {{ group?.fk_grouping || group?.grop_name || "لا يوجد اسم" }}
                </td>
                <td>
                  <v-checkbox
                    v-model="group.show"
                    density="compact"
                    hide-details
                  ></v-checkbox>
                </td>
              </tr>
              <tr v-if="!grouping.length" class="text-center">
                <td colspan="2">لا يوجد مجموعة</td>
              </tr>
            </tbody>
          </v-table>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <custom-btn type="save" :click="saveGroup" />
        <custom-btn :click="() => (dialog = false)" />
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- End Grouping -->
</template>
<script>
import { getGroupBySemester } from "@/plugins/methods/global-methods";
export default {
  props: {
    perm: Object,
    fk_subject: Number,
  },
  data() {
    return {
      items: [],
      pagination: undefined,
      college_data: {},
      is_update: false,
      drawer: false,
      dialog: false,
      grouping_data: [],
      file: null,

      headers: [
        {
          title: this.$t("semester_subject"),
          key: "semester_subject_4b_name",
        },
        {
          title: this.$t("file"),
          key: "file",
          type: "document",
          show: 0,
        },
        { title: this.$t("form_no"), key: "form_no" },
        {
          title: this.$t("group"),
          key: "Grouping",
          type: "chip-list",
        },
        { title: this.$t("actions"), key: "actions", sortable: false },
      ],
    };
  },

  methods: {
    actionList(data) {
      return [["select_group", () => this.viewGroups(data)]];
    },
    async get_data(page, perPage, order_data, txt_search) {
      return this.fk_subject
        ? await this.$axios("/academic-affairs/test-form/filter-paginate/", {
            params: {
              field: "fk_semester_subject_4b",
              value: this.fk_subject,
              search: txt_search,
              page: page,
              page_size: perPage,
              sort_by: order_data,
            },
          })
        : await this.$axios("/academic-affairs/test-form/", {
            params: {
              search: txt_search,
              page: page,
              page_size: perPage,
              sort_by: order_data,
            },
          });
    },
    async getData(page = 1, perPage = 10, order_data = "", txt_search = "") {
      await this.get_data(page, perPage, order_data, txt_search)
        .then((response) => {
          this.items = response.data.results;

          this.pagination = response.data.pagination;
        })
        .catch(() => {});
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        if (this.fk_subject) this.college_data.fk_semester_subject_4b = this.fk_subject;
        const formData = new FormData();
        for (const [key, value] of Object.entries(this.college_data)) {
          if (key == "file") {
            formData.append(key, value[0]);
          } else {
            formData.append(key, value);
          }
        }
        await this.$axios
          .post("/academic-affairs/test-form/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("data_added"));
            this.resetForm();
            this.drawer = false;

            this.getData();
          })
          .catch((error) => {
            if (error.response.data) {
              let message = Object.values(error.response.data)[0];
              this.$emit("warningAlert", message[0].replace(/[a-zA-Z]/g, ""));
            } else {
              this.$emit("alert", "errorData");
            }
          });
      }
    },
    saveGroup() {
      this.college_data.Grouping = this.grouping_data
        .filter((item) => item.show == true)
        .map((item) => item.id);
      this.updateData(false);
    },
    editData(data, isPermission = false) {
      this.update_id = data.id;
      if (!isPermission) {
        this.drawer = true;
        this.college_data = { ...data };
        this.is_update = true;
        this.file = null;
      } else {
        return true;
      }
    },
    async updateData(do_valid = true) {
      const { valid } = await this.$refs.form.validate();
      if (valid || !do_valid) {
        const formData = new FormData();
        for (const [key, value] of Object.entries(this.college_data)) {
          if (key == "file") {
            if (this.file) {
              formData.append(key, value[0]);
            }
          } else {
            formData.append(key, Array.isArray(value) ? JSON.stringify(value) : value);
          }
        }
        this.college_data.file = this.file ? this.file : [];
        await this.$axios
          .patch(
            this.base_url + "/academic-affairs/test-form/" + this.college_data.id + "/",
            formData
          )
          .then(() => {
            this.$emit("alert", "update");

            this.resetForm();
            this.drawer = false;
            this.getData();
          })
          .catch(() => {
            this.$emit("alert", "errorData");
          });
      }
    },
    async delData(delete_id, isPermission = false) {
      if (!isPermission) {
        if (delete_id != undefined) {
          var status;
          await this.$axios
            .delete("/academic-affairs/test-form/" + delete_id + "/")
            .then(() => {
              status = true;
            })
            .catch((error) => {
              status = error;
            });
          return status;
        }
      } else {
        return true;
      }
    },
    async viewGroups(g_data) {
      this.college_data = { ...g_data };
      if (!this.grouping) {
        this.grouping = await getGroupBySemester(0);
      }
      if (g_data.fk_semester_4b) {
        this.grouping_data = this.grouping.map((item) => ({
          id: item.id,
          grop_name: item.fk_grouping,
          semester_name: item.fk_semester_4b,
          show: g_data.Grouping.find((item_g) => item_g.id == item.id) ? true : false,
        }));
      }
      this.dialog = !this.dialog;
    },
    resetForm() {
      this.$refs.form.reset();
      this.college_data = {};
      this.is_update = false;
      this.drawer = true;
      this.dialog = false;
      this.file = false;
    },
  },
};
</script>
