<template>
 
  <v-dialog color="indigo" v-model="dialog" width="600" scrollable persistent>
    <v-card :loading="!archives">
      <v-card-title class="d-flex justify-space-between align-center py-3">
        <div>{{ $t('add_document') }}</div>
        <a
          v-if="!archives.file ? archives?.document ?? false : false"
          :href="base_url + archives?.document || ''"
          target="_blank"
          ><v-icon
            class="mt-1"
            icon="mdi-file-eye-outline"
            color="orange"
          ></v-icon
        ></a>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-5">
        <v-form ref="dialog">
          <v-row>
            <custom-file-input
              v-model="archives.file"
              :label="$t('document')"
              col="7"
              @change="
                archives.file
                  ? (archives.document = archives.file)
                  : ((archives.document = null), (archives.file = null))
              "
              :rules="[$required]"
            ></custom-file-input>
            <!-- :herf="!file ? college_data?.file : false" -->
            <custom-text-field
              v-model="archives.document_no"
              :label="$t('document_no')"
              type="number"
              col="5"
              :rules="[$required, $max_length(10)]"
            >
            </custom-text-field>
            <list-docment-type
              v-model="archives.document_type"
              :rules="[$required]"
              col="4"
            />
            <datetime-picker
              v-model="archives.issue_date"
              class="date-time"
              :label="$t('issue_date')"
              :rules="[$max_date(archives.expiration_date)]"
              col="4"
              ref="date"
              datetime_picker="issue_date"
            ></datetime-picker>
            <datetime-picker
              v-model="archives.expiration_date"
              class="date-time"
              :label="$t('expiration_date')"
              :rules="[$min_date(archives.issue_date)]"
              col="4"
              datetime_picker="expiration_date"
            ></datetime-picker>
            <custom-text-field
              v-model="archives.issue_place"
              :label="$t('issue_place')"
              :rules="[$max_length(10)]"
              col="12"
            >
            </custom-text-field>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="px-3">
        <custom-btn
          type="done"
          :click="
            () => {
              if (
                archives.document &&
                archives.document_no &&
                archives.document_type
              ) {
                dialog = false
              } else {
                $refs.dialog.validate()
                dialog = true
              }
            }
          "
        />
        <custom-btn
          type="reset"
          @click="
            () => {
              ;(archives.document = null),
                $refs.dialog.reset(),
                (dialog = false)
            }
          "
        />
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-card class="pa-5">
    <v-card-title>
      <h3>{{ $t('requerdocoument') }}</h3>
    </v-card-title>
    <v-card-text>
      <v-table class="ma-3" height="50vh" fixed-header density="compact">
        <thead>
          <tr>
            <th class="text-start">{{ $t('docoument') }}</th>
            <th class="text-start">{{ $t('upload_docoument') }}</th>
            <th>{{ $t('status_document') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(requireDocument, index) in requireDocuments" :key="index">
            <td>
              {{
                requireDocument?.required_document_name ||
                requireDocument.required_document_4c_name
              }}
            </td>
            <td>
              <v-icon
                :color="
                  requireDocument.fk_archives?.document ? 'teal' : 'indigo'
                "
                @click="openDialog(index)"
                v-ripple
                >{{
                  requireDocument.fk_archives?.document
                    ? 'mdi-file-document-edit-outline'
                    : 'mdi-file-document-plus-outline'
                }}</v-icon
              >
            </td>
            <td>
              <v-autocomplete
                item-title="name"
                item-value="id"
                :items="items"
                v-model="requireDocument.status"
                style="width: 250px"
                class="py-2"
                :label="$t('status_document')"
                density="compact"
                :prepend-inner-icon="
                  requireDocument.status == 1
                    ? 'mdi-file-document-check-outline'
                    : requireDocument.status == 2
                      ? 'mdi-file-document-remove-outline'
                      : requireDocument.status == 3
                        ? 'mdi-file-search-outline'
                        : requireDocument.status == 4
                          ? 'mdi-file-document-refresh-outline'
                          : ''
                "
                @update:model-value="changed = true"
                hide-details="auto"
              ></v-autocomplete>
              <!-- <list-document-status v-model="requireDocument.status" /> -->
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
    <v-card-actions>
      <custom-btn type="save" :click="saveData" :disabled="!changed" />
      <!-- <custom-btn type="cancel" :click="() => (dialog = false)" /> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import { getDataStudent, getChoices } from '@/plugins/methods/global-methods'
export default {
  props: {
    perm: Object,
    id: {
      type: Number,
    },
  },
  data() {
    return {
      student_data: [],
      dialog: false,
      requireDocuments: [],
      archives: {},
      items: [],
      changed: false,
    }
  },
  async created() {
 
    await this.applyDocuments()
    this.items = await getChoices('CheckDocumentStatusChoice')
    // this.getDataStudent()
  },
  methods: {
    async getData() {
      await this.$axios('student-affairs/check-document/filter/', {
        params: {
          field: 'fk_student',
          value: this.id,
        },
      })
        .then((response) => {
          this.requireDocuments = response.data
        })
        .catch(() => {})
    },
    async applyDocuments() {
      await this.$axios(
        '/student-affairs/check-document/save-check-document/',
        {
          params: {
            fk_student: this.id,
          },
        },
      )
        .then(() => {
          this.getData()
        })
        .catch((error) => {
          if (error.response.data) {
            let message = Object.values(error.response.data)
            // this.$emit('warningAlert', message[0].replace(/[a-zA-Z]/g, ''))
          } else {
            this.$emit('alert', 'errorData')
          }
        })
    },
    async saveData() {
      // let formData = new FormData()

      // this.dropList.data_entry = this.user;
      // console.log(this.requireDocuments)
      // this.requireDocuments.forEach((item , index) => {
      //   item.fk_archives.document = item.fk_archives?.document ?? null
      //   for (const [key, value] of Object.entries(item.fk_archives)) {
      //     if (key == 'document') {
      //       formData.append(`item_doc`, value ? value[0] : index)
      //     }
      //   }
      //   item.fk_archives = item.fk_archives?.document ?? null

      //   formData.append(`item_data`, JSON.stringify(item))
      // })
      // formData.append('objects',JSON.stringify(this.requireDocuments))
      // console.log(formData, '----------------------')
      // this.requireDocuments.forEach((item, index) => {
      //   Object.entries(item).forEach(([key, value]) => {
      //     if (typeof value === 'object' && value !== null) {
      //       Object.entries(value).forEach(([subKey, subValue]) => {
      //         if (subKey === 'document') {
      //           // console.log('1');
      //           formData.append(
      //             `items[${index}][${key}][${subKey}]`,
      //             subValue[0],
      //           )
      //         } else {
      //           // console.log('2');
      //           formData.append(`items[${index}][${key}][${subKey}]`, subValue)
      //         }
      //       })
      //     } else {
      //       // console.log('3');
      //       formData.append(`items[${index}][${key}]`, value)
      //     }
      //   })
      // })

      // let dataToSend = this.requireDocuments.map(item => {
      //   let formData = new FormData();
      //     formData.append('document',item.fk_archives?.document || null)
      //     formData.append('document_no',item.fk_archives?.document_no || null)
      //     return {required_document_name : item.required_document_name , fk_archives:formData}

      // })

      // this.requireDocuments.forEach((item, index)=>{
      //   formData.append(`items[${index}][]`)
      // })
      // console.log(dataToSend,'12212121');
      // console.log(formData.entries);
      delete this.requireDocuments.forEach(
        (item) => delete item.fk_archives?.file,
      )
      delete this.requireDocuments.forEach((item) =>
        item.fk_archives?.document == null ? delete item.fk_archives : false,
      )
      console.log(this.requireDocuments)
      await this.$axios
        .patch(
          '/student-affairs/check-document/bulk_update/',
          this.requireDocuments,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then(() => {
          this.$emit('alert', 'add')
          this.getData()
          this.changed = false
        })
        .catch((error) => {
          console.log(error)
          if (error?.response?.data) {
            let message = Object.values(error.response.data)
            this.$emit(
              'warningAlert',
              message[0].replace(/[a-zA-Z{_}'":=,()\[\]]/g, ''),
            )
          } else {
            this.$emit('alert', 'errorData')
          }
        })
    },
    async openDialog(index) {
      this.archives = this.requireDocuments[index].fk_archives
      this.dialog = !this.dialog
      this.changed = true
    },
  },
}
</script>

<style></style>
