<template>
  <nav-drawer
    v-show="drawer"
    :title="update_id ? 'update' : 'create'"
    v-model="drawer"
    temporary
  >
    <template v-slot:body>
      <v-form ref="form">
        <drop-list
          name="building"
          v-model="dropList.fk_building"
          :rules="[$required]"
          :param="'True'"
        />
        <custom-text-field
          v-model="dropList.accommodatin_no"
          icon="numeric"
          :label="$t('accommodatin_no')"
          :rules="[$validateNumber, $required, $max_value(999)]"
          type="number"
        />
        <custom-text-field
          v-model="dropList.number_of_beds"
          icon="bed"
          :label="$t('number_of_beds')"
          :rules="[$validateNumber, $required, $max_value(999)]"
          type="number"
        />
        <drop-list name="unit_type" v-model="dropList.uint_type" :rules="[$required]" />
        <custom-text-field
          v-model="dropList.uint_capacity"
          icon="human-capacity-increase "
          :label="$t('unit_capacity')"
          :rules="[$validateNumber, $required, $max_value(999)]"
          type="number"
        />
        <drop-list
          name="unit_status"
          v-model="dropList.uint_status"
          :rules="[$required]"
        />
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn
        :type="update_id ? 'update' : 'save'"
        :click="update_id ? updateData : saveData"
      />
      <custom-btn @click.stop="resetForm(), (drawer = !drawer)" />
    </template>
  </nav-drawer>
  <custom-data-table-2
    :headers="headers"
    :items="items"
    :pagination="pagination"
    :method="getData"
    :delItem="delData"
    :editItem="editData"
    :actionList="actionList"
    :perm="{}"
    exports
    :create="
      () => {
        resetForm();
        drawer = true;
      }
    "
  />
</template>

<script>
import DropList from "@/components/DropList/DropList.vue";
export default {
  components: { DropList },
  props: {
    perm: Object,
  },

  data() {
    return {
      items: [],
      // list_activity_type: [],
      pagination: undefined,
      dropList: {},
      updated_id: undefined,
      drawer: null,
      headers: [
        { title: this.$t("buildings"), key: "fk_building_id" },
        { title: this.$t("accommodatin_no"), key: "accommodatin_no" },
        { title: this.$t("number_of_beds"), key: "number_of_beds" },
        { title: this.$t("unit_type"), key: "uint_type" },
        { title: this.$t("unit_capacity"), key: "uint_capacity" },
        { title: this.$t("unit_status"), key: "uint_status" },
        { title: this.$t("actions"), key: "actions" },
      ],
    };
  },
  methods: {
    openDrawer() {
      this.drawer = !this.drawer;
      this.resetForm();
    },
    resetForm() {
      this.$refs.form.reset();
      this.dropList = {};
      this.update_id = undefined;
      this.is_update = false;
    },
    actionList(data) {
      return [
        [
          "beds",
          () =>
            this.$router.push({
              name: "beds",
              params: { id: data?.id },
            }),
        ],
      ];
    },
    async getData(page = 1, perPage = 10, order_data = "", txt_search = "") {
      await this.$axios("accommodation/student-accommodation/", {
        params: {
          search: txt_search,
          page: page,
          page_size: perPage,
          sort_by: order_data,
        },
      })
        .then((response) => {
          let newItem = [];
          response.data.results.forEach((element) => {
            newItem.push({
              ...element,
            });
          });

          this.items = newItem;
          this.pagination = response.data.pagination;
        })
        .catch(() => {});
    },
    editData(data) {
      this.resetForm();
      this.update_id = data.id;
      this.dropList = {
        ...data,
      };
      this.dropList.uint_type = data.uint_type_id;
      this.dropList.uint_status = data.uint_status_id;
      this.drawer = true;
    },
    async delData(delete_id) {
      if (delete_id != undefined) {
        var status;
        await this.$axios
          .delete("accommodation/student-accommodation/" + delete_id + "/")
          .then(() => {
            status = true;
            this.getData();
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        await this.$axios
          .post("accommodation/student-accommodation/", this.dropList)
          .then(() => {
            this.$emit("alert", "add");
            this.resetForm();
            this.getData();
          })
          .catch(() => {
            this.$emit("alert", "errorData");
          });
      }
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        await this.$axios
          .put(`accommodation/student-accommodation/${this.dropList.id}/`, this.dropList)
          .then(() => {
            this.$emit("alert", "update");
            this.resetForm();
            this.getData();
            this.drawer = false;
          })
          .catch(() => {
            this.$emit("alert", "errorData");
          });
      }
    },
  },
};
</script>
