<template>
  <custom-auto-complete
    :items="items"
    item-title="name_ar"
    item-value="id"
    icon="multicast"
    :label="$t('specialization_name')"
    :rules="rules"
  />
</template>
<script>
export default {
  name: "ListSpecialization",
  props: {
     filter:{
      type:Array,       
    },
    rules: {
      type: Object,
    },  
  },
  data() {
    return {
      items: [],
    };
  },
  async created() {
      if(!this.filter)
    {
    
        await this.$axios("system-management/specialization-branch/data-for-save/")
          .then((response) => {
            this.items = response.data;
          })
          .catch(() => {
            
          });
  }
  },
   watch:{
      filter(){
        this.items = this.filter
      }
      }

};
</script>
