<template>
  <custom-auto-complete
    :items="items"
    :placeholder="$t('numbering_system_placeholder')"
    item-title="name_ar"
    item-value="id"
    :label="$t('numbering_system')"
    :col="col"
    :rules="rules"
  />
</template>
<script>
export default {
  props: {
    col: {
      type: Number,
      default() {
        return 12
      },
    },
    rules: {
      type: Object,
    },
  },
  name: 'ListSection',
  data() {
    return {
      items: [],
    }
  },
  async created() {
    await this.$axios
      .get('/system-management/academic-system-data/all/')
      .then((e) => (this.items = e.data))
  },
}
</script>
