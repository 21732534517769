<template>
  <nav-drawer
    v-show="drawer"
    :title="update_id ? 'update' : 'create'"
    v-model="drawer"
    temporary
  >
    <template v-slot:body>
      <v-form ref="form">
        <v-row class="px-3 pt-5">
          <drop-list
            name="SemesterPeriodChoice"
            v-model="dropList.type_of_period"
            :excludeData="excludeData"
            :extraData="extraData"
            :rules="[$required]"
          />
          <datetime-picker
            v-model="dropList.start_date"
            :calendar="'gregorg'"
            placeholder="YYYY-MM-DD"
            :label="$t('start_date')"
            datetime_picker="start-data"
            :rules="[$required]"
          />
          <datetime-picker
            v-model="dropList.end_date"
            :calendar="'gregorg'"
            placeholder="YYYY-MM-DD"
            :label="$t('end_date')"
            datetime_picker="end-date"
            :rules="[$required]"
          />
        </v-row>
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn
        :type="update_id ? 'update' : 'save'"
        :click="update_id ? updateData : saveData"
      />
      <custom-btn @click="resetForm, (drawer = !drawer)" />
    </template>
  </nav-drawer>

  <custom-data-table-2
    :headers="headers"
    :items="items"
    :pagination="pagination"
    :method="getData"
    :delItem="delData"
    :editItem="editData"
    :perm="{}"
    :create="() => openDialog()"
    exports
  />
</template>
<script>
export default {
  props: {
    perm: Object,
    fk_semester: Number,
  },
  data() {
    return {
      dropList: {},
      items: [],
      update_id: undefined,
      pagination: undefined,
      drawer: false,
      extraData: [],
      excludeData: [],
    }
  },

  methods: {
    openDialog() {
      this.resetForm()
      this.excludeData = this.items.map((e) => e.type_of_period)
      this.drawer = true
    },
    async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
      await this.$axios('/academic-affairs/semester-period/filter-paginate/', {
        params: {
          search: txt_search,
          page: page,
          page_size: perPage,
          sort_by: order_data,
          value: this.fk_semester,
          field: 'fk_semester_4b',
        },
      })
        .then((response) => {
          this.items = response.data.results
          this.pagination = response.data.pagination
        })
        .catch(() => {})
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        this.dropList.fk_semester_4b = this.fk_semester

        await this.$axios
          .post('/academic-affairs/semester-period/', this.dropList)
          .then(() => {
            this.$emit('alert', 'add')
            this.resetForm()
            this.getData()
          })
          .catch((error) => {
            this.$emit('alert', 'errorData')
          })
      }
    },
    editData(data) {
      this.resetForm()
      this.update_id = data.id
      this.dropList = { ...data }
      this.excludeData = this.items.map((e) => e.type_of_period)
      this.extraData.push({
        id: this.dropList.type_of_period,
        name: this.dropList.type_of_period_name,
      })
      this.drawer = true
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        await this.$axios
          .put(
            this.base_url +
              '/academic-affairs/semester-period/' +
              this.dropList.id +
              '/',
            this.dropList
          )
          .then(() => {
            this.$emit('alert', 'update')
            this.resetForm()
            this.getData()
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })
      }
    },
    async delData(delete_id) {
      if (delete_id != undefined) {
        var status
        await this.$axios
          .delete('/academic-affairs/semester-period/' + delete_id + '/')
          .then(() => {
            status = true
          })
          .catch((error) => {
            status = error
          })
        return status
      }
    },
    resetForm() {
      this.dropList = {}
      this.$refs.form.reset()
      this.dropList = {}
      this.update_id = undefined
      this.excludeData = []
      this.extraData = []
      this.drawer = false
    },
  },
  computed: {
    headers() {
      return [
        { title: this.$t('type_of_period'), key: 'type_of_period' },
        { title: this.$t('type_of_period_name'), key: 'type_of_period_name' },
        { title: this.$t('start_date'), key: 'start_date' },
        { title: this.$t('end_date'), key: 'end_date' },
        { title: this.$t('actions'), key: 'actions', sortable: false },
      ]
    },
  },
}
</script>

