<template>
  <v-col>
   
    <v-row>
      <custom-text-field
         v-model="value.user.username"
        :label="$t('username')"
        icon="abjad-arabic"
        col="4"
        :rules="[$required,$max_length(40)]"
        :error_messages="value.uniqe_name?$t('already_exists'):''"
        @update:modelValue="value.uniqe_name=false"

      />
      <custom-text-field
         v-model="value.user.email"

        :label="$t('email')"
        icon="email"
        type="email"
        col="4"
        :rules="[$required,$email]"
        :error_messages="
        value.uniqe_email=='uniqe'?
        $t('already_exists'):
        value.uniqe_email=='error'?
        $t('email_error'):
        ''"
        @update:modelValue="value.uniqe_email=''"

      />
    </v-row>
      <custom-text-field
        v-model="value.user.password"
        :label="$t('password')"
        icon="lock"
        type="password"
        col="4"
        :rules="[$required,$max_length(40)]"
      />     
 
  </v-col>
</template>
<script>
import {getTypeDocmunetForCheckDocument} from '@/plugins/methods/global-methods'
export default {
  async created() {

    let data = await getTypeDocmunetForCheckDocument('1',1)
    this.value.user.username = data.default_username
    this.value.user.password = data.default_password     
    
  },
  props: {
    dropList: Object,
  },
  computed: {
    value: {
      get() {
        return this.dropList
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
}
</script>
