<template>
  <v-row class="mt-2">
    <v-col
      cols="12"
      md="12"
    >
      <v-card class="pt-3 px-1" id="card" style="height: 300px">
        <!-- :style="{height:$vuetify.display.xs?'600px':'300px'}" -->
        <!-- :option="$vuetify.display.xs?option_3:option_2" -->
        <div id="studentStatistics"></div>
      </v-card>
    </v-col>
  </v-row>
</template>
  
<script>
import * as echarts from 'echarts'
export default {
    props:{
        statistics:{
            type:Object,
            required:true
        }
    },
    created(){
        setTimeout(() => {
            this.drawBarChart()
        }, 1000 );
    },

    methods:{
        printChart(){
                const chartElement=document.getElementById('studentStatistics')
                const chart =echarts.init(chartElement)
                chart.setOption(this.option_2)
                const element = document.getElementById('card')
                chart.resize({
                    width:element.offsetWidth-12,
                    height:element.offsetHeight
                })
            
        },
        drawBarChart(){
            this.option_2={
                colors:['#2269d5','#548adb'],
                title: {
                    text: this.$t('students-statistics'),
                    left:'center',
                    textStyle:{
                        fontFamily:'Almarai',
                        fontSize:16
                    },
                    subtextStyle:{
                        fontFamily:'Almarai',
                        fontSize:10

                    }
                },
                dataset:[
                    {
                        dimensions:['name',this.$t('males'),this.$t('fmales'),''],
                        source:this.statistics?.levels_statistics?.map(el=>{
                            return [el?.level,el?.male,el?.female]
                        })??[]
                    },
                    {
                        transform:{
                            type:'sort',
                            config:{dimension:'constant',order:'desc'}
                        }
                    }
                ],
                toolbox: {
                    feature: {
                        saveAsImage: {
                            title:this.$t('save-as-png')
                        },
                    }
                },
                legend:{
                    orient:'vertical',
                    show:true,
                    left:'left',
                    textStyle:{
                        fontFamily:'Almarai'
                    }
                },
                xAxis: {
                    type:'category',
                    axisLabel:{
                        interval:0,
                        rotate:30,
                        textStyle:{
                            fontFamily:'Almarai',
                            fontSize:'10px'
                        }
                    },
                    
                },
                grid:{
                    top:'40%'
                },
                yAxis: {
                },
                series: [
                    { 
                        type:'bar',
                        itemStyle:{
                            color:'#1098F7'
                        }
                    },
                    { 
                        
                        type:'bar',
                        itemStyle:{
                            color:'#80ced7'
                        }
                    },
                    {
                        type: 'pie',
                        radius: '20%',
                        name:this.$t('students'),
                        center:['80%','20%'],
                        data:[
                            {value:this.statistics?.male,name:this.$t('males'),itemStyle:{color:'#1098F7'}},
                            {value:this.statistics?.female,name:this.$t('fmales'),itemStyle:{color:'#80ced7'}}
                        ],
                        label:{
                            show:true,
                            formatter:'{b}:({c})',
                            textStyle:{
                                fontFamily:'Almarai',
                                fontSize:'10px'
                            }
                        }
                    },
                    
                ],
               
        tooltip: {
          trigger:'axis',
          axisPointer:{
            type:'shadow'
          }
        }
            }
            this.printChart()
        }
    },
    // beforeUnmount(){
    //     window.removeEventListener('resize',this.printChart)
    // },
    data(){
        return {
            // statistics:[],
            option_2:{

            },
            option_3:{

            },
            size:null
        }
    },
    watch:{
        statistics(){
            this.drawBarChart()
        }
    }
};
</script>