<template>
<!-- <pre dir="ltr">{{item}}</pre> -->
  <v-container>
    <v-row class="justify-center">
      <v-col cols="12" md="8" class="pa-0 ma-0 mx-0 px-0">
        <v-card>
          <v-slide-x-reverse-transition mode="out-in">
            <v-icon
              v-bind="props"
              :key="`icon-${isEditing}`"
              :icon="isEditing ? 'mdi-check' : 'mdi-circle-edit-outline'"
              @click="edit"
              class="ma-1"
            />
          </v-slide-x-reverse-transition>
          <v-card
            elevation="0"
            class="rounded-lg pa-4 pa-0 ma-0 mx-0 px-0"
            :loading="loading"
          >
            <v-col class="pa-0 ma-0">
              <v-card :disabled="!isEditing" class="ma-0 pa-0" elevation="0">
                <v-row>
                  <v-col cols="12" md="3" class="justify-center">
                    <v-img
                      class="ms-2"
                      :src="require('@/assets/images/1.png')"
                      max-width="140"
                      rounded
                    @click="selectImage"
                    ref="imageInput"
                    />
                  </v-col>
                    <!-- <v-col col="4">
                      <image-input
                        v-model="value.image_user"
                        :reset="image_reset_logo"
                        :image="value.user.image_user"
                        :text="$t('summer_logo')"
                        height="100"
                      />
                    </v-col> -->
                  <v-col cols="12" md="9">
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <h1>{{ item.fk_student.full_name_ar }}</h1>
                        <h3 class="mt-2">{{ item.name_en }}</h3>
                      </v-col>
                      <v-col cols="12" class="d-flex">
                        <v-col v-if="!isEditing" cols="4">
                          <h4>
                            الحالة الاجتماعية:
                            <span class="coustom-color">{{
                              item.fk_student.marital_status_name
                            }}</span>
                          </h4>
                        </v-col>
                        <drop-list
                          v-if="isEditing"
                          name="marital_status"
                          v-model="item.fk_student.marital_status"
                          col="4"
                          :rules="[$required]"
                          :clearable="false"
                        />
                        <v-col v-if="!isEditing" cols="4">
                          <h4>
                            فصيلة الدم:
                            <span class="coustom-color">{{
                              item.fk_student.fk_user.blood_type_name
                            }}</span>
                          </h4>
                        </v-col>
                        <drop-list
                          v-if="isEditing"
                          v-model="item.fk_student.fk_user.blood_type"
                          name="blood_type"
                          col="4"
                          :rules="[$required]"
                          :clearable="false"
                        />
                        <v-col v-if="!isEditing" cols="4">
                          <h4>
                            نسبة التفرغ:
                            <span class="coustom-color"
                              >{{ item.fk_student.full_time_ratio }}%</span
                            >
                          </h4>
                        </v-col>
                        <drop-list
                          v-if="isEditing"
                          v-model="item.fk_student.full_time_ratio"
                          name="full_time_ratio"
                          col="4"
                          :rules="[$required]"
                          :clearable="false"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-col>
                  <p class="mb-2">البيانات الاساسية</p>
                  <v-row>
                    <custom-text-field
                      v-model="item.fk_student.fk_user.first_name"
                      :label="$t('fname')"
                      icon="abjad-arabic"
                      col="4"
                      :rules="[$required, $ar_letters_only, $max_length(100)]"
                    />
                    <custom-text-field
                      v-model="item.fk_student.fk_user.last_name"
                      :label="$t('lname')"
                      icon="abjad-arabic"
                      col="4"
                      :rules="[$required, $ar_letters_only, $max_length(100)]"
                    />
                    <custom-text-field
                      v-model="item.fk_student.name_en"
                      :label="$t('student_name_en')"
                      icon="alpha-e"
                      col="4"
                      :rules="[$en_letters_only, $max_length(100)]"
                    />
                  </v-row>

                  <v-row>
                    <custom-text-field
                      v-model="item.fk_student.brother_count"
                      :label="$t('number_brothers')"
                      :rules="[$required]"
                      type="number"
                      icon="account-multiple"
                      col="3"
                    />
                    <custom-text-field
                      v-model="item.fk_student.childern_count"
                      :label="$t('number_children')"
                      :rules="[$required]"
                      type="number"
                      icon="human-child"
                      col="3"
                    />
                    <custom-text-field
                      v-model="item.fk_student.wife_count"
                      :label="$t('number_wives')"
                      :rules="[$required]"
                      type="number"
                      icon="numeric"
                      col="3"
                    />
                    <v-checkbox
                      v-model="item.fk_student.is_owned_housing"
                      :label="$t('house_owned')"
                      hide-details
                    />
                  </v-row>

                  <v-row>
                    <custom-text-field
                      v-model="item.fk_student.fk_user.phone_no"
                      :label="$t('phonenumber')"
                      icon="phone-outline"
                      type="number"
                      col="3"
                      :rules="[$max_length(15)]"
                    />
                    <custom-text-field
                      v-model="item.fk_student.fk_user.mobile_no"
                      :label="$t('mobilenumber')"
                      icon="cellphone"
                      type="number"
                      col="3"
                      :rules="[$max_length(15)]"
                    />
                    <drop-list
                      v-model="item.fk_student.fk_user.gender"
                      name="gendar"
                      col="3"
                      :rules="[$required]"
                    />
                  </v-row>

                  <v-row>
                    <custom-text-field
                      v-model="item.fk_student.job"
                      :label="$t('work')"
                      icon="account-outline"
                      col="4"
                      :rules="[$max_length(50)]"
                    />
                    <custom-text-field
                      v-model="item.fk_student.job_entity"
                      :label="$t('employer')"
                      icon="briefcase-outline"
                      col="4"
                      :rules="[$max_length(50)]"
                    />
                  </v-row>

                  <v-row>
                    <drop-list
                      v-model="item.fk_student.dictating"
                      name="dictating"
                      col="3"
                    />
                    <drop-list
                      v-model="item.fk_student.quran"
                      name="quran"
                      col="3"
                    />
                    <drop-list
                      v-model="item.fk_student.addicted"
                      name="addicted"
                      col="6"
                      multiple
                    />
                  </v-row>
                </v-col>
                <v-divider class="mt-4" />
                <v-col>
                  <p class="mb-2">بيانات الاقامة</p>
                  <v-row>
                    <drop-list
                      name="country"
                      v-model="item.fk_student.fk_user.fk_address_fk_country"
                      @update:modelValue="
                        ;(item.fk_student.fk_user.fk_address_fk_governorate =
                          undefined),
                          (item.fk_student.fk_user.fk_address_fk_directorate =
                            undefined),
                          (item.fk_student.fk_user.fk_address_fk_region =
                            undefined)
                      "
                      col="4"
                      :rules="[$required]"
                    />
                    <drop-list
                      name="gov_for_country"
                      v-model="
                        item.fk_student.fk_user.fk_address_fk_governorate
                      "
                      :param="item.fk_student.fk_user.fk_address_fk_country"
                      @update:modelValue="
                        ;(item.fk_student.fk_user.fk_address_fk_directorate =
                          undefined),
                          (item.fk_student.fk_user.fk_address_fk_region =
                            undefined)
                      "
                      col="4"
                      :rules="[$required]"
                    />
                    <drop-list
                      name="dir_for_gov"
                      v-model="
                        item.fk_student.fk_user.fk_address_fk_directorate
                      "
                      :param="item.fk_student.fk_user.fk_address_fk_governorate"
                      col="4"
                      :rules="[$required]"
                      @update:modelValue="
                        item.fk_student.fk_user.fk_address_fk_region = undefined
                      "
                    />
                  </v-row>
                  <v-row>
                    <drop-list
                      name="region_by_dir"
                      v-model="item.fk_student.fk_user.fk_address_fk_region"
                      :param="item.fk_student.fk_user.fk_address_fk_directorate"
                      col="4"
                      :rules="[$required]"
                    />
                    <custom-text-field
                      v-model="item.fk_student.fk_user.fk_address_street"
                      :label="$t('street')"
                      icon="traffic-light-outline"
                      col="4"
                      :rules="[$max_length(100)]"
                    />
                  </v-row>
                </v-col>
                <v-divider class="mt-4" />
              </v-card>

              <v-col>
                <v-card :disabled="!isEditing" class="ma-0 pa-0" elevation="0">
                  <p class="mb-2">البيانات الشخصية</p></v-card
                >
                <v-row>
                  <custom-text-field
                    v-model="item.fk_student.full_name_ar"
                    :label="$t('name')"
                    icon="abjad-arabic"
                    col="3"
                    :rules="[$required, $ar_letters_only, $max_length(100)]"
                    :disabled="!isEditing"
                  />

                  <custom-file-input
                    v-if="isEditing"
                    v-model="item.fk_student.fk_user.fk_ssn_archive_document_1"
                    col="3"
                    :rules="[$required]"
                  />
                  <v-col
                    class="align-self-center text-center ps-10"
                    cols="2"
                    v-else
                  >
                    <a
                      v-if="!isEditing"
                      :href="item.fk_student.fk_user.fk_ssn_archive_document"
                      target="_blank"
                      class="mx-2"
                      >{{ $t('document')
                      }}<v-icon
                        icon="mdi-file-eye-outline"
                        size="30"
                        color="orange"
                        class="mx-2"
                      ></v-icon
                    ></a>
                  </v-col>
                  <datetime-picker
                    v-model="item.fk_student.fk_user.date_of_birth"
                    :calendar="'gregorg'"
                    placeholder="YYYY-MM-DD"
                    :label="$t('birth_date')"
                    datetime_picker="date_of_birth"
                    :rules="[$required]"
                    col="3"
                    :disabled="!isEditing"
                  />

                  <drop-list
                    v-model="
                      item.fk_student.fk_user.fk_ssn_archive_document_type
                    "
                    name="documnet_type"
                    col="4"
                    :rules="[$required]"
                    :disabled="!isEditing"
                  />
                </v-row>
                <v-row>
                  <custom-text-field
                    v-model="item.fk_student.fk_user.fk_ssn_archive_document_no"
                    :label="$t('id_number')"
                    icon="numeric"
                    col="3"
                    :rules="[$required, $max_length(50)]"
                    :disabled="!isEditing"
                  />

                  <custom-text-field
                    v-model="item.fk_student.fk_user.fk_ssn_archive_issu_place"
                    :label="$t('issue_place')"
                    icon="compass-outline"
                    col="3"
                    :rules="[$required, $max_length(100)]"
                    :disabled="!isEditing"
                  />

                  <datetime-picker
                    v-model="item.fk_student.fk_user.fk_ssn_archive_issue_date"
                    :calendar="'gregorg'"
                    placeholder="YYYY-MM-DD"
                    :label="$t('issue_date')"
                    datetime_picker="user_fk_ssn_archive_date_of_issue_date"
                    :rules="[$required]"
                    col="3"
                    :disabled="!isEditing"
                  />

                  <datetime-picker
                    v-model="
                      item.fk_student.fk_user.fk_ssn_archive_expiration_date
                    "
                    :calendar="'gregorg'"
                    placeholder="YYYY-MM-DD"
                    :label="$t('expiration_date')"
                    datetime_picker="user_fk_ssn_archive_date_of_expiration_date"
                    col="3"
                    :disabled="!isEditing"
                  />
                </v-row>
              </v-col>
              <v-divider class="mt-2" />
              <v-card :disabled="!isEditing" class="ma-0 pa-0" elevation="0">
                <v-col>
                  <p class="mb-2">مكان الميلاد</p>
                  <v-row>
                    <drop-list
                      name="country"
                      v-model="
                        item.fk_student.fk_user.fk_place_of_brith_fk_country
                      "
                      @update:modelValue="
                        ;(item.fk_student.fk_user.fk_place_of_brith_fk_governorate =
                          undefined),
                          (item.fk_student.fk_user.fk_place_of_brith_fk_directorate =
                            undefined),
                          (item.fk_student.fk_user.fk_place_of_brith_fk_region =
                            undefined)
                      "
                      col="4"
                      :rules="[$required]"
                    />
                    <drop-list
                      name="gov_for_country"
                      v-model="
                        item.fk_student.fk_user.fk_place_of_brith_fk_governorate
                      "
                      :param="
                        item.fk_student.fk_user.fk_place_of_brith_fk_country
                      "
                      @update:modelValue="
                        ;(item.fk_student.fk_user.fk_place_of_brith_fk_directorate =
                          undefined),
                          (item.fk_student.fk_user.fk_place_of_brith_fk_region =
                            undefined)
                      "
                      col="4"
                      :rules="[$required]"
                    />
                    <drop-list
                      name="dir_for_gov"
                      v-model="
                        item.fk_student.fk_user.fk_place_of_brith_fk_directorate
                      "
                      :param="
                        item.fk_student.fk_user.fk_place_of_brith_fk_governorate
                      "
                      col="4"
                      :rules="[$required]"
                      @update:modelValue="
                        item.fk_student.fk_user.fk_place_of_brith_fk_region =
                          undefined
                      "
                    />
                  </v-row>
                  <v-row>
                    <drop-list
                      name="region_by_dir"
                      v-model="
                        item.fk_student.fk_user.fk_place_of_brith_fk_region
                      "
                      :param="
                        item.fk_student.fk_user.fk_place_of_brith_fk_directorate
                      "
                      col="4"
                      :rules="[$required]"
                    />
                    <custom-text-field
                      v-model="item.fk_student.fk_user.fk_place_of_brith_street"
                      :label="$t('street')"
                      icon="traffic-light-outline"
                      col="4"
                      :rules="[$max_length(100)]"
                    />
                  </v-row>
                </v-col>
                <v-divider class="mt-2" />
                <v-col>
                  <p class="mb-2">بيانات المستخدم</p>
                  <v-row>
                    <custom-text-field
                      v-model="item.fk_student.fk_user.username"
                      :label="$t('username')"
                      icon="abjad-arabic"
                      col="4"
                      :rules="[$required, $max_length(40)]"
                    />

                    <custom-text-field
                      v-model="item.fk_student.fk_user.email"
                      :label="$t('email')"
                      icon="email"
                      type="email"
                      col="4"
                      :rules="[$required, $email]"
                    />

                    <v-col cols="3">
                      <p>
                        تغيير كلمة المرور
                        <span><custom-btn type="reset" /></span>
                      </p>
                    </v-col>
                    <v-divider></v-divider>
                  </v-row>
                </v-col>
              </v-card>
            </v-col>
          </v-card>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-row>
          <v-col cols="6">
            <custom-card-icon
              :head_title="$t('academic_data')"
              icon="school-outline"
              size="50px"
              color="teal"
              @click="changeIndex(0)"
            >
            </custom-card-icon>
          </v-col>

          <v-col cols="6">
            <custom-card-icon
              :head_title="$t('guardian')"
              icon="account-outline"
              size="50px"
              color="teal"
              @click="changeIndex(1)"
            >
            </custom-card-icon>
          </v-col>
          <v-col cols="6">
            <custom-card-icon
              :head_title="$t('checkrequiredcondition')"
              icon="text-box-check-outline"
              size="50px"
              color="teal"
              @click="changeIndex(2)"
            />
           
          </v-col>
           <v-col cols="6">
            <custom-card-icon
              :head_title="$t('verifyrequiredcondition')"
                icon="file-document-outline"
              size="50px"
              color="teal"
              @click="changeIndex(3)"
            />
           
          </v-col>
         
          <v-col cols="6">
            <custom-card-icon
              :head_title="$t('verifyrequiredqualification')"
              icon="certificate-outline"
              size="50px"
              color="teal"
              @click="changeIndex(4)"
            />           
          </v-col>
          <v-col cols="6">
          
              <custom-card-icon
                @click="changeIndex(5)"
                :head_title="$t('coursesquranprocession')"
                icon="text-box-outline"
                size="50px"
                color="teal"
              />
              
          </v-col>
          <v-col cols="6">           
              <custom-card-icon
                @click="changeIndex(6)"
                :head_title="$t('workquranmasarah')"
                icon="briefcase-account"
                size="50px"
                color="teal"
              />           
          </v-col>
            <v-col cols="6">
            <custom-card-icon
              :head_title="$t('checkrequiredcondition')"
              icon="text-box-check-outline"
              size="50px"
              color="teal"
              @click="changeIndex(6)"
            />           
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
  <v-bottom-sheet v-model="sheet[index]" class="h-100 ">
    <v-card class="pa-0 ma-0 rounded-t-lg" elevation="0" rounded="2">
      <v-card-title class="d-flex px-5"
        >{{ title[index] }}<v-spacer />
        <v-icon icon="mdi-close" @click="sheet = sheet.map(() => false)" />
      </v-card-title>
      <academic-data v-if="sheet[0]" :data="item" @updateEvent="update" />
      <guardian-data
        v-if="sheet[1]"
        :data="item.fk_student.fk_guardian"
        @updateEvent="update"
      />
      <verify-required-condition
        v-if="sheet[2]"
        :id="this.id"
        
      />
      
      <check-required-documents
        v-if="sheet[3]"
        :id="this.id "
    
      />
      <verify-required-qualification
        v-if="sheet[4]"
        :id="this.id"
      />
      <courses-quran-procession
        v-if="sheet[5]"
        :id="item.fk_student.id"
      />

      <desires-work-masarah
        v-if="sheet[6]"
        :id="item.fk_student.id"       
      />
      <work-on-masarah
        v-if="sheet[7]"
        :id="item.fk_student.id"       
      />
      
    </v-card>
  </v-bottom-sheet>
  <VDialog
    v-model="dialog"
    class="text-center"
    width="300"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
    <v-card class="ma-auto" width="300 ">
      <v-card-title class="bg-indigo text-white">
        <h3>
          {{ $t('data_update') }}
        </h3>
      </v-card-title>
      <v-divider />

      <v-card-actions
        style="min-height: 40px; height: 40px"
        class="pa-0 ma-0 d-flex mx-3"
      >
        <v-row>
          <custom-btn type="update" :click="() => updateData()" />

          <v-btn @click="getData(), (dialog = false)">
            <span class="text-indigo">{{ $t('cancel_changes') }}</span>
          </v-btn>

          <v-btn @click=";(dialog = false), (isEditing = true)">
            <span class="text-red"> {{ $t('cancel') }}</span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </VDialog>
</template>

<script>
import GuardianData from './Screen/GuardianData.vue'
import VerifyRequiredQualification from './Screen/VerifyRequiredQualification.vue'
import AcademicData from './Screen/AcademicData.vue'
import DesiresWorkMasarah from './Screen/DesiresWorkMasarah.vue'
import CheckRequiredDocuments from './Screen/CheckRequiredDocuments.vue'
import VerifyRequiredCondition from './Screen/VerifyRequiredCondition.vue'
import CoursesQuranProcession from './Screen/CoursesQuranProcession.vue'
import WorkOnMasarah from './Screen/WorkOnMasarah.vue'

export default {
  components: { GuardianData, VerifyRequiredQualification, AcademicData ,DesiresWorkMasarah,CheckRequiredDocuments,VerifyRequiredCondition,CoursesQuranProcession,WorkOnMasarah},

  data() {
    return {
      title: [
        this.$t('academic_data'),
        this.$t('guardian'),
        this.$t('checkrequiredcondition'),
        this.$t('verifyrequiredcondition'),
        this.$t('verifyrequiredqualification'),
        this.$t('coursesquranprocession'),
        this.$t('workquranmasarah'),
        this.$t('desirestoworkquran'),
      ],
      isEditing: false,
      dialog: false,
      loading: false,
      item: {
        fk_student: {
          fk_user: {},
        },

        fk_guardian: {},
        user_fk_ssn_archive: {},
      },
      sheet: [false, false],
      index: undefined,
    }
  },
  props: {
    id: {
      type: Object,
    },
  },
  async created() {
    await this.getData()
  },

  methods: {
      selectImage() {
      const input = this.$refs.imageInput;
      const files = input.files;
      if (files && files[0]) {
        const maxSizeInBytes = 2 * 1024 * 1024;
        if (files[0].size > maxSizeInBytes) {
          this.imageError = true;
        } else {
          this.imageError = false;
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imageRender = e.target.result;
            this.$emit("ImageSelected", files[0]);
          };
          reader.readAsDataURL(files[0]);
          this.$emit("resetRequest", 0);
        }
      }
    },
    changeIndex(i) {
      this.index = i
      this.sheet[i] = true
    },
    update(data) {
      data
        .then(() => {
          this.$emit('alert', 'update')
          this.sheet = this.sheet.map(() => false)
          this.getData()
        })
        .catch(() => {
          this.$emit('alert', 'errorData')
        })
    },
    async updateData() {
      const data = {
        user: {
          first_name: this.item.fk_student.fk_user.first_name,
          last_name: this.item.fk_student.fk_user.last_name,
          username: this.item.fk_student.fk_user.username,
          email: this.item.fk_student.fk_user.email,
          password: this.item.fk_student.fk_user.password,
          date_of_birth: this.item.fk_student.fk_user.date_of_birth,
          mobile_no: this.item.fk_student.fk_user.mobile_no,
          phone_no: this.item.fk_student.fk_user.phone_no,
          gender: this.item.fk_student.fk_user.gender,
          fk_place_of_brith: this.item.fk_student.fk_user.fk_place_of_brith,
          blood_type: this.item.fk_student.fk_user.blood_type,
        },
        user_fk_ssn_archive: {
          document_no: this.item.fk_student.fk_user.fk_ssn_archive_document_no,
          document_type:
            this.item.fk_student.fk_user.fk_ssn_archive_document_type,
          issue_date: this.item.fk_student.fk_user.fk_ssn_archive_issue_date,
          expiration_date:
            this.item.fk_student.fk_user.fk_ssn_archive_expiration_date,
          issue_place: this.item.fk_student.fk_user.fk_ssn_archive_issu_place,
        },
        user_fk_place_of_brith: {
          fk_region: this.item.fk_student.fk_user.fk_place_of_brith_fk_region,
          street: this.item.fk_student.fk_user.fk_place_of_brith_street,
        },
        user_fk_address: {
          fk_region: this.item.fk_student.fk_user.fk_address_fk_region,
          street: this.item.fk_student.fk_user.fk_address_street,
        },
        student_data: {
          marital_status: this.item.fk_student.marital_status,
          full_name_ar: this.item.fk_student.full_name_ar,
          name_en: this.item.fk_student.name_en,
          full_time_ratio: this.item.fk_student.full_time_ratio,
          brother_count: this.item.fk_student.brother_count,
          childern_count: this.item.fk_student.childern_count,
          childern_male_count: this.item.fk_student.childern_male_count,
          dictating: this.item.fk_student.dictating,
          quran: this.item.fk_student.quran,
          is_owned_housing: this.item.fk_student.is_owned_housing,
          wife_count: this.item.fk_student.wife_count,
          job: this.item.fk_student.job,
          job_entity: this.item.fk_student.job_entity,
          addicted: this.item.fk_student.addicted.join(','),
        },
      }

      await this.$axios
        .put(
          `student-affairs/registration-student/${this.item.fk_student.id}/`,
          data,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then(() => {
          this.$emit('alert', 'update')
          this.dialog = false
          this.getData()
        })
        .catch(() => {
          this.$emit('alert', 'errorData')
        })
    },
    edit() {
      this.isEditing = !this.isEditing
      this.dialog = !this.isEditing
    },

    async getData() {
      this.loading = true
      await this.$axios(`student-affairs/student-academic-data/${this.id}/`)
        .then((response) => {
          this.item = response.data
        })
        .catch(() => {
          this.$emit('alert', 'errorData')
        })

      this.loading = false
    },
  },
}
</script>

<style scoped>
.no-decoration {
  text-decoration: none;
  color: rgb(255, 255, 255);
}
.coustom-color {
  color: rgb(0, 165, 55);
  margin-right: 5px;
  /* background-color: aquamarine; */
}
.coustom-span {
  margin-right: 5px;
  color: #006d62;
}
.coustom-card {
  border-radius: 8px !important;
  background-color: #7878781f;
  margin: auto;
}
</style>
