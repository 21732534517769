<template>
  <custom-auto-complete
    :items="numbum"
    item-title="name"
    item-value="id"
    :label="$t('numberingbumper')"
    icon="account"
    col="3"
  />
</template>
<script>
export default {
  data() {
    return {
      numbum: [],
    };
  },
  created() {
    this.$axios("choices/choice-data/",{
      params:{
        param:'Algorithms4BuildingAcademicNo'
      }
    }).then((response) => {
      this.numbum = response.data;
    });
  },
};
</script>
