<template>
  <custom-auto-complete
    :items="getDir"
    item-title="name_ar"
    item-value="id"
    :label="$t('directoratename')"
    icon="map-outline"
    col="3"
     />
      
</template>
<script>
export default {
  props:{
    gov:{
      type:Object
    }
  },

  data() {
    return {
      directorates: [],
 
    }
  },
  created() {
    this.$axios('common/directorate-data/all/').then((e)=>{
          this.directorates=  e.data;
          console.log(this.directorates);
    });
  },

   computed:{
    
    getDir(){
      return this.directorates.filter(item=>item.fk_governorate==this.gov)
    }
  }
}
</script>