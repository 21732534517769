<template>
  <custom-auto-complete
    :items="items"
    item-title="name"
    item-value="id"
    :label="$t('iteration')"
    icon="refresh"
    col="3"
  />
</template>
<script>
// import { getActivityIterations } from '@/plugins/methods/global-methods'

export default {
  name: 'ListActivityIterations',
  data() {
    return {
      items: [],
    }
  },
  async created() {
    // this.items = await getActivityIterations()
  },
}
</script>