<template>
  <v-navigation-drawer
    v-if="$checkPermission(perm?.add || true, perm?.systems || true)"
    :width="width"
    class="v-navdra pt-5 px-2"
    :location="$i18n.locale != 'ar' ? 'right' : 'left'"
  >
    <!-- <div class="pt-5"></div> -->
    <h2 class="pt-3">{{ strTitle }}</h2>
    <v-divider class="pb-3"></v-divider>
    <slot name="body"></slot>
    <v-row class="pa-3 mt-3 justify-center">
      <slot name="action"></slot>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'NavDrawer',
  perm: Object,
  props: {
    title: {
      type: String,
    },
    width: {
      type: String,
      default: '270',
    },
  },
  computed: {
    strTitle() {
      if (this.title == 'create' || this.title == false)
        return this.$t('add')
      else if (this.title == 'update' || this.title == true)
        return this.$t('update')
      else return this.title
    },
  },
}
</script>
<style scoped>
.v-navdra {
  z-index: 1;
  /* transform: translateX(0%) !important; */
  /* position: fixed !important; */
  /* left: 0px !important; */
  /* height: calc((100% - 0px) - 0px) !important; */
  /* top: 0px !important; */
  /* bottom: 0px !important; */
  /* box-shadow: 0 10px 6px rgba(0, 0, 0, 0.16) !important; */
  /* width: 270px !important; */
}
</style>
