<template>
  <!-- <pre dir="ltr">{{periods}}</pre> -->
  <!-- <pre dir="ltr">{{schedule}}</pre> -->
  <!-- أضافة فترة -->
  <nav-drawer
    :title="$t('addperiod')"
    v-if="drawer"
    v-model="drawer"
    temporary
  >
    <template v-slot:body>
      <v-form ref="form">
        <v-row>
          <datetime-picker
            v-model="dropList.time_from"
            type="time"
            format="hh:mm"
            icon="mdi-timer-play-outline"
            col="12"
            datetime_picker="time_from"
            :label="$t('startperiod')"
            :rules="[$required, $time_min_max(dropList.time_from, dropList.time_to)]"
          >
          </datetime-picker>
          <datetime-picker
            v-model="dropList.time_to"
            type="time"
            format="hh:mm"
            icon="mdi-timer-play-outline"
            col="12"
            datetime_picker="time_to"
            :label="$t('endperiod')"
            :rules="[$required, $time_min_max(dropList.time_from, dropList.time_to)]"
          ></datetime-picker>
          <custom-text-field
            type="number"
            v-model="dropList.order_no"
            icon="alpha-e"
            col="12"
            :label="$t('ordernumber')"
            :rules="[$required]"
          ></custom-text-field>
        </v-row>
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn v-if="!is_update" type="save" :click="savePeriod" />
      <custom-btn v-if="is_update" type="update" :click="updateData" />
      <custom-btn type="cancel" @click.stop="drawer = !drawer" />
    </template>
  </nav-drawer>

  <v-card class="mt-2 px-1">
    <v-card-title class="d-flex mt-3 ml-5">
      <v-spacer></v-spacer>
      <custom-btn class="ml-2" type="save" :click="() => saveExamsSchedule()" />
      <v-slide-x-reverse-transition mode="out-in" class="mt-2">
        <v-icon
          :key="`icon-${isEditing}`"
          :icon="isEditing ? 'mdi-check' : 'mdi-circle-edit-outline'"
          @click="isEditing = !isEditing"
        />
      </v-slide-x-reverse-transition>
    </v-card-title>
    <v-card-text>
      <v-form ref="formSchedule">
        <v-table class="ma-3 pa-3 border-sm" density="compact">
          <thead>
            <tr>
              <th class="text-center fixed-width">
                {{ $t("date") }}/{{ $t("period") }}
              </th>
              <th
                class="text-center fixed-width"
                v-for="(period, index) in periods"
                :key="index"
              >
                <v-icon
                  size="14px"
                  @click="editData(period)"
                  class="ml-2"
                  color="orange-darken-3"
                  >mdi-pencil-outline</v-icon
                >
                {{ $t("period") }}
                <v-icon
                  size="14px"
                  small
                  @click="delet_id=period.id,dialogVisible=true"
                  class="ml-2"
                  color="red-darken-2"
                  >mdi-delete-outline</v-icon
                >
                <br />
                <small>{{ period.time_to }}-{{ period.time_from }} </small>
              </th>
              <th class="text-center fixed-width">
                <v-icon
                  @click="(this.drawer = true), this.resetForm();"
                  icon="mdi-plus"
                ></v-icon>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in schedule" :key="index">
              <td class="text-center fixed-width">
                <span><br />{{ item.exam_date }} </span>
              </td>
              <td
                class="text-center fixed-width pa-2"
                v-for="(period, index) in item.periods"
                :key="index"
              >
                <v-select
                  v-model="period.subjects"
                  :items="list_materials"
                  item-title="fk_differentiation_subject_name"
                  item-value="fk_differentiation_subject"
                  :label="$t('accept_test_materials')"
                  density="compact"
                  hide-details="auto"
                  prepend-inner-icon="mdi-book-open-page-variant-outline"
                  multiple
                  class="fixed-size-select"
                  :disabled="!isEditing"
                  ><template :v-slot:selection="{ list_materials, index }">
                    <v-chip v-if="index < 2">
                      <span>{{ list_materials }}</span>
                    </v-chip>
                    <span
                      v-if="index === 2"
                      class="text-grey text-caption align-self-center"
                      >(={{ value.lenght - 2 }} اخرى)</span
                    >
                  </template>
                </v-select>
              </td>
            </tr>
            <tr class="text-center fixed-width">
              <datetime-picker
                v-if="periods.length"
                icon="mdi-plus"
                :field="false"
                datetime_picker="ghgh"
                v-model="exam_date"
                @update:modelValue="addRow"
              >
              </datetime-picker>
            </tr>
          </tbody>
        </v-table>
      </v-form>
    </v-card-text>
  </v-card>
  <alert-protected-records
    v-model="protected_records"
    :data_message="data_message"
  ></alert-protected-records>
    <custom-delete-dialog v-model="dialogVisible" @confirmDelete="delData" />

</template>

<script>
export default {
  props: {
    fk_differentiaion_batch: {
      type: Number,
    },
    fk_differentiaion_group: {
      type: Number,
    },
  },
  data() {
    return {
      dialogVisible:false,
      delet_id:undefined,
      drawer: null,
      drawer2: null,
      dropList: {},
      periods: [],
      schedule: [],
      list_materials: [],
      exam_date: null,
      isEditing: false,
      protected_records: false,
      data_message: null,
    };
  },
  created() {
    this.getExamsSchedule();
    this.getAcceptTestMaterials();
  },

  methods: {
    addRow() {
      let period = {};
      for (let newPeriod of this.periods) {
        period[newPeriod.id] = { subjects: [] };
      }
      this.schedule.push({ exam_date: this.exam_date, periods: period });
    },
    // <!-------------------- جلب المواد ----------------------->
    async getAcceptTestMaterials() {
      await this.$axios(`coordination/differentiation-subject-batch/filter/`, {
        params: {
          field: "fk_differentiaion_batch",
          value: this.fk_differentiaion_batch,
        },
      })
        .then((response) => {
          this.list_materials = response.data;
        })
        .catch(() => {});
    },

    // <!-------------------- أضافة فترة ----------------------->
    async savePeriod() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.dropList.fk_differentiation_group = this.fk_differentiaion_group;
        await this.$axios
          .post("coordination/differentiation-period-for-schedule/", this.dropList)
          .then(() => {
            this.$emit("successAlert", this.$t("data_added"));
            this.getExamsSchedule();
            this.drawer = false;
          })
          .catch((e) => {
            this.$emit("errorAlert", e.response.data.non_field_errors[0]);
          });
      }
    },
    editData(period) {
      this.dropList = {};
      this.update_id = period.id;
      this.is_update = true;
      this.dropList = { ...period };
      this.drawer = true;
    },

    async updateData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.dropList.fk_differentiation_group = this.fk_differentiaion_group;
        await this.$axios
          .put(
            `coordination/differentiation-period-for-schedule/${this.dropList.id}/`,
            this.dropList
          )
          .then(() => {
            this.$emit("alert", "update");
            // this.resetForm()
            this.getExamsSchedule();
            this.drawer = false;
          })
          .catch(() => {
            this.$emit("alert", "errorData");
          });
      }
    },

    async delData() {
      if (this.delet_id != undefined) {
        var status;
        await this.$axios
          .delete("coordination/differentiation-period-for-schedule/" + this.delet_id + "/")
          .then(() => {
            status = true;
            this.getExamsSchedule();
            this.drawer = false;
          })
          .catch((e) => {
            status = e
            this.protected_records = true;
            let error_messages = [];
            e.response?.data?.data?.forEach((error) => {
              error_messages.push(this.$t(`models.${error.model}`) + ` = ${error.count}`);
            });
            this.data_message = error_messages;
          });

          this.dialogVisible =false
        
          
      }
    },
    resetForm() {
      this.dropList = {};
      this.update_id = undefined;
      this.is_update = false;
    },

    // <!-------------------- أضافة جدول الاختبار ----------------------->
    async getExamsSchedule() {
      await this.$axios
        .post(`coordination/differentiation-schedule/get-schedule-by-batch-and-group/`, {
          fk_differentiaion_batch: this.fk_differentiaion_batch,
          fk_differentiation_group: this.fk_differentiaion_group,
        })
        .then((response) => {
          this.periods = response.data.header;
          this.schedule = response.data.results;
        })
        .catch(() => {});
    },

    async saveExamsSchedule() {
      const { valid } = await this.$refs.formSchedule.validate();
      if (valid) {
        this.dropList.fk_differentiaion_batch = this.fk_differentiaion_batch;
        this.dropList.results = this.schedule;
        await this.$axios
          .post(
            "coordination/differentiation-schedule/create-schedule-by-batch-and-group/",
            this.dropList
          )
          .then(() => {
            this.$emit("successAlert", this.$t("data_added"));
            this.getExamsSchedule();
            this.isEditing = false;
          })
          .catch(() => {
            this.$emit("errorAlert", this.$t("error_in_data"));
          });
      }
    },
  },
};
</script>
<style scoped>
.fixed-width {
  width: 150px;
  max-width: 1;
  box-sizing: border-box;
}
</style>
