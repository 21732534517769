<template>

  <nav-drawer
    v-show="drawer"
    :title="update_id ? 'update' : 'create'"
    v-model="drawer"
    temporary
  >
    <template v-slot:body>
      <v-form ref="form">
        <custom-text-field
          type="number"
          v-model="dropList.full_time_ratio"
          icon="percent-outline"
          col="12"
          :label="$t('fulltimeratio')"
          :rules="[$required, $max_value(100)]"
        />
        <custom-text-field
          v-model="dropList.work"
          icon="briefcase-outline"
          col="12"
          :label="$t('workname')"
          :rules="[$required, $max_length(100)]"
        />
        <custom-text-field
          v-model="dropList.specialization"
          icon="school-outline"
          col="12"
          :label="$t('specialty')"
          :rules="[$required, $max_length(100)]"
        />
        <custom-text-field
          v-model="dropList.entity"
          icon="town-hall"
          col="12"
          :label="$t('side')"
          :rules="[$required, $max_length(100)]"
        />
        <custom-text-note
          v-model="dropList.note"
          :rules="[$ar_letters_only, $max_length(500)]"
        />
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn
        :type="update_id ? 'update' : 'save'"
        :click="update_id ? updateData : saveData"
      />
      <custom-btn @click.stop="resetForm(), (drawer = !drawer)" />
    </template>
  </nav-drawer>
  <custom-data-table-2
    :headers="headers"
    :items="items"
    :pagination="pagination"
    :method="getData"
    :delItem="delData"
    :editItem="editData"
    :actionList="true"
    :perm="{}"
    exports
    :create="() =>  this.openDrawer()"
    />
</template>
<script>
import { getDataStudent } from '@/plugins/methods/global-methods'

export default {
  props: {
    perm: Object,
    id: {
      type: Number,
    },
  },

  data() {
    return {
      items: [],
      studentdata: [],
      pagination: undefined,
      dropList: {},
      drawer: null,
      headers: [
        // { title: this.$t('studentname'), key: 'student_name' },
        { title: this.$t('workname'), key: 'work' },
        {
          title: this.$t('specialty'),
          key: 'specialization',
        },
        { title: this.$t('side'), key: 'entity' },
        {
          title: this.$t('fulltimeratio'),
          key: 'full_time_ratio',
        },
        { title: this.$t('note'), key: 'note' },

        { title: this.$t('actions'), key: 'actions' },
      ],
    }
  },
  async created() {
    // this.getDataStudent()
    this.studentdata = !this.studentdata.length
      ? await getDataStudent(this.id)
      : this.studentdata
  },
  methods: {
    // async getDataStudent() {
    //   await this.$axios(`student-affairs/students/${this.id}/`)
    //     .then((response) => {
    //       this.studentdata = response.data
    //     })
    //     .catch(() => {})
    // },
    openDrawer(){
      this.resetForm()
      this.drawer = true
    },
    resetForm() {
      this.update_id = null
      this.$refs.form.reset()
    },
    async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
      await this.$axios(`student-affairs/desires4-jihadi-work/filter-paginate/`, {
        params: {
          search: txt_search,
          page: page,
          page_size: perPage,
          sort_by: order_data,
          field: 'fk_student',
          value: this.id,
        },
      })
        .then((response) => {
          this.items = response.data.results
          this.pagination = response.data.pagination
        })
        .catch(() => {})
    },
    editData(data) {
      this.resetForm()
      this.update_id = data.id
      this.dropList = { ...data }
      this.drawer = true
    },
    async delData(delete_id) {
      if (delete_id != undefined) {
        var status
        await this.$axios
          .delete('student-affairs/desires4-jihadi-work/' + delete_id + '/')
          .then(() => {
            status = true
          })
          .catch((error) => {
            status = error
          })
        return status
      }
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        this.dropList.fk_student = this.id
        await this.$axios
          .post('student-affairs/desires4-jihadi-work/', this.dropList)
          .then(() => {
            this.$emit('alert', 'add')
            this.resetForm()
            this.drawer = !this.drawer
            this.getData()
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })
      }
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        this.dropList.fk_student = this.id

        await this.$axios
          .put(
            `student-affairs/desires4-jihadi-work/${this.id}/`,
            this.dropList,
          )
          .then(() => {
            this.$emit('alert', 'update')
            this.resetForm()
            this.getData()
            this.drawer = false
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })
      }
    },
  },
}
</script>
