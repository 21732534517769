<template>
  <nav-drawer v-show="drawer" v-model="drawer" temporary title="اضافة ارسالية التسكين">
    <template v-slot:body>
      <v-form ref="form">
        <v-row class="px-3 pt-5">
          <drop-list
            name="batch"
            v-model="send_items.fk_batch"
            @update:modelValue="(send_items.fk_semester_4b = undefined ,send_items.group = undefined ,send_items.fk_student = undefined)"
            :rules="[$required]"
            v-show="!is_update"
            
          />
          <drop-list
            name="semester_4_batch"
            v-model="send_items.fk_semester_4b"
            :param="send_items.fk_batch"
            @update:modelValue="(send_items.group = undefined,send_items.fk_student = undefined)"
            :rules="[$required]"
            v-show="!is_update"
          />
          <drop-list
            name="group_4_semester"
            v-model="send_items.group"
            :param="send_items.fk_semester_4b"
            @update:modelValue="send_items.fk_student = undefined"
            :rules="[$required]"
            :disabled="is_update"

          />

          <drop-list
            name="student_4_group"
            v-model="send_items.fk_service.fk_student"
            :param="send_items.group"
            :rules="[$required]"
            :disabled="is_update"
          />
          
          <drop-list name="currency" v-model="send_items.fk_service.fk_currency" col="12" :rules="[$validateRequired]"></drop-list>
      
      <fieldset class="w-100" style="border:1px solid"> 
          <legend >
              {{$t('document_data')}}
          </legend>
          <drop-list name="documnet_type" v-model="send_items.fk_service.fk_document.document_type" col="12" :rules="[$validateRequired]"></drop-list>
              <custom-text-field
                v-model="send_items.fk_service.fk_document.issue_place"
                icon="map-marker"
                :label="$t('issue_place')"
                :rules="[$validateRequired , $validateArabicText , $max_length(100)]"
              ></custom-text-field>
   <datetime-picker
            class="date-time"
            v-model="send_items.fk_service.fk_document.issue_date"
            :label="$t('issue_date')"
            :rules="[$required]"
            datetime_picker="date"
          ></datetime-picker>
              <custom-text-field
                v-model="send_items.fk_service.fk_document.document_no"
                :label="$t('document_no')"
                :rules="[$required, $max_value(65535)]"
                icon="numeric"
              >
              </custom-text-field>
              <span class="mx-5" v-if="send_items.fk_service.fk_document.document"><label >الملف:  <a :href="send_items.fk_service.fk_document.document" target="_blank"><v-icon >mdi-file</v-icon></a>  </label></span>
              <custom-file-input
                v-model="file"
                type="file"
                :label="$t('file')"
                @change="
                  file ? (send_items.fk_service.fk_document.document = file) : (send_items.fk_service.fk_document.document = null)
                "
                :herf="!file?.length ? data?.file : false"
                :rules="[!is_update ? $required : true]"
              ></custom-file-input>
      </fieldset>
        </v-row>
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn
        :type="is_update ? 'update' : 'save'"
        @click="is_update ? updateData() : saveData()"
      />
      <custom-btn @click.stop="drawer = !drawer" />
    </template>
  </nav-drawer>
<custom-card
    
    :title="$t('select-criteria')"
    :head_title="$t('accommodations')"
    head_date="27يناير"
    class="cardcolor"
    >
    <template v-slot>
      <v-row class="align-center">
            <drop-list
            v-model="send_items.fk_filter" 
            @update:modelValue="filterSearch"  
            col="12"
            name="service_status"
                    ></drop-list>
      </v-row> </template
    ></custom-card>
<custom-data-table-2
    :headers="headers"
    :items="items"
    :pagination="pagination"
    :method="getData"
    :delItem="delData"
    :perm="{}"
    export
    :create="()=>resetForm()"
  > 
    <template v-slot:item="{item,key}">
        <v-span v-if="key=='service_status'" style="display:block; width:150px">
                <drop-list v-model="item[key]" @update:modelValue="editData(item)"  col="12" name="service_status"></drop-list>
        </v-span>
        <v-span v-if="key=='is_paid'" style="display:block; width:150px">
        <v-checkbox v-model="item[key]" @update:modelValue="editData(item)" ></v-checkbox>
        </v-span>
  </template>
  </custom-data-table-2>
</template>
<script>
export default {
  props: {
    perm: Object,
  },
  data() {
    return {
      url :"/student-services/accommodation-service/",
      items: [],
      send_items: {
      fk_specialization: undefined ,
      fk_service:{
        fk_document:{}
      }},
      
      pagination: undefined,
      data: {},
      is_update: false,
      drawer: false,
      file: null,
      headers: [
        { title: this.$t('id'), key: 'id' },
        { title: this.$t('student_name'), key: 'student_name',sortable: false },
        { title: this.$t('expiration_date'), key: 'expiration_date',sortable: false , show: false },
        { title: this.$t('document'), key: 'document',type:"document",sortable: false , show: false },
        { title: this.$t('document_no'), key: 'document_no',sortable: false , show: false },
        { title: this.$t('issue_date'), key: 'issue_date' ,sortable: false , show: false},
        { title: this.$t('document_type'), key: 'document_type_name',sortable: false },
        { title: this.$t('issue_place'), key: 'issue_place',sortable: false },
        { title: this.$t('request_date'), key: 'request_date' ,sortable: false , show: false},
        { title: this.$t('request_no'), key: 'request_no',sortable: false, show: false },
        { title: this.$t('service_status'), key: 'service_status' ,sortable: false },
        { title: this.$t('service_fees'), key: 'service_fees',sortable: false , show: false},
        { title: this.$t('is_paid'), key: 'is_paid',sortable: false  },
        { title: this.$t('fk_sender'), key: 'sender_name',sortable: false },
        { title: this.$t('fk_receiver_to_approval'), key: 'receiver_to_approval_name' ,sortable: false},
        { title: this.$t('actions'), key: 'actions',sortable:false },
      ],
    }
  },

  computed: {
    updateheaders() {
      
        if (this.items) {
          
          this.headers[1].key = this.items.id
        }
        return this.headers[1].key;
      },
  },
  methods: {
    filterSearch(data) {
      if(data == null){
        this.url = "/student-services/accommodation-service/";
        this.getData();
      }
      else if (data != null) {
        this.url = `/student-services/accommodation-service/filter-by-status/?service_status=${data}`;
        
        this.getData();
      } else {
        this.url = "/student-services/accommodation-service/";
        this.getData();
      }
    },
    async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
      await this.$axios(this.url, {
        params: {
          search: txt_search,
          page: page,
          page_size: perPage,
          sort_by: order_data,
        },
      })
        .then((response) => {
            let data  = this.url.includes('filter')?response.data:response.data.results;
            this.items=[];
            data.map((item)=>{
          
              this.items.push({
              ...item,
              expiration_date:item.fk_service.fk_document.expiration_date,
              service_fees:item.fk_service.service_fees,
              is_paid:item.fk_service.is_paid,
              request_no:item.fk_service.request_no,
              request_date:item.fk_service.request_date,
              service_status:item.fk_service.service_status,
              document_no:item.fk_service.fk_document.document_no,
              document:item.fk_service.fk_document.document,
              document_type_name:item.fk_service.fk_document.document_type_name,
              issue_place:item.fk_service.fk_document.issue_place,
              issue_date:item.fk_service.fk_document.issue_date,
            })
          })
          this.pagination = response.data.pagination
        })
        .catch(() => {})
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const formData = new FormData()
        if (this.send_items.fk_service.fk_document) {
          
          for (const [key, value] of Object.entries(this.send_items.fk_service.fk_document)) {
            if (key == 'document') {
              formData.append('file',value[0])
            } 
          }
        }
        formData.append('data',JSON.stringify(this.send_items))
        await this.$axios
          .post('/student-services/accommodation-service/create_accommodation/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(() => {
            this.$emit('successAlert', this.$t('data_added'))
            this.resetForm()
            this.getData()
          })
            .catch((error) => {
            if (error.response.status == 400) {

              if (error.response.data.error == "يرجى اعداد الخدمة من شاشة الاعدادات") {
              this.$emit(
                'warningAlert',
                this.$t('error_in_settings'),
              )   
              } 
              else  if (error.response.data.error == " يرجى ضبط اعدادت الخدمة") {
              this.$emit(
                'warningAlert',
                this.$t('error_in_settings'),
              )   
              } 
              else if(error.response.data.error =="الحقول document_no, document_type يجب أن تشكل مجموعة فريدة."){
                this.$emit(
                'warningAlert',
                " يجب ان تكون رقم الوثيقة غير موجود بالوثائق"
              )   
              }
              else{

              this.$emit(
                'warningAlert',
                this.$t('error_in_repeted_data'),
              )
                }
            
            } else {
              this.$emit('errorAlert', this.$t('error_in_data'))
            }
          })
      }
    },
    editData(data, isPermission = false) {
      this.update_id = data.id
      if (!isPermission) {  
        this.data.service_status = data.service_status 
        this.data.is_paid = data.is_paid 
        this.is_update = true
        this.updateData()
      } else {
        return true
      }
    },
  async updateData() {
        this.data.data_entry = this.user;
      await this.$axios
          .put(
            this.base_url + "student-services/accommodation-service/update-data/?id=" + this.update_id ,
              this.data
          )
          .then(() => {
            this.$emit("successAlert", this.$t("data_updated"));
                    this.resetForm();
        this.drawer =false;
        this.getData();
          })
          .catch(() => {
            this.$emit("errorAlert", this.$t("title"));
          });
    },
    async delData(delete_id, isPermission = false) {
      if (!isPermission) {
        if (delete_id != undefined) {
          var status
          await this.$axios
            .delete('student-services/accommodation-service/' + delete_id + '/')
            .then(() => {
              status = true
            })
            .catch((error) => {
              status = error
            })
          return status
        }
      } else {
        return true
      }
    },
    resetForm() {
      this.$refs.form.reset()
      this.data = {}
      this.send_items.fk_service={}
      this.send_items.reason=undefined
      this.send_items.is_fulfill_condition=undefined
      this.send_items.fk_service.fk_document={}
      this.send_items.fk_service.fk_document.document_no=undefined
      this.is_update = false
      this.drawer = this.drawer ? !this.drawer : !this.drawer
      this.file = undefined
    },
  },
}
</script>
<style scoped>
fieldset{
  border: 1px #5e6981 solid;
  border-radius: .3rem;
}
legend{
  padding-inline: 8px;
    margin-right: 7px;
    font-size: smaller;
  
}
</style>