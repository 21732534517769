<template>

  <!-- @update:modelValue="getStudentList()" -->
  <custom-auto-complete
    clearable
    :items="items"
    item-title="name_ar"
    item-value="id"
    :label="$t('select')"
    icon="account-tie"
    :col="col"
    :rules="rules"
    density="compact"
  
    
     />
</template>
<script>
// import {getEmployee} from '@/plugins/methods/global-methods'

export default {
 
  
props:{
  rules:{
    type:Object
  },

    col:{
    type:Number,
    default(){
      return 3
    }
  }

},  data() {
    return {
      items: [],
      
 
    }
  },
  
  async created() {

    // this.items =await getEmployee();
    
  },
  

    
 
 

}
</script>