<template>
  <custom-auto-complete
    :items="items"
    :placeholder="$t('batch_placeholder')"
    item-title="name_ar"
    item-value="id"
    :label="$t('batch')"
    icon="book"
    :col="col"
    :rules="rules"
  />
</template>
<script>
export default {
  props: {
    col: {
      type: Number,
      default() {
        return 12
      },
    },
    rules: {
      type: Object,
    },
  },
  data() {
    return {
      items: [],
    }
  },
  created() {
    this.$axios.get('/academic-affairs/batch/all/').then((e) => {
      this.items = e.data
    })
  },
}
</script>
