
import * as method from '@/plugins/methods/global-methods'


export const data =(name,param)=>{

    const list =[
        {
          'year': [
            'academic_year',
            'calendar',
            method.getCollege,
            'name_ar',
            'id',
          ],
        },
        {
          'colleg': [
            'college',
            'school',
            method.getCollege,
            'name_ar',
            'id',
          ],
        },
        {
          'colleg_with_out_regester_batch': [
            'college',
            'school',
            ()=>param?method.getCollegeWithOutRegesterInBatch(param):[],
            'name_ar',
            'id',
        ],
        },
        {
          'colleg_with_out_regester_specializations': [
            'specialization_name',
            'book',
            ()=>param?method.getCollegeWithOutRegesterInBatch(param):[],
            'name_ar',
            'id',
          ],
        },
        ///الفصول الذي بالخطة
        {
          'semester_in_plane': [
            'clss_name',
            'chart-pie',
            ()=>[],
            
          ],
        },
        //المراحل
        {
          'level': [
            'level',
            'stairs-box',
            method.getLevelChoice,
            
          ],
        },
        // المباني 
        {
          'building': [
            'buildings',
            'office-building',
            ()=>param?method.getBuildings(param):[],
            'name_ar',
            'id'    
          ],
        },

// جلب القاعات حسب المبنى
        {
          'get_halls_4_building': [
            'halls',
            'town-hall',
            ()=>param?method.getHalls(param):[],
            'hall_no'                    

          ],
        },

// جلب اللجان حسب المبنى
        {
          'get_committees_4_building': [
            'committees',
            'account',
            ()=>param?method.getCommittees(param):[],
            'hall_no'                    

          ],
        },
        //////  السكن
        {
          'accommodations': [
            'accommodations',
            'home',
            ()=>param?method.getAccommodations(param):[],      
          ],
        },
        //////     الاسرة
        {
          'bed': [
            'bed_number',
            'bed',
            ()=>param?method.getBeds(param):[],
          ],
        },
        ///////     السرايا
        {
          'accommodation_group': [
            'group',
            'account-group',
            method.getAccommodationGroup,
            "name_ar"
          ],
        },
        //////////    خدمات التسكين
        {
          'accommodation_service': [
            'service',
            'room-service',
            method.getAccommodationService,
            'student_name'
          ],
        },
        //الاعوام للفصول
        {
          'year_in_semester_batch': [
            'academic_year',
            'calendar',
            ()=>param?method.getYearInPlaneBatch(param):[],
            'year_h'      
          ],
        },
        //الاعوام  للفصول للتخصصات
        {
          'year_in_semester_specialization': [
            'academic_year',
            'calendar',
            ()=>param?method.getYearInPlaneSpecialization(param):[],
            'year_h'      
          ],
        },
        ///نظام الدرجات
        {
          'system_degree': [
            'grading_system',
            'numeric',
            method.getGradingSystem,
            'name_ar'      
          ],
        },
//جلب المواد للفصل دون تكرار
{
          'subject_for_semester_without_repetition': [
            'subject',
            'book',
            ()=>param?method.getSubjectForSemesterWithoutRepetition(param):[],   
                
          ],
        },
        ////المواد للفصل
        {
          'subject_for_semester': [
            'subject',
            'book',
            ()=>param?method.getSubjectForSemester(param):[],   
                
          ],
        },
         ////المواد 
        {
          'subjects': [
            'subject',
            'book',
            method.getSubjectsall,
            'name_ar' 
          ],
        },
            ////كل المواد
            {
              'allsubjects': [
                'subject',
                'book',
                method.getSubjects,
                'name_ar' 
              ],
            },
        ///قسم المواد
        {
          'subject_section': [
            'subject_section',
            'chart-pie',
            method.getSubjectSection,           
          ],
        },
        //نوع المادة نظري عملي 
        {
          'subject_type': [
            'subject_type',
            'chart-pie',
            method.getSubjectType,           
          ],
        },
        //التخصصات للكلية
        {
          'specializatoin_4B': [
            'specialization_name',
            'book',
            method.getSpecializatoin4B,       
            'name_ar'    
          ],
        },
        //// كلية او تخصص        
        {
          batch_or_specializatoin: [
            'data_type',
            'book',
            ()=>[
              {
                id:1,
                name:"تخصص"
              },
              {
                id:2,
                name:"كلية"
              
              }
                      
            ],       
          
          ],
        },   
        //الجهة المرسلة  
        {
          'sending_party': [
            'sending_party',
            'send',
            ()=>[],              
          ],
        }, 
        ///نوع السكن    
        {
          'accommodation_type': [
            'accommodation_type',                           
            'office-building-outline',
            ()=>method.getChoices('AccommodationTypeChoice')               
          ],
        },  
        ///الدولة  
        {
          'country': [
            'country',
            'flag-outline',
            method.getCountry,         
            'name_ar'        
          ],
        },    
        // المحافظة حسب الدولة
        {
          'gov_for_country': [
            'governoratename',
            'city',
            ()=>param?method.getGovForCountry(param):[],         
            'name_ar'        
          ],
        },   
        //ألمديرية حسب المحافظة 
        {
          'dir_for_gov': [
            'directoratename',
            'map-outline',
            ()=>param ? method.getDirForGov(param) : [],         
            'name_ar'        
          ],
        },    
          ////الجنسية
        {
          'nationalty': [
            'nationality',
            'map-outline',
            method.getCountry,         
            'nationality_name_ar'
          ],
        },
        //// نوع الوحدة في السكنات  
        {
          'unit_type': [
            'unit_type',
            'calculator',
            method.getUnitType,         
                
          ],
        },  
        //// حالة الوحدة في السكنات  
        {
          'unit_status': [
            'status',
            'list-status',
            method.getUnitStatus,         
          ],
        },  
        //// حالة التسكين  
        {
          'assigning_status': [
            'assigning_status',
            'list-status',
            method.getAssigningStatus,         
          ],
        },  
        ///الجنس
        {
          'gendar': [
            'gender',
            'gender-male-female',
           ()=> method.getChoices('GenderChoice')                        
          ],
        },   
        //نوع المستخدم 
        {
          'type_user': [
            'user_type',
            'account-circle',
            method.getDirForGov,         
                
          ],
        },
        ///الدفعة  
        {
          'batch': [
            'batch',
            'ballot-outline',
            method.getBatch,      
            'name_ar'
          ],
        },   
        //الفصول حسب الدفع
        {
          'semester_4_batch': [
            'seasons',
            'flag',
            ()=>param?method.getSemesteForBarch(param):[],
          ],
        },   
        //المجموعات حسب الفصول
        {
          'group_4_semester': [
            'sumester_group',
            'account-group-outline',
            ()=>param?method.getGroupBySemester(param):[], 
            'fk_grouping'        
          ],
        },  
        //الطلاب حسب المجموعة
        {
          'student_4_group': [
            'student_name',
            'account',
            ()=>param?method.getStudent4Group(param):[], 
            'fk_student',
            'fk_student_id'       
          ],
        },  
            //الطلاب حسب الدفعة
            {
              'StudentBatch': [
                'student_name',
                'account',
                ()=>param?method.getStudentBatch(param):[], 
                'fk_student',
                'fk_student_id'       
              ],
            },  

        

        // بيانات المستخدمين
        {
          'user_data': [
            'users',
            'account',
            method.getUserData,
            'username'        
          ],
        },      
        //ألمجموعة
        {
          'group': [
            'group',
            'account-multiple',
            method.getGroup, 
            'name_ar'                        
          ],
        }, 
        //ألمجموعة
        {
          'group_for_semester': [
            'group',
            'account-multiple',
            ()=>[],
            'name_ar'                     
          ],
        }, 
        //الفتره
        {
          'period': [
            'group',
            'clock',
            method.getPeriod,                         
          ],
        }, 
        //الاملاء
        {
          'dictating': [
            'dictating',
            'pencil-outline',
            ()=>method.getChoices('EstimateChoice'),                         
                        
          ],
        }, 
        //القران
        {
          'quran': [
            'quran',
            'book-open-page-variant-outline',
            ()=>method.getChoices('EstimateChoice'),                         
          ],
        }, 
        //نسبة التفرغ
        {
          'full_time_ratio': [
            'full_time_ratio',
            'alarm-snooze',
            ()=>method.getChoices('RatioChoice'),                         
                      
          ],
        }, 
     

        //ماذا يتعاطى
        {
          'addicted': [
            'addicted',
            'alert-outline',
            ()=>method.getChoices('AddictedChoice'),                         
          ],
        }, 
        //المهارات والمواهب
        {
          'talent': [
            'talent_skills',
            'pencil',
            method.getTalentChoices,   
            'name_ar'                      
          ],
        }, 
        //الموؤهلات
        {
          'qualifcation': [
            'qualifcation',
            'certificate-outline',
            ()=> method.getChoices('QualificationTypeChoice')                        

           ],
        }, 

        //حالة التحضير
        {
          'prepareChoice': [
            'prepare_status',
            'certificate-outline',
            ()=> method.getPrepareChoice(),                     

           ],
        }, 
        
        //نوع الوثيقة
        {
          'documnet_type': [
            'documnet_type',
            'file-document-alert-outline',
            method.getdocmunet,
            'name_ar'                    

           ],
        }, 
         //نوع الوثيقة بدون بيانات
         {
          'custom_documnet_type': [
            'documnet_type',
            'file-document-alert-outline',
            ()=>[],
            'name_ar'                    

           ],
        }, 
        //القسم
        {
          'department': [
            'department',
            'format-list-bulleted',
            ()=> method.getChoices('SecondaryDepartmentChoice')                                   
           ],
        }, 
        //الحالة الصحية
        {
          'healthy_condition': [
            'father_health_condition',
            'heart-pulse',
            ()=> method.getChoices('HealthyConditionChoice')                                   
           ],
        }, 
        //الحالة المالية
        {
          'financial_condition': [
            'financial_condition',
            'currency-usd',
            ()=> method.getChoices('FinancialConditionChoice')                                   
           ],
        }, 
        //مستوى التعليم
        {
          'EducationalLevelChoice': [
            'educational_level',
            'book-education-outline',
            ()=> method.getChoices('EducationalLevelChoice')                                   
           ],
        }, 
       
        //نوع فصيلة الدم
        {
          'blood_type': [
            'blood_type',
            'blood-bag',
            ()=>method.getChoices('BloodTypeChoice'),                         
          ],
        }, 
        //مادة الفصل للدفعة
        {
          'semester_subject_4b': [
            'subject_lecture_batch',
            'book',
            ()=>[],                         
          ],
        }, 
        //المرفقات
        {
          'attachments': [
            'title',
            'attachment',
            method.getAttachment,
            'name_ar'
            ],
        }, 
        //نوع المحاضرة
        {
          'lecture_type': [
            'lecture_type',
            'file-document',
            ()=>method.getChoices('SubjectTypeChoice'),                         
          ],
        }, 
        //المنطقة
        {
          'region_by_dir': [
            'region',
            'map-marker-outline',
            ()=>param?method.getRegionByDir(param):[],
            'name_ar'                       
          ],
        }, 
        //الحالة الاجتماعية
        {
          'marital_status': [
            'marital_status',
            'account-group-outline',
            ()=>method.getChoices('MaritalStatusChoice'),            
          ],
        }, 
        //نوع المرض
        {
          'type_condition': [
            'type_condition',
            'heart-pulse',
            ()=>method.getChoices('ConditionTypeChoice'),            
          ],
        }, 
        ///الدرجة العلمية   
        {
          'degree_choice': [
            'degree',                           
            'layers',
            ()=>method.getChoices('DegreeChoice')               
          ],
        }, 
        //نوع العملة
        {
          'currency': [
            'currency',
            'margin',
            method.getCurrency,
            'name_ar'                    

          ],
        },
        ///الدرجة العلمية   
        {
          'degree_choice': [
            'degree',                           
            'layers',
            ()=>method.getChoices('DegreeChoice')               
          ],
        }, 
        //نوع العملة
        {
          'currency': [
            'currency',
            'margin',
            method.getCurrency,
            'name_ar'                    

          ],
        },
        {
          'getSpecializationByBranchId': [
            'specialization_name',
            'multicast',
            ()=>param?method.getSpecializationByBranchId(param):[],
            'name_ar',
            'id',
          ],
        },
                // الفروع  
                {
                  'branches': [
                    'branch',
                    'office-building',
                    method.getBranches,
                    'name_ar'
                  ],
                },
                // جلب الكليات بحسب الفرع
                {
                  'collage_for_branch': [
                    'college',
                    'school',
                    ()=>param?method.getCollegesByBranchId(param):[],
                    'college_name_ar',
                    'fk_college',
                  ],
                },
            
                // نوع النظام
                {
                  'jop_system': [
                    'jop_system',
                    'account-tie',
                    method.JopSystemChoice,
                  ],
                },
                // حالة الموظف
                {
                  'employee_status': [
                    'status',
                    'account-tie',
                    method.EmployeeStatusChoice,
                  ],
                },
                // نوع البطاقة
                {
                  'document_type': [
                    'document_type',
                    'card-account-details',
                    method.getDocumentType,
                    "name_ar"
                  ],
                },

        ///الية القبول 
        {
          'acceptance_mechanism_choice': [
            'acceptance_mechanism',                           
            'desktop-classic',
            ()=>method.getChoices('AcceptanceMechanismChoice')               
          ],
        }, 

//جلب  المستخدمين حسب النوع(موظف - مدرس)
        {
          'get_users_by_ET': [
            'users',
            'account',
            method.getUsersByTecherEmploot,
            'full_name'                    
          ],
        },
        //عناوين المحاضرات
        {
          'lecture_name': [
            'lectur_name',
            'subtitles-outline',
            ()=>param?method.getLectureName(param):[],
            'lecture_ar'                    

          ],
        },

///دفع التنيسق والقبول  
        {
          'batch_coordination': [
            'differentiation_batch',
            'ballot-outline',    
            method.batchCoordination,      
            'name'
          ],
        },   

//المجموعات حسب دفع التنيسق والقبول
        {
          'group_4_batch_coordination': [
            'group',
            'flag',
            ()=>param?method.groupBatchCoordination(param):[],
            'name'
          ],
        }, 

        ///الفترات
        {
          'group_period_choice': [
            'period',                           
            'ab-testing',
            ()=>method.getChoices('GroupPeriodChoice')               
          ],
        },

        ///اختبارات القبول
        {
          'diff_subject': [
            'test_subject',                           
            'test-tube',
            ()=>method.getDiffSubject(),
            'name_ar',
            'id'        
          ],
        }, 

        ///اختبارات القبول بحسب الدفعة
        {
          'diff_subject_batch': [
            'test_subject',                           
            'test-tube',
            ()=>param?method.getDiffSubjectByBatch(param):method.getDiffSubjectByBatch(),       
            'fk_differentiation_subject_name',
            'fk_differentiation_subject'
          ],
        }, 
    //نوع الوثائق للتحقق من الوثائق

        {
          'get_type_docmunetFor_checkDocument': [
            'documnet_type',
            'file-document-alert-outline',
            ()=>param?method.getTypeDocmunetForCheckDocument(param,0):[],
            'documnet_type_name'                    
           ],
        }, 
          ////المواد للدفعة
          {
            'subject_for_batch': [
              'subject',
              'book',
              ()=>param?method.getSubjectForBatch(param):[],
              'name',
              'subject_id'                  
            ],
          },
            /// اليوم  
        {
          'day': [
            'day',                           
            'calendar-today-outline',
            ()=>method.getChoices('DayChoice')
          ]
          },
          {
          'employee_for_subject': [
            'teacher',
            'account',
            ()=>param?method.getTeacherForSubject(param):[],
          ],
        },
           //الفترة حسب المجموعة
           {
            'period_for_groups': [
              'period',
              'clock',
              ()=>param?method.getPeriodForGroups(param):[],
              'order_no'
            ],
          }, 
        
          
        //حالة الخدمة
        {
          'service_status': [
            'service_status',
            'room-service',
            method.getServiceStatus,
            'name'                    

          ],
        },
         // الطلاب
        {
          'student': [
            'student',
            'account',
            method.getStudentِِAcadmic,
            'fk_student_full_name_ar'                    

          ],
        },
             // التخصصات
        {
          'specialization': [
            'specialization_name',
            'multicast',
            method.getSpecialization,
            'name_ar'                    

          ],
        },
        // الموؤهلات انواع
        {
        'qualification_type': [
          'qualification_type',
          'school',
          method.getqualificationtype,
          'name'                    

        ],
      },
      // المرحلة الدراسية 
      {
        'education_level': [
          'education',
          'school',
          method.getEducationalLevel,
          'name'                    

        ],
      },
      // الفصول 
      {
        'semester': [
          'semester',
          'chart-pie',
          method.getSemester,
          'name'                    

        ],
      },
      {
        'branch': [
          'branch',
          'chart-pie',
          method.getBranch,
          'name_ar'                    

        ],
      },      
      //تحضير الاسكان
        {
          'accommodation_group': [
            'buildings',
            'office-building',
            ()=>param?method.getAccommadationGroup(param):[],
            'name_ar'
          ],

        },

         ///التصنيفات  
        {
          'get_activiteis_classficion': [
            'activityclassification',
            'flag',
            method.getActiviteisClassficion,      
            'name_ar'
          ],
        },   
        //انواع الانشطة حسب التصنيفات
        {
          'get_activities_type_4_classficion': [
            'activity_type',
            'flag',
            ()=>param?method.getActivitiesType4Classficion(param):[],
            'name_ar'
          ],
        },   
        //نوع التحضير
        {
          'prepare_choice': [
            'prepare_choice',                           
            'layers',
            ()=>method.getChoices('PrepareChoice')               
          ],
        }, 
        
         //الفترات حسب الفصل
         {
          'SemesterPeriodChoice': [
            'period',
            'clock',
            ()=>method.getChoices('SemesterPeriodChoice')               
          ],
        }, 
        //تقييم اللجنة
        {
          'committee_opinion': [
            'opinion_committe',                           
            'account-group-outline',
            ()=>method.getChoices('CommitteeOpinionChoice')               
          ],
        },  
        //اليوم بحسب الفترة
        {
          'daily_schedules_4B': [
            'date',
            'calendar',
            ()=>param?method.getDailySchedules4B(param):[],
            'date'
          ],
        }, 
       //نوع الرفع
        {
          type_level_up: [
            'type_level_up',
            'book',
            ()=>[
              {
                id:"1",
                name:'رفع للفصل الاول'  
              },
              {
                id:"2",
                name:'رفع الطلاب الناجحين للفصل التالي'  
              },
               {
                id:"3",
                name:'الطلاب المعيدين'
              },
                      
            ],                 
          ],
        },   
        ///دفع التنسيق والقبول 
        {
          'batch_differentiation': [
            'batch',
            'ballot-outline',
            method.getBatchDifferentiation,      
            'name'
          ],
        },   
        // المجموعات حسب دفع التنسيق والقبول  
        {
          'group_4_differentiation': [
            'group',
            'flag',
            ()=>param?method.getGroupForBarchDifferentiation(param):[],
          ],
        },  

         // التقديرات 
         {
          'estimates': [
            'evalution',
            'star',
           ()=> method.getChoices('EstimateChoice'),
             
          ],
        },

        
      ]            
      return list.find((item) => name in item)
      
    }
    