<template>
  <custom-auto-complete
    :items="items"
    :item-title="itemTitle"
    :item-value="itemValue"
    :label="$t(label)"
    :icon="icon"
    :col="col"
    :rules="rules"
    class="custom-autocomplate"
  />
    <!-- :label="label + (isRequired?'*':'')" -->  
</template>
<script>
import { data } from '@/plugins/global-varibal'

export default {
  props: {
    name: String,
    col: {
      type: Number,
      default() {
        return 12
      },
    },
    rules: {
      type: Object,
    },
    param: {
      type: Array,
    },
    extraData: {
      type: Array,
    },
    excludeData: {
      type: Array,
    },      
  },
  data() {
    return {
      filter: undefined,

      items: [],
      isRequired:false
    }
  },
  created() {
    // if (this.rules)
    //   this.rules?.forEach((e) => {
    //     if (e() == 'حقل مطلوب') this.isRequired =true
    //   })
      
    this.enterData()
  },

  methods: {
    enterData(isExtra = false) {
      const list = data(this.name, this.param)
      this.getData( 
        list[this.name][0],
        list[this.name][1],
        list[this.name][2](),
        list[this.name][3],
        list[this.name][4],
        isExtra
      )
    },
    async getData(
      label,
      icon,
      fun,
      itemTitle = 'name',
      itemValue = 'id',

    ) {
      this.items = []
      this.label = label
      this.icon = icon
      this.itemTitle = itemTitle
      this.itemValue = itemValue
      this.items = await fun
      if(this.excludeData)     
          this.items =this.items.filter(item=>!this.excludeData.includes(item.id) )
      
      if (this.extraData)
          this.extraData.forEach((element) => {
            this.items.push(element)
          })
    

     
    },
  },
 
  watch: {
    param() {
      if (!this.etraData) this.enterData()
    },
    extraData() {
      this.enterData()
    },
    excludeData(){

      this.enterData()
    }
  },
}
</script>
<style scoped>
</style>