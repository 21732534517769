<template>
  <v-dialog color="indigo" v-model="drawer" width="600">
        <v-card>
          <v-card-title> </v-card-title>
          <v-card-text>
            <v-row>
              <v-expansion-panels model-value="1" v-model="panel">
                <v-expansion-panel value="1" class="border border-primary">
                  <template v-slot:default>
                    <v-expansion-panel-content :ripple="false">
                      <v-expansion-panel-title
                        >{{$t("lectures_defnition_add") }}
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <v-form ref="form">


                        <VRow
                        >
                      
                            <VCol cols="12" md="4" >
                              <VCardItem class="pa-0">
                              <custom-text-field
                                :label="$t('topic_ar')"
                                v-model="TopicList.topic_ar"
                                icon="abjad-arabic"
                                :rules="[$required, $validateArabicText,$max_length(30)]"
                                >

                                </custom-text-field>
                              </VCardItem>
                                </VCol>
                                    <VCol cols="12" md="4" >
                              <VCardItem class="pa-0">
                                
                                  <custom-text-field
                                :label="$t('topic_en')"
                                v-model="TopicList.topic_en"
                                icon="alpha-e"
                                :rules="[$validateEnglishText, $max_length(30)]"
                                >

                              </custom-text-field>
                            </VCardItem>
                          </VCol>
                                
                              
                              
                            
                        </VRow>


                                              </v-form>
                      </v-expansion-panel-text>
                    </v-expansion-panel-content>
                  </template>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-card-text>
          <v-card-actions class="px-3">
            <custom-btn v-if="!is_update" type="save"  :click="saveData"  />
              <custom-btn v-if="is_update" type="update"  :click="updateData"  />
          
            <custom-btn
              :label="$t('cancel')"
              color="white"
              text_color="black"
              icon_color="black"
              type="cancel"
              @click="drawer = false"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
    <custom-data-table-2
      :headers="headers"
      :items="items"
      :pagination="pagination"
      :method="getData"
      :delItem="delData"
      :editItem="editData"
      :perm="{}"
      export
      :create="()=>opendailog()"
    />

</template>
<script>

export default {
  props:{
      id: {
        type: Number,
      },
    },
    

    data() {
        return {
        panel:null,
        items: [],
        pagination: undefined,
        is_update: false,
        updated_id: undefined,
        drawer: null,
        TopicList:{ },
        headers: [

            { title: this.$t('topic_ar'), key: 'topic_ar' },
            { title: this.$t('topic_en'), key: 'topic_en' },
            { title: this.$t('actions'), key: 'actions',sortable:false },
        ],
        }
    },

    methods: {

        opendailog() {
        this.drawer = true
        this.is_update = false
        this.resetForm()
        },
        resetForm() {
            this.TopicList={}
            this.is_update = false
            this.updated_id = undefined
            },
        async editData(data) {
      
            this.update_id = data.id
            this.TopicList={...data}
            this.drawer =true
            this.is_update = true
            
        },
        async updateData() {
            if (this.panel === undefined || this.panel === null) {
          }else{
        const { valid } = await this.$refs.form.validate();
        if (valid) {
            this.TopicList.data_entry = this.user;
            this.TopicList.fk_subject_lecture_id=this.id;
        await this.$axios
            .put(
                this.base_url + "system-management/lecture-topic-data/" + this.update_id +'/',
                this.TopicList,
            
            )
            .then(() => {
              this.$emit("successAlert", this.$t("data_updated"));
                        this.resetForm();

            this.getData();
              this.drawer = !this.drawer
            })
            .catch((e) => {
              if (e.response.data.non_field_errors[0] == "الحقول fk_semester, fk_subject يجب أن تشكل مجموعة فريدة.") {
                    this.$emit("errorAlert", this.$t("unique_subject_and_subject_type"));
                  }
                this.$emit("errorAlert", this.$t("title"));
            });
        }
          }
        },
        async delData(delete_id) {

            if (delete_id != undefined) {
            var status
            await this.$axios
                .delete('system-management/lecture-topic-data/' + delete_id +'/')
                .then(() => {
                status = true
                })
                .catch((error) => {
                status = error
                })
            return status
            }

        },
        async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
        await this.$axios('system-management/lecture-topic-data/filter/', {
            params: {
            search: txt_search,
            page: page,
            page_size: perPage,
            sort_by: order_data,
            field:'fk_subject_lecture',
            value: this.id,
            },
        })
            .then((response) => {
          
            this.items = response.data

            this.pagination = response.data.pagination
            })
            .catch(() => {})
        },
        async saveData() {
            if (this.panel === undefined || this.panel === null) {
          }else{
        const { valid } = await this.$refs.form.validate();
            if (valid) {
            this.TopicList.data_entry = this.user;
            this.TopicList.fk_subject_lecture=this.id;
          
            await this.$axios
                .post('system-management/lecture-topic-data/', this.TopicList)
                .then(() => {
                this.$emit("successAlert", this.$t("data_added"));
                this.resetForm();
                this.getData();
                this.drawer=!this.drawer;
                })
                .catch(() => {
        
                    this.$emit("errorAlert", this.$t("error_in_data"));
                
                
                });
            
            }
          }
        },
    },
    }
</script>
