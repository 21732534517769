<template>
  <v-col cols="12" :md="col" v-if="!multiple">
    <v-autocomplete
      v-model="value"
      :items="data"
      :item-title="itemTitle"
      :item-value="itemValue"
      :label="label"
      density="compact"
      :prepend-inner-icon="'mdi-' + icon"
      :rules="rules"
      @change="updateDate"
      :disabled="disabled"
      :loading="loading"
      :error="error"
      hide-details="auto"
      :clearable="clearable"
      :readonly="readonly"
    />
  </v-col>
  <v-col cols="12" :md="col" v-else>
    <v-autocomplete
      v-model="value"
      :items="data"
      :item-title="itemTitle"
      :item-value="itemValue"
      clearable
      :label="label"
      density="compact"
      :prepend-inner-icon="'mdi-' + icon"
      :rules="rules"
      @change="updateDate"
      :disabled="disabled"
      :loading="loading"
      :error="error"
      hide-details="auto"
      :multiple="multiple"
      :readonly="readonly"
    >
      <template v-slot:chip="{ props, item }">
        <v-chip
          class="mx-1"
          style="font-size: 0.9rem"
          v-bind="props"
          :text="item.raw.name_ar"
        >
        </v-chip> </template
    ></v-autocomplete>
  </v-col>
</template>
<script>
export default {
  name: 'CustomAutoComplete',
  props: {
    modelValue: {
      type: [Number, String, Array],
      default: null,
    },
    items: {
      type: [Array],
    },
    itemTitle: {
      type: String,
      default: 'name',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    itemValue: {
      type: String,
      default: 'key',
    },
    col: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'select item',
    },
    icon: {
      type: String,
      default: 'home',
    },
    append_icon: {
      type: String,
      default: '',
    },
    rules: {
      type: Object,
    },
    clearable: {
      type: Boolean,
      default() {
        return true
      },
    },
    chooseFirst: {
      type: Boolean,
      default() {
        return false
      },
    },
    readonly: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      error: false,
      loading: false,
      data: [],
    }
  },
  created() {
    this.loading = true
  },

  computed: {
    value: {
      get() {
        if (Number(this.modelValue)) {
          if (this.modelValue >= 0) return this.modelValue
          else return null
        } else if (Object(this.modelValue) && !this.multiple) {
          return null
        } else {
          return this.modelValue
        }
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
  methods: {
    updateDate(newValue) {
      this.$emit('update:modelValue', newValue)
    },
  },

  watch: {
    items() {
      if (this.items == 'error') {
        this.error = true
        this.data = []
      } else {
        this.error = false

        this.data = this.items
      }
      if (this.items.length > 0 && this.chooseFirst == true) {
        this.updateDate(this.items[0][this.itemValue])
      }

      this.loading = false
    },
  },
}
</script>
