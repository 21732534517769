<template>
<v-row>
              <drop-list
                name="batch"
                v-model="dropList.fk_batch"
                col="3"
                @update:model-value="(dropList.fk_semester = undefined, dropList.fk_semester_group_4b = undefined)"
              />
              <drop-list
              name="semester_4_batch"
              :param="dropList.fk_batch"
              v-model="dropList.fk_semester"
              col="3"
              @update:model-value="(dropList.fk_semester_group_4b = undefined)"
            />

            <drop-list
              :param="dropList.fk_semester"
              name="group_4_semester"
              class="mr-12"
              v-model="dropList.fk_semester_group_4b"
              col="3"
              @update:model-value="getitemData"
            />
</v-row>
  <v-row>
    <v-col>
      <custom-card class="ma-5 w-50">
        <template v-slot>
          <v-col>
            <custom-text-field
              v-model="search_1"
              :label="$t('search-here')"
              col="12"
              icon="magnify"
              :hide_details="true"
            />
        <v-data-table
              v-model="selected_1"
              :search="search_1"
              :headers="headers_1"
              :items="items_1"
              show-select
              item-value="fk_student"
              items-per-page="-1"
            >
              <template #bottom />
            </v-data-table>
          </v-col>
        </template>
      </custom-card>
    </v-col>
    <v-col class="pt-0 px-3 my-auto" cols="12" xs="12" sm="12" md="1" lg="1">
      <div class="d-flex align-center flex-column">
        <v-btn
          class="bg-indigo mb-3"
          size="small"
          color="white"
          density="default"
          variant="tonal"
          @click="addTo"
          :disabled="!dropList.fk_semester_group_4b"
        >
          <v-icon color="white"> mdi-arrow-left</v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col>
      <custom-card class="ma-5 w-50">
        <template v-slot>
          <custom-text-field
            v-model="search_2"
            :label="$t('search-here')"
            col="12"
            icon="magnify"
            :hide_details="true"
          />
          <v-data-table
            v-model="selected_2"
            v-model:search="search_2"
            :headers="headers_2"
            :items="items_2"
            show-select
            item-value="full_name_ar"
            items-per-page="-1"
          >
            <template #bottom />
          </v-data-table>
        </template>
      </custom-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
    data() {
        return {
            item_3:[],
            dropList:{},
            selected_1:[],
            search_1:null,
            items_1:[
            ],
          headers_1: [
          { title: this.$t('name_ar'), key: 'full_name_ar' },        
          ],
            selected_2:[],
              search_2:null,
              items_2:[
              ],
          headers_2: [
        { title: this.$t('name_ar'), key: 'full_name_ar' },        
        ]
        }
    },
    methods: {
        getitemData(){
          if (this.dropList.fk_batch != undefined || this.dropList.fk_batch != null    ) {
            if (this.dropList.fk_semester_group_4b != null || this.dropList.fk_semester_group_4b != undefined) {
            this.$axios('/student-affairs/distribution-of-student-into-class-group/get-students-by-batch/'
                ,{
              params: {
                fk_semester_group_4b: this.dropList.fk_semester_group_4b,
                fk_batch: this.dropList.fk_batch,
              },
            }
            ).then((e)=>{
              this.items_1 =e.data[0].students;
              this.items_2 =e.data[0].studentSemesterGroup;
        })
        }
        else{
          this.items_2=[];
        }
            }
        },
        addTo(){
            const data_include = this.items_1.filter((item)=>this.selected_1.includes(item.fk_student))
            const data_exclude = this.items_1.filter((item)=>!this.selected_1.includes(item.fk_student))
            this.items_1 = data_exclude
            data_include.map(v=>{                            
                this.items_2.push(v)  
            })
            this.selected_1=[]  
            const updateObject={};
            this.item_3= this.items_2.map(obj => {
              return {...obj,fk_semester_group_4b: this.dropList.fk_semester_group_4b
              }
            })
            
            // this.item_3= this.items_1.concat(this.items_2)
            this.$axios
            .post('student-affairs/distribution-of-student-into-class-group/',this.item_3,
              {
              params: {
                fk_semester_group_4b: this.dropList.fk_semester_group_4b,
              },
            }
            )
            .then(() => {
              this.$emit('alert', 'add');
              this.getitemData();
            })
            .catch(() => {
                this.$emit('alert', 'errorData')
            })
          }, 
    },
}
</script>