<template>
  <custom-auto-complete
     :items="items"
    item-title="date"
    item-value="id"
     :label="$t('study-day')"
    icon="calendar-range"
    :col="col"
    :rules="rules"
     />
</template>
<script>
// import {getDays} from"@/plugins/methods/global-methods"
export default {
  name:"ListDays",

  props:{
    rules:{
      type:Object
    },
    col:{
      type:Number,
      default(){
        return 3
      }
    }
  },  
  data() {
    return {
      items: [],

    }
  },
  async created() {
   
      // this.items=await getDays();
    
  },
 

}
</script>