<template>
  <custom-auto-complete
    :items="items"
    item-title="name"
    item-value="id"
    :label="$t('status_document')"
    icon="file-outline"
    :col="col"
    :rules="rules"
  />
</template>
<script>
export default {
  name: 'ListDays',

  props: {
    rules: {
      type: Object,
    },
    col: {
      type: Number,
      default() {
        return 12
      },
    },
  },
  data() {
    return {
      items: [],
    }
  },
  async created() {
    await this.$axios('/choices/choice-data/', {
      params: { param: 'CheckDocumentStatusChoice' },
    }).then((e) => (this.items = e.data))
  },
}
</script>
