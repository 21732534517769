<template>
  <nav-drawer
    v-show="drawer"
    :title="update_id ? 'update' : 'create'"
    v-model="drawer"
    temporary
  >
    <template v-slot:body>
      <v-form ref="form">
        <drop-list
          name="level"
          v-model="dropList.level_id"
          :param="id_batch"
          :rules="[$required]"
          @update:modelValue="
            semesterData(dropList.level_id), (this.dropList.name_id = undefined)
          "
        />
        <drop-list
          name="semester_in_plane"
          v-model="dropList.name_id"
          :extraData="semesterForLevel"
          :rules="[$required]"
        />
        <drop-list
          :name="
            isBatch
              ? 'year_in_semester_batch'
              : 'year_in_semester_specialization'
          "
          v-model="dropList.fk_academic_year_id"
          :param="id_batch"
          :rules="[$required]"
        />
        <custom-text-field
          v-model="dropList.semester_code"
          icon="numeric"
          col="12"
          :label="$t('semester_codes')"
          readonly="true"
          disabled="true"
          :rules="[$required]"
        />
        <custom-text-field
          v-model="dropList.percentage_in_cumulative_grade"
          icon="trending-up"
          :rules="[$required, $max_value(65535)]"
          :label="$t('score_percentage')"
          type="number"
        />
        <custom-text-field
          v-model="dropList.number_of_subject"
          icon="format-list-numbered"
          :rules="[$required, $max_value(65535)]"
          :label="$t('number_materials')"
          type="number"
        />
        <custom-text-field
          v-model="dropList.allowed_failure_subjects"
          :rules="[$max_value(65535)]"
          icon="close-box"
          :label="$t('number_failing_subjects')"
          type="number"
        />
        <custom-text-field
          v-model="dropList.number_of_exemption"
          icon="check-circle"
          :rules="[$required, $max_value(65535)]"
          :label="$t('number_exemptions')"
          type="number"
        />
      </v-form>
    </template>
    <template v-slot:action>
      <custom-btn
        :type="dropList.id ? 'update' : 'save'"
        :click="dropList.id ? updateData : saveData"
      />
      <custom-btn @click.stop="resetForm(), (drawer = false)" />
    </template>
  </nav-drawer>
  <custom-data-table-2
    :headers="headers"
    :items="items"
    :pagination="pagination"
    :method="getData"
    :delItem="delData"
    :editItem="editData"
    :actionList="actionList"
    :perm="{}"
    exports
    :create="() => openDialog()"
  />
</template>

<script>
import CustomRouter from '@/components/ComponentGlobal/CustomRouter.vue'
export default {
  components: { CustomRouter },
  props: {
    type: Number,
    id: Number,
  },
  created() {
    this.isBatch = this.$route.name == 'semester-for-batch' ? true : false
    this.id_batch = this.id
  },
  data() {
    return {
      items: [],
      id_batch: undefined,
      isBatch: undefined,
      semester: [],
      semesterForLevel: [],
      academic_years: [],
      pagination: undefined,
      dropList: {},
      drawer: false,
      perm: {},
    }
  },
  methods: {
    actionList(data) {
      return [
        [
          'semesterperiod',
          () =>
            this.$router.push({
              name: 'period4-schedul-in-semester',
              params: { fk_semester: data.id },
            }),
        ],
        [
          'groups',
          () =>
            this.$router.push({
              name: 'groups-for-semester',
              params: { fk_semester: data.id },
            }),
        ],
        [
          'subjectes',
          () =>
            this.$router.push({
              name: 'subject-for-semester',
              params: { id: data.id, fk: data.fk_semester },
            }),
        ],
      ]
    },
    async openDialog() {
      this.resetForm()
      await this.getSemester()
        .then((response) => {
          this.semester = response.data.names
          this.dropList.semester_code = response.data.next_semester_code
          this.drawer = true
        })
        .catch(() => {
          this.$emit('alert', 'errorData')
        })
    },
    async getSemester(){
      const param = this.isBatch
        ? {
            fk_batch: this.id,
            semester_type: '1',
          }
        : {
            fk_specializatoin: this.id,
            semester_type: '2',
          }
      return await this.$axios('academic-affairs/semester-4B/data-for-save/', {
        params: param,
      })
    },
    resetForm() {
      this.$refs.form.reset()
      this.semester = []
      this.semesterForLevel = []
      this.dropList = {}
      this.update_id = undefined
    },
    async getData(page = 1, perPage = 10, order_data = '', txt_search = '') {
      await this.$axios('academic-affairs/semester-4B/filter-paginate/', {
        params: {
          field: this.isBatch ? 'fk_batch' : 'fk_specialization_4b',
          value: this.id,
          search: txt_search,
          page: page,
          page_size: perPage,
          sort_by: order_data,
        },
      })
        .then((response) => {
          this.items = response.data.results
          this.pagination = response.data.pagination
        })
        .catch(() => {})
    },
    async editData(data) {
      this.resetForm()
      await this.openDialog()
      this.semesterData(data.level_id)
      this.semesterForLevel.push({
        id: data.name_id,
        name: data.name,
        level: data.level_id,
      })
      this.semesterForLevel = this.semesterForLevel
        .slice()
        .sort((a, b) => a.id - b.id)
      this.dropList = { ...data }
    },
    async delData(delete_id) {
      if (delete_id != undefined) {
        var status
        await this.$axios
          .delete('academic-affairs/semester-4B/' + delete_id + '/')
          .then(() => {
            status = true
          })
          .catch((error) => {
            status = error
          })
        return status
      }
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const data = {
          ...this.dropList,
          name: this.dropList.name_id,
          level: this.dropList.level_id,
          is_4_college: this.$route.name == 'semester-for-batch' ? true : false,
          fk_academic_year: this.dropList.fk_academic_year_id,
          fk_batch: this.isBatch ? this.id : null,
          fk_specialization_4b: this.isBatch ? null : this.id,
        }
        await this.$axios
          .post('academic-affairs/semester-4B/', data)
          .then(() => {
            this.$emit('alert', 'add')
            this.resetForm()
            this.getData()
            this.drawer = false
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })
      }
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const data = {
          ...this.dropList,
          fk_batch: this.isBatch ? this.dropList.fk_batch_id : null,
          fk_specialization_4b: this.isBatch
            ? null
            : this.dropList.fk_specialization_id,
          fk_academic_year: this.dropList.fk_academic_year_id,
          level: this.dropList.level_id,
          name: this.dropList.name_id,
        }
        await this.$axios
          .put(`academic-affairs/semester-4B/${this.dropList.id}/`, data)
          .then(() => {
            this.$emit('alert', 'update')
            this.resetForm()
            this.drawer = false
            this.getData()
          })
          .catch(() => {
            this.$emit('alert', 'errorData')
          })
      }
    },
    async semesterData(level) {
      let data = this.semester.filter((e) => e.level == level)
      this.semesterForLevel = [...data]
    },
  },
  computed:{
     headers(){
      return [
        { title: this.$t('clss_name'), key: 'name' },
        { title: this.$t('level'), key: 'level' },
        { title: this.$t('semester_codes'), key: 'semester_code' },
        {
          title: this.$t('score_percentage'),
          key: 'percentage_in_cumulative_grade',
        },
        {
          title: this.$t('number_materials'),
          key: 'number_of_subject',
        },
        {
          title: this.$t('number_failing_subjects'),
          key: 'allowed_failure_subjects',
        },
        {
          title: this.$t('number_exemptions'),
          key: 'number_of_exemption',
        },

        { title: this.$t('actions'), key: 'actions', sortable: false },
      ]
      }
  }
}
</script>
