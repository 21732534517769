<template>
  <custom-auto-complete
    :items="item"
    item-title="name_ar"
    item-value="id"
    :label="$t('currency')"
    icon="currency-usd"
  />
</template>
<script>
export default {
  data() {
    return {
      item: [],
    }
  },
  created() {
    this.$axios('common/currency-data/').then((e) => {
      this.item = e.data.results
    })
  },
}
</script>
